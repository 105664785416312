/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ShiftListType,
  ShiftStatus,
  createShift,
  deleteShift,
  listAllShifts,
  updateShift,
} from '@digistaff/shift';
import {app, setTenantKey} from '../Accounts';
import {withTenant} from './withTenant';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {listKioskSettings} from '@digistaff/business';

export const fetchAppliedShifts = async (
  staffId: string,
  offset: number,
  limit: number
) => {
  try {
    const filterCriteria = {staff_id: staffId};
    const orderCriteria = [{column: 'shift_date', order: 'desc'}];

    const res = await listAllShifts(app, ShiftListType.schedule, {
      offset: offset,
      limit: limit,
      filter: filterCriteria,
      order: orderCriteria,
    });

    return res.data.listShifts;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const globalFetchAppliedShifts = (tenant_id: string) =>
  withTenant(tenant_id, fetchAppliedShifts);

export const applyNewShift = async (
  staffId: string,
  jobId: string,
  startDate: string
) => {
  try {
    const payload = {
      staff_id: staffId,
      shift_instance_id: jobId,
      status: ShiftStatus.applied,
      shift_date: startDate,
    };
    return await createShift(app, payload);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchJobApplicationStatus = async (
  staffId: string,
  jobId: string
) => {
  try {
    const filterCriteria = {staff_id: staffId, shift_instance_id: jobId};

    const res = await listAllShifts(app, ShiftListType.jobDetails, {
      filter: filterCriteria,
    });
    return res.data.listShifts;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const updateAppliedShift = async (
  staffId: string,
  jobId: string,
  shiftId: string,
  startDate: string
) => {
  try {
    const payload = {
      staff_id: staffId,
      shift_instance_id: jobId,
      status: ShiftStatus.applied,
      shift_date: startDate,
    };

    return await updateShift(app, shiftId, payload);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const checkJobApplicationStatus = async (
  staffId: string,
  jobPostingId: string
) => {
  try {
    const filterCriteria = {staff_id: staffId, shift_instance_id: jobPostingId};
    const res = await listAllShifts(app, ShiftListType.jobDetails, {
      filter: filterCriteria,
    });

    return res.data.listShifts;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const deleteAppliedShift = async (shiftId: string) => {
  try {
    return await deleteShift(app, shiftId);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchBusinessKioskSetting = async (
  tenant_id: string,
  business_location_id: string
) => {
  const currentTenant = localStorage.getItem('tenantId');
  setTenantKey(app, tenant_id);
  try {
    const res = await listKioskSettings(app, {
      filter: {business_location_id, status: 'active'},
    });
    return res?.data?.listKioskSettings;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  } finally {
    setTenantKey(app, currentTenant!);
  }
};
