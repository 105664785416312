/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        bridge: {
          postMessage: Function;
        };
      };
    };
    blink?: {
      postMessage: Function;
    };
  }
}

export class Bridge {
  private static submitPayload = async (
    functionName: string,
    data: any = {}
  ) => {
    if (window?.webkit) {
      // iOS
      return window?.webkit?.messageHandlers?.bridge?.postMessage({
        function: functionName,
        ...data,
      });
    } else if (window?.blink) {
      // Android
      return new Promise((resolve, reject) => {
        const response = window.blink!.postMessage(
          JSON.stringify({
            function: functionName,
            ...data,
          })
        );
        if (response === 'null') {
          resolve('null');
        } else if (typeof response === 'undefined') {
          reject(
            `Bridge didn't return anything.. functionName: ${functionName}`
          );
        } else {
          try {
            resolve(response);
          } catch (e) {
            console.log(e);
            reject(e);
          }
        }
      });
    } else {
      return Promise.reject();
    }
  };

  public static resetSecureStorage = async (): Promise<void> => {
    return this.submitPayload('RESET_SECURE_STORAGE');
  };

  public static getSecureValue = async (name: string): Promise<void> => {
    return this.submitPayload('GET_SECURE_VALUE', {name: name});
  };

  // name - token value
  // value - json string data
  public static setSecureValue = async (
    name: string,
    value: string
  ): Promise<void> => {
    return this.submitPayload('SET_SECURE_VALUE', {name: name, value: value});
  };

  public static deleteSecureValue = async (name: string): Promise<void> => {
    return this.submitPayload('DELETE_SECURE_VALUE', {name: name});
  };

  public static openURL = async (url: string): Promise<void> => {
    return this.submitPayload('OPEN_URL', {url: url});
  };

  public static getFCMToken = async (): Promise<void> => {
    return this.submitPayload('GET_FCM_TOKEN');
  };

  public static resetAppData = async (): Promise<void> => {
    return this.submitPayload('RESET_APP_DATA');
  };

  // Return a json string
  public static getPushPayload = async (): Promise<string> => {
    return this.submitPayload('GET_PUSH_PAYLOAD');
  };

  public static openSettings = async (): Promise<void> => {
    return this.submitPayload('OPEN_SETTINGS');
  };

  public static setAuthToken = async (token: string): Promise<void> => {
    return this.submitPayload('SET_AUTH_TOKEN', {token: token});
  };

  public static requestPushNotificationAuthorization =
    async (): Promise<void> => {
      return this.submitPayload('REQUEST_PUSH_NOTIFICATION_AUTHORIZATION');
    };

  /**
   * Requests location authorization for the app.
   * @param authorizationType - ("WHEN_IN_USE" | "ALWAYS") The type of location authorization to request. Can only be "WHEN_IN_USE" or "ALWAYS".
   * @returns Promise<void> - A promise that resolves when the authorization request is completed.
   */
  public static requestLocationAuthorization = async (
    authorizationType: string
  ): Promise<void> => {
    return this.submitPayload('REQUEST_LOCATION_AUTHORIZATION', {
      authorizationType,
    });
  };

  /**
   * Returns the authorization status for location services.
   *
   * NOT_DETERMINED -\> The user hasn't yet made a choice about whether the app is allowed to schedule notifications.
   * DENIED -\> The app isn't authorized to schedule or receive notifications.
   * AUTHORIZED -\> The app is authorized to schedule or receive notifications.
   * PROVISIONAL -\> The application is provisionally authorized to post noninterruptive user notifications.
   * EPHEMERAL -\> The app is authorized to schedule or receive notifications for a limited amount of time.
   * UNKNOWN_DEFAULT -\> unknown error
   *
   * @returns Promise\<"NOT_DETERMINED" | "DENIED" | "AUTHORIZED" | "PROVISIONAL" | "EPHEMERAL" | "UNKNOWN_DEFAULT"\>
   */
  public static getPushNotificationAuthorizationStatus =
    async (): Promise<string> => {
      return this.submitPayload('GET_PUSH_NOTIFICATION_AUTHORIZATION_STATUS');
    };

  /**
   * Returns the authorization status for location services.
   *
   * NOT_DETERMINED -\> The user has not chosen whether the app can use location services.
   * RESTRICTED -\> The app is not authorized to use location services.
   * DENIED -\> The user denied the use of location services for the app or they are disabled globally in Settings.
   * AUTHORIZED_ALWAYS -\> The user authorized the app to start location services at any time.
   * AUTHORIZED_WHEN_IN_USE -\> The user authorized the app to start location services while it is in use.
   * UNKNOWN_DEFAULT -\> unknown error
   *
   * @returns Promise "NOT_DETERMINED" | "RESTRICTED" | "DENIED" | "AUTHORIZED_ALWAYS" | "AUTHORIZED_WHEN_IN_USE" | "UNKNOWN_DEFAULT"
   */
  public static getLocationAuthorizationStatus = async (): Promise<string> => {
    return this.submitPayload('GET_LOCATION_AUTHORIZATION_STATUS');
  };

  public static getBridgeVersion = async (): Promise<string> => {
    return this.submitPayload('GET_BRIDGE_VERSION');
  };
}
