/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {WorkOutline} from '@mui/icons-material';
import {Box, Grid, MenuItem, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  certLoadingBox,
  editQualiInput,
  innerGridContainer,
  innerGridTitle,
} from '../../ProfileViewStyles';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {cardContainer} from '../../../JobView/JobViewStyles';
import {
  cardBox,
  editDelete,
  emptyBox,
  leftIcon,
  leftPart,
  rightPart,
} from '../../../../../components/common/QualificationDrawer/QualificationDrawerStyles';
import moment from 'moment';
import {
  deleteStaffWorkExp,
  fetchBusinessIndustry,
  fetchStaffExperienceList,
} from '../../../../../providers/api/qualification';
import {ScrollLoader} from '../../../../../components/common/Loader/ScrollLoader';

export const WorkListView = () => {
  const [jobs, setJobs] = useState<any>([]);
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const [industries, setIndustries] = useState([]);
  const [loading, setLoading] = useState(true);
  const staffId = localStorage.getItem('email');

  useEffect(() => {
    loadExpList().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadExpList = async () => {
    try {
      if (staffId) {
        const jobRes = await fetchStaffExperienceList(staffId);
        const indRes = await fetchBusinessIndustry();
        setJobs(jobRes);
        setIndustries(indRes);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleDelete = (item: any) => {
    deleteStaffWorkExp(item.id)
      .then(() => {
        handlePopUp('success', 'Removed qualification');
        loadExpList().catch(err => {
          console.log(err);
          console.log(err);
        });
      })
      .catch(err => {
        console.log(err);
        handlePopUp('success', 'Unable to remove qualification');
        console.log(err);
      });
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="All Work Experiences">
          {loading ? (
            <Box sx={certLoadingBox}>
              <ScrollLoader />
            </Box>
          ) : (
            <Box sx={cardContainer}>
              {jobs.length > 0 ? (
                jobs.map((item: any, index: number) => (
                  <Box sx={cardBox} key={index}>
                    <Grid container>
                      <Grid item xs={3} sx={leftPart}>
                        <WorkOutline sx={leftIcon} />
                      </Grid>
                      <Grid item xs={9} sx={rightPart}>
                        <Grid container sx={innerGridContainer}>
                          <Grid item xs={4} sx={innerGridTitle}>
                            Job Title
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              sx={editQualiInput}
                              value={item.jobTitle}
                              disabled
                              name="jobTitle"
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={innerGridContainer}>
                          <Grid item xs={4} sx={innerGridTitle}>
                            Company
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              sx={editQualiInput}
                              value={item.company}
                              disabled
                              name="company"
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={innerGridContainer}>
                          <Grid item xs={4} sx={innerGridTitle}>
                            Industry
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              sx={{
                                width: '90%',
                                '& .MuiInputBase-root': {height: '25px'},
                                textTransform: 'capitalize',
                              }}
                              value={item.industryID}
                              disabled
                              name="industry"
                              select
                            >
                              {industries.map((inds: any) => (
                                <MenuItem
                                  key={inds.id}
                                  value={inds.id}
                                  sx={{textTransform: 'capitalize'}}
                                >
                                  {inds.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid container sx={innerGridContainer}>
                          <Grid item xs={4} sx={innerGridTitle}>
                            Start Year
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              sx={editQualiInput}
                              value={moment(item.start).format('YYYY')}
                              disabled
                              name="start"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                        <Grid container sx={innerGridContainer}>
                          <Grid item xs={4} sx={innerGridTitle}>
                            End Year
                          </Grid>
                          <Grid item xs={8}>
                            <TextField
                              sx={editQualiInput}
                              value={moment(item.end).format('YYYY')}
                              disabled
                              name="end"
                              type="number"
                            />
                          </Grid>
                        </Grid>
                        <Box sx={editDelete}>
                          <Box
                            sx={{color: 'error.main', cursor: 'pointer'}}
                            onPointerUp={() => handleDelete(item)}
                          >
                            Delete
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))
              ) : (
                <Box sx={emptyBox}>
                  Add New Work experience to upgrade your qualifications
                </Box>
              )}
            </Box>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={() => setPopUp(false)}
        message={message}
        status={status}
        duration={2000}
      />
    </>
  );
};
