/* eslint-disable @typescript-eslint/no-explicit-any */
import {setTenantKey} from '@digistaff/app';
import {
  fetchIndividualTraining,
  fetchMandatoryTrainingSession,
  fetchTrainingSession,
} from '../../../providers/api/training';
import {app} from '../../../providers/Accounts';
import {
  fetchAgencyEmploymentContractStatus,
  fetchAgencyEmploymentContractsList,
} from '../../../providers/api/contract';

import {handleCustomError} from '../../RouteErrorView/RouteErrorView';
import {
  listStaffDocumentTenants,
  listStaffProfileTenants,
  updateStaffProfileTenant,
} from '@digistaff/staff';
import {withTenant} from '../../../providers/api/withTenant';
import {listDocumentListInDefault} from '../../../providers/api/documents';
import {createStaffDocumentInTenant} from '../../../providers/api/tenancy';

export const checkRequiredAgencyTraining = async (
  required_agency_trainings: any
) => {
  if (!required_agency_trainings.length) return [];

  const fetchTrainingWithStatus = async (trainingId: string) => {
    try {
      const training = await fetchIndividualTraining(trainingId);
      if (!training) return null;

      const session = await fetchTrainingSession(training.id);
      return {
        ...training,
        status: session.length > 0 ? session[0].status : 'not started',
      };
    } catch (err) {
      console.log(err);
      console.log(err);
      throw err;
    }
  };

  try {
    const trainingsWithStatus = await Promise.all(
      required_agency_trainings.map(fetchTrainingWithStatus)
    );
    return trainingsWithStatus.filter(training => training !== null);
  } catch (err) {
    console.log(err);
    console.log(err);
    return [];
  }
};

export const checkRequiredMandatoryTraining = async (
  mandatory_trainings: any
) => {
  const profile_tenant_key = localStorage.getItem('tenantId');
  setTenantKey(app, 'default');

  if (!Array.isArray(mandatory_trainings) || mandatory_trainings.length === 0) {
    setTenantKey(app, profile_tenant_key!);
    return [];
  }

  try {
    const promises = mandatory_trainings.map(async (training: any) => {
      try {
        const individualTraining = await fetchIndividualTraining(training.id);
        let session;
        try {
          session = await fetchMandatoryTrainingSession(training.id);
        } catch (err) {
          handleCustomError(err);
        }
        return {
          ...individualTraining,
          status:
            session && session.length > 0 ? session[0].status : 'not started',
          mandatory: true,
          isExpired: isTrainingSessionExpired(session, individualTraining),
        };
      } catch (err) {
        handleCustomError(err);
      }
    });

    const res = await Promise.all(promises);

    return res;
  } catch (err) {
    handleCustomError(err);
    return err;
  } finally {
    setTenantKey(app, profile_tenant_key!);
  }
};

export const isTrainingSessionExpired = (
  session: any,
  individualTraining: any
) => {
  let result = false;

  if (session?.length) {
    const expirationDate = new Date(session[0].end_date);
    expirationDate.setDate(
      expirationDate.getDate() + individualTraining?.expiry || 0
    );
    result = new Date() > expirationDate;
  }

  return result;
};

export const passedAllRequiredAgencyTrainings = async (
  agency_trainings: any
) => {
  try {
    const requiredAgencyTrainings: any = await checkRequiredAgencyTraining(
      agency_trainings
    );

    const allTrainings = [...requiredAgencyTrainings];
    const isAllPass = allTrainings.every((item: any) => item.status === 'pass');
    return {isAllPass, allTrainings};
  } catch (error) {
    console.log(error);
    console.log(error);
    return error;
  }
};

export const passedAllMandatoryTrainings = async (mandatory_trainings: any) => {
  try {
    const requiredAgencyTrainings: any = await checkRequiredMandatoryTraining(
      mandatory_trainings
    );

    const allTrainings = [...requiredAgencyTrainings];

    const isAllPass = allTrainings.every(
      (item: any) => item.status === 'pass' && item.isExpired === false
    );

    return {isAllPass, allTrainings};
  } catch (error) {
    console.log(error);
    console.log(error);
    return error;
  }
};

export const checkStaffContractSignature = async (contract_list: any) => {
  if (contract_list.length !== 0) {
    const promises = contract_list.map((contract: any) => {
      return new Promise((resolve, reject) => {
        fetchAgencyEmploymentContractStatus(contract.id)
          .then(staffSignedContract => {
            const contractWithStatus = {
              ...contract,
              sign_status: staffSignedContract.length !== 0 ? true : false,
            };
            resolve(contractWithStatus);
          })
          .catch(err => {
            reject(err);
            console.log(err);
            console.log(err);
          });
      });
    });

    return Promise.all(promises)
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  } else {
    return [];
  }
};

export const signedAllContracts = async () => {
  try {
    const contractList = await fetchAgencyEmploymentContractsList();

    if (contractList.length === 0) {
      return {
        allSigned: true,
        contractsWithStaffSignatureStatus: [],
      };
    }

    const contractsWithStaffSignatureStatus: any =
      await checkStaffContractSignature(contractList);

    const allSigned = [...contractsWithStaffSignatureStatus].every(
      (item: any) => item.sign_status === true
    );

    return {allSigned, contractsWithStaffSignatureStatus};
  } catch (error) {
    console.log(error);
    console.log(error);
    return error;
  }
};

export const signedAllContractsInDigiJobs = async (tenant_id: string) => {
  setTenantKey(app, tenant_id);
  try {
    const contractList = await fetchAgencyEmploymentContractsList();

    if (contractList.length === 0) {
      return {
        allSigned: true,
        contractsWithStaffSignatureStatus: [],
      };
    }

    const contractsWithStaffSignatureStatus: any =
      await checkStaffContractSignature(contractList);

    const contractListWithTenant = contractsWithStaffSignatureStatus.map(
      (item: any) => {
        return {
          ...item,
          tenant_id: tenant_id,
        };
      }
    );

    const allSigned = contractListWithTenant.every(
      (item: any) => item.sign_status === true
    );

    setTenantKey(app, 'default');
    return {allSigned, contractListWithTenant};
  } catch (error) {
    console.log(error);
    setTenantKey(app, 'default');
    console.log(error);
    return error;
  }
};

export const passedAllRequiredAgencyTrainingsInDigiJobs = async (
  agency_trainings: any,
  tenant_id: string
) => {
  setTenantKey(app, tenant_id);
  try {
    const requiredAgencyTrainings: any = await checkRequiredAgencyTraining(
      agency_trainings
    );

    const allTrainings = requiredAgencyTrainings.map((item: any) => {
      return {
        ...item,
        tenant_id: tenant_id,
      };
    });

    const isAllPass = allTrainings.every((item: any) => item.status === 'pass');

    setTenantKey(app, 'default');
    return {isAllPass, allTrainings};
  } catch (error) {
    console.log(error);
    setTenantKey(app, 'default');
    console.log(error);
    return error;
  }
};

// Remove Job Feed related value from local storage and trigger reload
export const removeOldJobPosts = () => {
  localStorage.removeItem('jobList');
  localStorage.removeItem('JLOffset');
  localStorage.removeItem('JLScroll');
  localStorage.removeItem('totalJobs');
};

export const checkTenantVerificationStatus = async (staffId: string) => {
  try {
    const res = await listStaffProfileTenants(app, {
      filter: {id: staffId},
      limit: 1,
    });

    return res;
  } catch (error) {
    handleCustomError(error);
    return error;
  }
};

export const checkTenantVerificationStatusInDigiJobs = (tenant_id: string) =>
  withTenant(tenant_id, checkTenantVerificationStatus);

export const resubmitApplicationToTenant = async (
  tenantId: string,
  staffId: string
) => {
  const currentTenant = localStorage.getItem('tenantId');
  setTenantKey(app, tenantId);
  try {
    const docs = await listStaffDocumentTenants(app, {
      filter: {staff_id: staffId},
    });

    const docList = docs?.data?.listStaffDocumentTenants || [];
    const allDocs = await listDocumentListInDefault(tenantId, staffId);

    if (allDocs && allDocs.length !== 0) {
      const docListMap = new Map(docList.map((doc: any) => [doc.id, doc]));

      for (const document of allDocs) {
        if (!docListMap.has(document.id)) {
          const payload = {
            id: document.id,
            type: document.type,
          };

          await createStaffDocumentInTenant(staffId, tenantId, [payload]);
        }
      }
    }

    return await updateStaffProfileTenant(app, staffId, {
      verification_status: 'pending',
    });
  } catch (error) {
    handleCustomError(error);
    return error;
  } finally {
    setTenantKey(app, currentTenant!);
  }
};
