/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, Button} from '@mui/material';
import {continueBtn} from '../LandingView/LandingViewStyles';
import {Bridge} from '../../../Bridge';
import {GPSBtnBox, GPSContainer, GPSTextBox} from './EnableGPSViewStyles';
import android_permission_location from '../../../../assets/images/android_permission_location.jpg';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

export const EnableGPSAndroidView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkLocationStatus = async () => {
      try {
        const locationStatus = await Bridge.getLocationAuthorizationStatus();

        if (locationStatus === 'AUTHORIZED_ALWAYS') {
          localStorage.setItem('hasRedirected', 'true');
          navigate('/agency/select');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const intervalId = setInterval(checkLocationStatus, 1000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleOpenSetting = async () => {
    try {
      await Bridge.requestLocationAuthorization('ALWAYS');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={{...GPSContainer, mb: '100px'}}>
      <Box sx={GPSTextBox}>
        You Must Enable Location Services in Settings 1 hour before your next
        shift:
      </Box>
      <Box
        sx={{
          mx: 4,
          mt: 2,
          py: 2,
          px: 4,
          border: '1px solid',
          borderColor: 'secondary.main',
          fontSize: '0.8rem',
          borderRadius: '8px',
        }}
      >
        <Box>
          This app collects location data to enable clock in, clock out, and
          suggest local jobs even when the app is closed or not in use.
        </Box>
      </Box>
      <Box
        component="img"
        src={android_permission_location}
        sx={{height: '250px', width: 'auto', mt: 2}}
      />
      <Box sx={GPSBtnBox}>
        <Button
          variant="outlined"
          sx={continueBtn}
          onPointerUp={handleOpenSetting}
        >
          Turn on
        </Button>
      </Box>
      <Box
        sx={GPSBtnBox}
        onPointerUp={() => {
          localStorage.setItem('hasRedirected', 'true');
          navigate('/agency/select');
        }}
      >
        Do it later
      </Box>
    </Box>
  );
};
