/* eslint-disable @typescript-eslint/no-explicit-any */
import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export const SwitchProfileDialog = ({open, onClose}: any) => {
  const navigate = useNavigate();

  const redirect = () => navigate('/account/switch');

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">
        Switch Profile
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', my: 1, color: 'info.main'}}>
          Please note that your application to join the agency has been
          submitted. You can switch your profile to view agency-specific job
          posts by clicking the button below.
        </Box>
        <Box sx={{textAlign: 'center', my: 1, color: 'info.main'}}>
          Please be aware that your application is currently under review and
          has not been approved by the agency. The agency&apos;s staff may
          contact you for further action if necessary.
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={redirect}>
          Switch Profile Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};
