/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createStaffContactPreference,
  getStaffContactPreference,
  updateStaffContactPreference,
} from '@digistaff/staff';
import {app} from '../Accounts';

export const createContactPreference = async (staffId: string) => {
  try {
    const payload = {
      id: staffId,
      method: 'push',
    };
    const res: any = await createStaffContactPreference(app, payload);

    return res.data.createStaffContactPreference;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getContactPreference = async (contactPreferenceId: string) => {
  try {
    const res: any = await getStaffContactPreference(app, contactPreferenceId);

    return res?.data?.getStaffContactPreference;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const updateContactPreference = async (
  contactPreferenceId: string,
  selectedOption: any
) => {
  try {
    const res: any = await updateStaffContactPreference(
      app,
      contactPreferenceId,
      {
        method: selectedOption.value,
      }
    );

    return res?.data?.updateStaffContactPreference;
  } catch (err) {
    return Promise.reject(err);
  }
};
