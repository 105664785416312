export const scrollLimit = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '126px',
  p: 0,
  overflowY: 'scroll',
  backgroundColor: 'primary.dark',
};

export const leftMsgBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 'auto',
  width: '100vw',
  maxWidth: '100%',
  pt: 2,
};

export const leftTextBox = {
  width: '70%',
  height: '100%',
  backgroundColor: 'primary.light',
  borderRadius: '10px',
  border: '1px solid',
  borderColor: 'info.main',
  px: 2,
  py: 1,
  wordWrap: 'break-word',
  color: 'info.main',
};

export const leftTime = {
  fontSize: '0.7rem',
  color: 'secondary.dark',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const warningTime = {
  fontSize: '0.7rem',
  color: 'warning.main',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const rightTime = {
  fontSize: '0.7rem',
  color: 'primary.light',
  display: 'flex',
  justifyContent: 'flex-end',
};

export const rightMsgBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 'auto',
  width: '100vw',
  maxWidth: '100%',
  pt: 2,
};

export const rightTextBox = {
  width: '70%',
  height: '100%',
  backgroundColor: 'info.main',
  borderRadius: '10px',
  border: '1px solid',
  borderColor: 'info.main',
  px: 2,
  py: 1,
  wordWrap: 'break-word',
  color: 'primary.light',
};

export const warningTextBox = {
  width: '70%',
  height: '100%',
  backgroundColor: 'primary.light',
  borderRadius: '10px',
  border: '1px solid',
  borderColor: 'warning.main',
  px: 2,
  py: 1,
  wordWrap: 'break-word',
  color: 'warning.main',
  fontWeight: '700',
};

export const infoBox = {display: 'flex', justifyContent: 'flex-end'};

export const chipContainer = {
  width: '100%',
  height: '100%',
  backgroundColor: 'primary.light',
  borderRadius: '10px',
  px: 2,
  py: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const chipBox = {
  mx: 1,
  px: 2,
  backgroundColor: 'primary.light',
  border: '1px solid',
  borderColor: 'info.main',
  color: 'info.main',
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: 'info.main',
    color: 'primary.light',
    borderColor: 'primary.light',
  },
  '&.MuiChip-root': {height: '3.5rem', width: '6rem'},
};

export const chipBoxAlt = {
  mx: 1,
  px: 2,
  backgroundColor: 'info.main',
  border: '1px solid',
  borderColor: 'info.main',
  color: 'primary.light',
  fontSize: '1rem',
  '&.MuiChip-root': {height: '3.5rem', width: '6rem'},
};

export const callBox = {
  display: 'flex',
  position: 'absolute',
  right: '25px',
  cursor: 'pointer',
};

export const rightMsgTick = {color: 'info.main', fontSize: '16px'};

export const leftMsgTick = {fontSize: '16px'};

export const warningTick = {color: 'warning.main', fontSize: '16px'};

export const msgAppBar = {
  backgroundColor: 'primary.light',
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderColor: 'secondary.light',
};
