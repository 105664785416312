/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {
  bottomSubPageLimit,
  certLoadingBox,
  chequeEmptyBox,
} from '../../ProfileViewStyles';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {Moment} from 'moment';
import {ScheduledAppointmentTab} from './ScheduledAppointmentTab';
import {BookAppointmentTab} from './BookAppointmentTab';
import {
  checkTimeSlot,
  createStaffAppointment,
  dropAppointment,
  fetchAppointmentCategories,
  fetchAppointmentLocation,
  fetchAppointmentTimeSlot,
  fetchCustomBusinessDateSetting,
  fetchStaffAppointment,
} from '../../../../../providers/api/appointment';
import {ScrollLoader} from '../../../../../components/common/Loader/ScrollLoader';

import {
  checkCustomSettings,
  extractAppointmentPayload,
  formatTime,
  generateAndDisableTimeSlots,
  generateDates,
} from './AppointmentHelpers';

export const ChequePickUp = () => {
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [types, setTypes] = useState<string[]>([]);
  const [dates, setDates] = useState<any>([]);
  const [timeSlot, setTimeSlot] = useState<any>([]);
  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [reason, setReason] = useState('');
  const [address, setAddress] = useState('');
  const [latLng, setLatLng] = useState<any>({
    lat: null,
    lng: null,
  });
  const [booking, setBooking] = useState([]);
  const [appointment, setAppointment] = useState(false);
  const simulatedDates = 15;
  const staffId = localStorage.getItem('email');
  const [loading, setLoading] = useState(true);
  const [typeLoading, setTypeLoading] = useState(true);
  const [dateLoading, setDateLoading] = useState(true);
  const [times, setTimes] = useState({});
  const [availableSlots, setAvailableSlots] = useState(0);
  const [noAgent, setNoAgent] = useState(false);
  const [appriseHours, setAppriseHours] = useState(0);
  const tenantId = localStorage.getItem('tenantId');
  const [customDateSettings, setCustomDateSettings] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedWeekday, setSelectedWeekday] = useState<Moment | null>(null);
  const [typePurpose, setTypePurpose] = useState('');
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [optionalNotes, setOptionalNotes] = useState('');
  const [selectedType, setSelectedType] = useState<any>({});
  const [timezoneLocationSetting, setTimezoneLocationSetting] = useState<any>(
    {}
  );

  useEffect(() => {
    checkAppointment().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [appointment]);

  useEffect(() => {
    if (selectedDate) {
      if (typePurpose === 'payout') {
        setSelectedWeekday(null);
        setSelectedLocation(null);
        setOptionalNotes('');
      }
      setSelectedTime('');
      checkTimeSlotVacancy(
        times,
        availableSlots,
        selectedDate,
        timezoneLocationSetting
      ).catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTypeId) {
      loadDatesAndTimes().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, [selectedTypeId]);

  const loadTypes = () => {
    fetchAppointmentCategories()
      .then(res => setTypes(res))
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setTypeLoading(false));
  };

  const processTimeSlots = async (
    time: any,
    slots: any,
    selectedDate: any,
    timezoneLocationSetting: any
  ) => {
    try {
      setSelectedTimeSlot(slots);
      await generateAndDisableTimeSlots(
        time,
        slots,
        selectedDate,
        appriseHours,
        timezoneLocationSetting,
        setTimeSlot
      );
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const checkTimeSlotVacancy = async (
    times: any,
    availableSlots: any,
    selectedDate: any,
    timezoneLocationSetting: any
  ) => {
    try {
      const customSetting = checkCustomSettings(
        customDateSettings,
        selectedDate
      );

      if (customSetting) {
        const startTime = formatTime(customSetting.start_date);
        const endTime = formatTime(customSetting.end_date);

        const customTimes = {
          intervalMinutes: customSetting.interval_minutes,
          startTime: startTime,
          endTime: endTime,
        };
        await processTimeSlots(
          customTimes,
          customSetting.booking_per_slot,
          selectedDate,
          timezoneLocationSetting
        );
      } else {
        await processTimeSlots(
          times,
          availableSlots,
          selectedDate,
          timezoneLocationSetting
        );
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const checkAppointment = async () => {
    if (tenantId === 'default') {
      setNoAgent(true);
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      if (staffId) {
        const appointment: any = await fetchStaffAppointment(staffId);

        if (appointment.length === 0) {
          loadTypes();
        } else {
          const location: any = await fetchAppointmentLocation();
          loadAddress(location);
          setBooking(appointment);
          setAppointment(true);
        }
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const loadDatesAndTimes = async () => {
    try {
      const {times, dates, availableSlots, timezone} =
        await fetchAppointmentTimeSlot();
      const customDateSetting = await fetchCustomBusinessDateSetting();
      const newDates = generateDates(
        dates,
        appriseHours,
        customDateSetting,
        simulatedDates
      );

      setDates(newDates);
      setTimes(times);
      setAvailableSlots(availableSlots);
      setCustomDateSettings(customDateSetting);
      setTimezoneLocationSetting(timezone);

      await setSelectedDateAndCheckVacancy(
        newDates,
        times,
        availableSlots,
        timezoneLocationSetting
      );
    } catch (err) {
      console.log(err);
    } finally {
      setDateLoading(false);
    }
  };

  const setSelectedDateAndCheckVacancy = async (
    newDates: any,
    times: any,
    availableSlots: any,
    timezoneLocationSetting: any
  ) => {
    for (let i = 0; i < newDates.length; i++) {
      const currentDate = newDates[i];
      if (!currentDate.disabled) {
        const date = currentDate.date.format('DD/MM/YYYY');
        setSelectedDate(date);
        try {
          await checkTimeSlotVacancy(
            times,
            availableSlots,
            date,
            timezoneLocationSetting
          );
        } catch (err) {
          console.log(err);
        }
        break;
      }
    }
  };

  const loadAddress = (location: any) => {
    setAddress(location.address);
    setLatLng({
      lat: location.lat,
      lng: location.lng,
    });
  };

  const formNotCompleted = () => {
    if (typePurpose === 'payout') {
      if (selectedType.is_shift_linked) {
        return (
          !selectedTypeId || !selectedTime || !selectedDate || !selectedLocation
        );
      } else {
        return (
          !selectedTypeId || !selectedTime || !selectedDate || !selectedWeekday
        );
      }
    } else {
      return !selectedTypeId || !selectedTime || !selectedDate || !reason;
    }
  };

  const clearFields = () => {
    if (typePurpose === 'payout') {
      setSelectedWeekday(null);
      setSelectedLocation(null);
      setOptionalNotes('');
    }
    setSelectedType({});
    setSelectedTypeId('');
    setSelectedDate('');
    setSelectedTime('');
    setReason('');
    setTypePurpose('');
  };

  const bookAppointment = async () => {
    try {
      const timeSlotAvailable = await checkTimeSlot(
        selectedDate,
        selectedTime,
        selectedTimeSlot!
      );

      if (!timeSlotAvailable) {
        handlePopUp(
          'error',
          'Time slot already taken, please select another time slot'
        );
        clearFields();
        return;
      }

      const {customPayload, note} = extractAppointmentPayload(
        typePurpose,
        selectedLocation,
        selectedWeekday,
        optionalNotes,
        reason
      );

      await createStaffAppointment(
        staffId!,
        selectedTypeId,
        selectedDate,
        selectedTime,
        note,
        customPayload
      );

      confirmSubmission();
    } catch (error) {
      handlePopUp('error', 'Unable to book an appointment');
      console.log(error);
    }
  };

  const cxlBooking = () => {
    dropAppointment(booking)
      .then(() => setAppointment(false))
      .catch(err => {
        console.log(err);
      });
  };

  const confirmSubmission = () => {
    handlePopUp(
      'success',
      `You have successfully booked an appointment on ${selectedDate} at ${selectedTime}`
    );
    setAppointment(true);
    clearFields();
  };

  const handleNext = () => {
    if (formNotCompleted()) {
      if (selectedType.is_shift_linked) {
        handlePopUp(
          'error',
          'Please provide all the information required. Select the corresponding business location with a week ending date for your scheduled shift.'
        );
      } else {
        handlePopUp('error', 'Please provide all the information required');
      }
    } else {
      bookAppointment().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  };

  const checkTab = () =>
    !appointment ? 'Book an Appointment' : 'Your Upcoming Appointment';

  const handleTypeSelect = (type: any) => {
    setSelectedTypeId(type.id);
    setAppriseHours(type.apprise_hours);
    setTypePurpose(type.purpose);
    setSelectedType(type);
  };

  const handleSelectedWeekday = (newValue: Moment | null) => {
    setSelectedWeekday(newValue);
    setSelectedLocation(null);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        {loading ? (
          <Box sx={certLoadingBox}>
            <ScrollLoader />
          </Box>
        ) : noAgent ? (
          <PageWrapper title="Appointment">
            <Box sx={chequeEmptyBox}>
              You currently do not associate with an agency yet or you are
              currently in DigiJobs. Switch Profile and book an appointment with
              an agency
            </Box>
          </PageWrapper>
        ) : (
          <PageWrapper title={checkTab()}>
            {appointment && latLng.lat && latLng.lng ? (
              <ScheduledAppointmentTab
                latLng={latLng}
                address={address}
                booking={booking}
                cxlBooking={cxlBooking}
              />
            ) : (
              <BookAppointmentTab
                types={types}
                dates={dates}
                selectedTypeId={selectedTypeId}
                handleTypeSelect={handleTypeSelect}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                selectedTime={selectedTime}
                setSelectedTime={setSelectedTime}
                timeSlot={timeSlot}
                timezoneLocationSetting={timezoneLocationSetting}
                reason={reason}
                setReason={setReason}
                handleNext={handleNext}
                typeLoading={typeLoading}
                dateLoading={dateLoading}
                selectedWeekday={selectedWeekday}
                handleSelectedWeekday={handleSelectedWeekday}
                typePurpose={typePurpose}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                optionalNotes={optionalNotes}
                setOptionalNotes={setOptionalNotes}
                selectedType={selectedType}
              />
            )}
          </PageWrapper>
        )}
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
