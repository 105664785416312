/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress, TextField} from '@mui/material';
import stringLength from '../../../../assets/js/formvalidation/es6/validators/stringLength';
import React, {useEffect, useRef, useState} from 'react';
import {CustomSlider} from '../../../components/common/Animation/CustomSlider';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {PasswordInput} from '../../../components/common/PasswordInput/PasswordInput';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {
  continueBtn,
  emailInput,
  userScrollLimit,
} from '../LandingView/LandingViewStyles';
import {
  errorText,
  formContainer,
  inputBox,
  RegPersonalField,
  secondaryTitle,
  titleBox,
} from './RegistrationStyles';
import {CustomDialog} from '../../../components/common/Dialog/CustomDialog';
import {useNavigate} from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import {
  checkOnboardingStatus,
  checkStaffPhoneUniqueness,
  checkStaffProfileUniqueness,
  getUserAccountInfo,
  registerUser,
  updateUserProfileInBasicInfo,
} from '../../../providers/api/profile';
import validator from 'validator';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from '@digistaff/app';
import {app} from '../../../providers/Accounts';

import {isValidPhoneNumber} from 'react-phone-number-input';

export const BasicInfoView = () => {
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const passwordRef = useRef<HTMLInputElement>();
  const confirmPasswordRef = useRef<HTMLInputElement>();
  const [formError, setFormError] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    phone: '',
  });
  const [passwordValues, setPasswordValues] = useState({
    password: '',
    showPassword: false,
    confirmPassword: '',
    showConfPassword: false,
  });
  const staffId = sessionStorage.getItem('email')?.toLowerCase();
  const [pwField, setPwField] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    retrieveAccount().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  // Only show password field for user's first time visit
  const retrieveAccount = async () => {
    try {
      const {first_name, middle_name, last_name, phoneRetrieved} =
        await getUserAccountInfo(staffId!);

      const onboarding_status = await checkOnboardingStatus();

      onboarding_status && setOnboardingStatus(onboarding_status);

      staffId && setEmail(staffId);
      first_name && setFirstName(first_name);
      middle_name && setMiddleName(middle_name);
      last_name && setLastName(last_name);
      phoneRetrieved && setPhone(phoneRetrieved);

      if (onboarding_status && onboarding_status >= 4) {
        setPwField(true);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * validate if the email address is valid and the password length is over 8 char.
   * @param prop - The name of the input field
   * @param eventValue - Input value from user
   * @returns : set formError accordingly if password is shorter than 8 chars or two passwords not match
   */
  const validateField = (prop: string, eventValue: string) => {
    const value = eventValue;
    const password = passwordRef.current!.value;
    const confirmPassword = confirmPasswordRef.current!.value;

    switch (prop) {
      case 'password': {
        const result = stringLength().validate({
          value,
          options: {
            min: 8,
            max: 20,
          },
        });
        result.valid === false
          ? setFormError({
              ...formError,
              [prop]: 'Password should be at least 8 characters long.',
            })
          : confirmPassword !== password
          ? setFormError({
              ...formError,
              [prop]: 'Please provide same password in Confirmed Password.',
            })
          : setFormError({...formError, [prop]: '', confirmPassword: ''});

        break;
      }
      case 'confirmPassword': {
        confirmPassword !== password
          ? setFormError({
              ...formError,
              [prop]: 'Those passwords didn’t match.',
            })
          : setFormError({...formError, [prop]: '', password: ''});
        break;
      }
    }
  };

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPasswordValues({...passwordValues, [prop]: event.target.value});
      validateField(prop, event.target.value);
    };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    !validator.isEmail(e.target.value)
      ? setFormError({
          ...formError,
          email: 'Please provide a valid email address',
        })
      : setFormError({...formError, email: ''});
    setEmail(e.target.value);
  };

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFirstName(e.target.value);

  const handleMiddleName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setMiddleName(e.target.value);

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLastName(e.target.value);

  const handlePhone = (value: string) => {
    if (value) {
      setPhone(value);
      const errors: any = {};

      if (!isValidPhoneNumber(value))
        errors.phone = 'Please provide a valid phone number';
      else errors.phone = '';

      setFormError({
        ...formError,
        ...errors,
      });
    }
  };

  const checkFormValidity = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus && onboardingStatus <= 3) {
        if (
          formError.email ||
          formError.confirmPassword ||
          formError.password ||
          !phone
        )
          return true;
      }
      return false;
    } catch (error) {
      return error;
    }
  };

  const updateBasicInfo = async () => {
    try {
      const phoneNotUnique = await checkStaffPhoneUniqueness(phone, staffId!);

      if (phoneNotUnique) {
        handleDialog();
        return;
      }

      await updateUserProfileInBasicInfo(
        staffId!,
        firstName,
        middleName,
        lastName,
        phone
      );

      const onboardingStatus = await checkOnboardingStatus();

      if (onboardingStatus === 9) {
        navigate('/review');
      } else {
        navigate('/info/personal');
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const storeEmailAndNext = () => {
    const storedEmail = email.toLowerCase();
    const storedPw = passwordValues.password;
    sessionStorage.setItem('email', storedEmail);
    sessionStorage.setItem('pw', storedPw);

    signInWithEmailAndPassword(app, storedEmail, storedPw)
      .then(() => navigate('/info/personal'))
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  // First check if phone number or email is duplicated
  // then create account in cognito after created staff profile
  const createAcc = async () => {
    setSubmitting(true);
    try {
      const pw = passwordValues.password;
      const _email = email.toLowerCase();

      const isNotUnique = await checkStaffProfileUniqueness(_email, phone);
      if (isNotUnique) {
        handleDialog();
        return;
      }

      const res: any = await registerUser({
        email: _email,
        firstName,
        middleName,
        lastName,
        phone,
        cognito_password: pw,
      });

      if (res.data.createStaffProfile.id === null) {
        handleDialog();
        return;
      }

      storeEmailAndNext();
    } catch (err: any) {
      console.log(err);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  // Require all fields to be filled in
  const handleNext = async () => {
    try {
      const formNotValidated = await checkFormValidity();
      if (formNotValidated) {
        handlePopUp('error', 'Please fill in the fields');
        return;
      }
      if (!onboardingStatus) {
        await createAcc();
      } else {
        await updateBasicInfo();
      }
    } catch (err) {
      handlePopUp(
        'error',
        'Unable to proceed to next step. Please contact admin.'
      );
      console.log(err);
      console.log(err);
    }
  };

  const handleDialog = () => setDialog(prev => !prev);

  const handleLoginPage = () => navigate('/');

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/preparation" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Account Information">
          {loading ? (
            <Box sx={{px: 3, mt: 5}}>
              {Array.from(new Array(5)).map((_, index) => (
                <CustomTextLoad height={54} mb={14} key={index} />
              ))}
            </Box>
          ) : (
            <Box sx={{p: 1}}>
              <Box sx={titleBox}>
                <CustomSlider direction="right" fadeIn={300}>
                  <Box sx={secondaryTitle}>
                    Provide account information to continue
                  </Box>
                </CustomSlider>
              </Box>
              <Box sx={formContainer}>
                {formError.email ? (
                  <Box sx={{fontSize: '0.7rem', color: 'error.main', py: 1}}>
                    {formError.email}
                  </Box>
                ) : (
                  <Box sx={{py: 2}} />
                )}
                <CustomSlider direction="right" fadeIn={350}>
                  <Box sx={inputBox}>
                    <TextField
                      type="email"
                      label="Email"
                      sx={emailInput}
                      disabled={
                        onboardingStatus && onboardingStatus >= 4 ? true : false
                      }
                      value={email}
                      onChange={handleEmail}
                    />
                  </Box>
                </CustomSlider>
                <CustomSlider direction="right" fadeIn={400}>
                  <Box>
                    <Box sx={errorText}>{formError.name}</Box>
                    <TextField
                      label="Legal First Name"
                      sx={RegPersonalField}
                      value={firstName}
                      onChange={handleFirstName}
                      id="first-name"
                    />
                    <TextField
                      label="Legal Middle Name (Optional)"
                      sx={RegPersonalField}
                      value={middleName}
                      onChange={handleMiddleName}
                      id="middle-name"
                    />
                    <TextField
                      label="Legal Last Name"
                      sx={RegPersonalField}
                      value={lastName}
                      onChange={handleLastName}
                      id="last-name"
                    />
                    {formError.phone.length > 0 && (
                      <Box sx={errorText}>{formError.phone}</Box>
                    )}
                    <Box sx={{mb: 2}}>
                      <PhoneInput
                        international
                        countryCallingCodeEditable
                        defaultCountry="CA"
                        value={phone}
                        onChange={handlePhone}
                        maxLength={15}
                        id="phone"
                      />
                    </Box>
                  </Box>
                </CustomSlider>
                {!pwField && (
                  <>
                    <CustomSlider direction="right" fadeIn={450}>
                      <Box sx={inputBox}>
                        <PasswordInput
                          type={passwordValues.showPassword}
                          style={emailInput}
                          name="password"
                          inputRef={passwordRef}
                          placeholder="Create your password"
                          onChange={handleChange('password')}
                          value={passwordValues.password}
                          onClickIcon={() =>
                            setPasswordValues({
                              ...passwordValues,
                              showPassword: !passwordValues.showPassword,
                            })
                          }
                          id="password"
                        />
                      </Box>
                    </CustomSlider>
                    <CustomSlider direction="right" fadeIn={500}>
                      <Box sx={{pb: 2}}>
                        <PasswordInput
                          type={passwordValues.showConfPassword}
                          style={emailInput}
                          name="confirmPassword"
                          inputRef={confirmPasswordRef}
                          placeholder="Confirm your password"
                          onChange={handleChange('confirmPassword')}
                          value={passwordValues.confirmPassword}
                          onClickIcon={() =>
                            setPasswordValues({
                              ...passwordValues,
                              showConfPassword:
                                !passwordValues.showConfPassword,
                            })
                          }
                          id="confirm-password"
                        />
                      </Box>
                    </CustomSlider>
                    {formError.password ? (
                      <Box sx={{fontSize: '0.7rem', color: 'error.main'}}>
                        {formError.password}
                      </Box>
                    ) : (
                      <Box sx={{fontSize: '0.7rem', color: 'secondary.dark'}}>
                        Password should be at least 8 characters long.
                      </Box>
                    )}
                    {formError.confirmPassword && (
                      <Box
                        sx={{fontSize: '0.7rem', color: 'error.main', pt: 1}}
                      >
                        {formError.confirmPassword}
                      </Box>
                    )}
                  </>
                )}
                <CustomSlider direction="right" fadeIn={550}>
                  <Box sx={{pt: 3}}>
                    <Button
                      variant="outlined"
                      sx={continueBtn}
                      disabled={
                        !isValidPhoneNumber(phone) ||
                        submitting ||
                        formError.confirmPassword ||
                        formError.password ||
                        (pwField === false &&
                          (!passwordValues.password ||
                            !passwordValues.confirmPassword)) ||
                        passwordValues.password.length < 8 ||
                        !email ||
                        !firstName ||
                        !lastName
                          ? true
                          : false
                      }
                      onPointerUp={handleNext}
                      id="next-btn"
                    >
                      {submitting ? (
                        <CircularProgress
                          size="1.3rem"
                          sx={{color: 'primary.light', fontSize: '12px'}}
                        />
                      ) : (
                        'Next'
                      )}
                    </Button>
                  </Box>
                </CustomSlider>
              </Box>
            </Box>
          )}
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
      <CustomDialog
        open={dialog}
        close={handleDialog}
        title="This email or phone number has already been registered. Do you want to proceed to the Login page?"
        leftOnClick={handleDialog}
        rightOnClick={handleLoginPage}
      />
    </>
  );
};
