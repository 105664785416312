/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import moment from 'moment';
import {
  blueColumn,
  dateBox,
  detailBox,
  eventContainer,
  eventLocation,
  eventTitle,
  greenColumn,
  greyColumn,
  orangeColumn,
  redColumn,
  timeBox,
  timeDetail,
} from './ScheduleViewStyles';
import {useNavigate} from 'react-router-dom';
import {bottomSubPageLimit} from '../ProfileView/ProfileViewStyles';
import {useEffect, useRef, useState} from 'react';
import {usePopUp} from '../../../hooks/usePopUp';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {ScrollLoader} from '../../../components/common/Loader/ScrollLoader';
import {EmptyPageLoader} from '../../../components/common/EmptyPageLoader/EmptyPageLoader';
import {globalFetchAppliedShifts} from '../../../providers/api/shift';
import {ShiftStatus} from '@digistaff/shift';
import {
  createScheduleEvents,
  groupShiftByShiftDate,
  mergeSchedules,
  removeOldSchedule,
  sortByDate,
} from './ScheduleHelpers';
import {CustomCircularProgress} from '../../../components/common/CustomCircularProgress/CustomCircularProgress';
import {ContractChecker} from '../../../components/common/ContractChecker/ContractTracker';
import {TimezoneChecker} from '../../../components/common/TimezoneChecker/TimezoneTracker';
import {MandatoryDocumentChecker} from '../../../components/common/MandatoryDocumentChecker/MandatoryDocumentTracker';
import {loadContractChecker} from '../JobView/ContractCheckerHelper';

export const ScheduleView = ({tenantProfile}: any) => {
  const navigate = useNavigate();
  const staffId = localStorage.getItem('email');
  const [schedule, setSchedule] = useState<any>([]);
  const [offset, setOffset] = useState(0);
  const [offsetTrigger, setOffsetTrigger] = useState(false);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const limit = 7;
  const [loading, setLoading] = useState(false);
  const [initLoad, setInitLoad] = useState(true);
  const tenants = localStorage.getItem('tenantList');
  const loadedAllShifts = localStorage.getItem('loadedAllShifts');
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollPos = useRef(0);
  const [refreshing, setRefreshing] = useState(false);
  const [contractChecker, setContractChecker] = useState(false);
  const [requiredContracts, setRequiredContracts] = useState<any>([]);

  // Store scroll position when leaving schedule page
  useEffect(() => {
    return () => {
      localStorage.setItem('SLScroll', JSON.stringify(scrollPos.current));
    };
  }, []);

  // First load stored schedule in local storage, then fetch if not
  useEffect(() => {
    if (!loadStoredSchedule()) {
      loadShifts().catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, []);

  // Load more past schedule
  useEffect(() => {
    if (!initLoad && offsetTrigger) {
      const newOffset = offset + limit;
      setLoading(true);
      handlePopUp('info', 'Loading more shifts for you');
      loadShifts(newOffset)
        .then(() => {
          setLoading(false);
          setOffset(newOffset);
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
      setOffsetTrigger(false);
    }
  }, [offsetTrigger, limit]);

  // Load scroll position
  useEffect(() => {
    if (schedule.length > 0 && scrollContainerRef.current) {
      const storedScrollTop = localStorage.getItem('SLScroll');
      if (storedScrollTop) {
        const parsedScrollTop = JSON.parse(storedScrollTop);

        if (scrollContainerRef.current.scrollHeight > parsedScrollTop) {
          scrollContainerRef.current.scrollTop = parsedScrollTop;
        }
      }
    }
  }, [schedule]);

  // Load Agency contract checker
  useEffect(() => {
    if (schedule.length > 0) {
      loadContractChecker(
        setContractChecker,
        setRequiredContracts,
        tenantProfile
      ).catch(err => {
        console.log(err);
        console.log(err);
      });
    }
  }, [schedule]);

  const loadStoredSchedule = () => {
    const storedSchedule = localStorage.getItem('schedule');
    const storedOffset = localStorage.getItem('scheduleOffset');
    const timestamp = new Date(localStorage.getItem('SLTime') || '');
    const currentTime = new Date();

    if (currentTime > timestamp) {
      removeOldSchedule();
      return false;
    }

    if (storedSchedule && storedOffset) {
      setSchedule(JSON.parse(storedSchedule));
      setOffset(JSON.parse(storedOffset));
      setInitLoad(false);
      return true;
    }

    return false;
  };

  const loadShifts = async (newOffset: number = offset) => {
    if (shouldAbortLoading()) {
      return;
    }

    try {
      startLoading();

      const tenantList = parseTenants();
      const allShiftsList = await fetchShiftsForTenants(tenantList, newOffset);
      const scheduledShift = await processShifts(allShiftsList);

      updateLocalStorageAndState(newOffset, scheduledShift);
    } catch (err) {
      handleLoadingError(err);
    } finally {
      stopLoading();
    }
  };

  const shouldAbortLoading = () => {
    if (loadedAllShifts === 'true' || tenants === 'default') {
      setInitLoad(false);
      return true;
    }

    // Prevent early registered users without stored tenant list to continue
    // Redirect to select agent
    if (!tenants) {
      handlePopUp('error', 'Please select agent before continue');
      setTimeout(() => {
        navigate('/agency/select');
      }, 2000);
      return true;
    }

    return false;
  };

  const startLoading = () => setLoading(true);

  const parseTenants = () => {
    if (tenants) {
      if (tenants !== 'default') {
        return tenants && JSON.parse(tenants);
      } else {
        return [];
      }
    }
  };

  const fetchShiftsForTenants = async (
    tenantList: any[],
    newOffset: number
  ) => {
    const shiftsPromises = tenantList.map(tenant =>
      fetchShiftsForTenant(tenant, newOffset)
    );
    const res = await Promise.all(shiftsPromises);
    return res.flat();
  };

  const fetchShiftsForTenant = async (tenant: any, newOffset: number) => {
    const shifts = await globalFetchAppliedShifts(tenant.id)(
      staffId!,
      newOffset,
      limit
    );
    return shifts.map((shift: any) => ({
      ...shift,
      tenant_id: tenant.id,
      tenant_name: tenant.name,
    }));
  };

  const processShifts = async (allShiftsList: any[]) => {
    const groupedShifts = groupShiftByShiftDate(allShiftsList);
    const allShifts = await createScheduleEvents(groupedShifts);
    return sortByDate(allShifts);
  };

  const updateLocalStorageAndState = (
    newOffset: number,
    scheduleShift: any[]
  ) => {
    if (scheduleShift.length !== 0) {
      const mergedSchedule =
        newOffset > 0 ? mergeSchedules(schedule, scheduleShift) : scheduleShift;

      setSchedule(mergedSchedule);
      setOffset(newOffset);
      const timestamp = new Date();
      timestamp.setMinutes(timestamp.getMinutes() + 60);

      localStorage.setItem('SLTime', timestamp.toString());
      localStorage.setItem('schedule', JSON.stringify(mergedSchedule));
      localStorage.setItem('scheduleOffset', JSON.stringify(newOffset));
    } else {
      handlePopUp('info', 'You have checked all the shifts!');
      localStorage.setItem('loadedAllShifts', 'true');
    }
  };

  const handleLoadingError = (err: any) => {
    handlePopUp(
      'error',
      'Something went wrong while loading more scheduled shifts'
    );
    console.log(err);
    console.log(err);
  };

  const stopLoading = () => {
    setLoading(false);
    setInitLoad(false);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (initLoad || loading) return;

    const element = e.target as HTMLDivElement;

    scrollPos.current = element.scrollTop;

    const scrollRatio =
      element.scrollTop / (element.scrollHeight - element.clientHeight);

    if (scrollRatio < -0.1 && !refreshing) {
      setRefreshing(true);
    }

    if (scrollRatio === 0 && refreshing) {
      handleRefresh()
        .then(() => setRefreshing(false))
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    }

    if (loadedAllShifts !== 'true' && scrollRatio > 0.93) {
      setOffsetTrigger(true);
    }
  };

  const handleRefresh = (): Promise<void> => {
    const newOffset = 0;
    setOffset(newOffset);
    setInitLoad(true);

    removeOldSchedule();

    return new Promise<void>(resolve => {
      loadShifts(newOffset)
        .then(() => {
          setInitLoad(false);
          resolve();
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    });
  };

  return (
    <>
      <Navbar rightTop />
      <Box
        sx={bottomSubPageLimit}
        onScroll={handleScroll}
        ref={scrollContainerRef}
      >
        <Box sx={{width: '100vw', maxWidth: '100%'}}>
          {initLoad ? (
            <EmptyPageLoader />
          ) : (
            <>
              {schedule.length === 0 && (
                <Box
                  sx={{
                    height: '80vh',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  You don&apos;t have any scheduled job yet. Applied job will be
                  shown here.
                </Box>
              )}
              {refreshing && <CustomCircularProgress />}
              {schedule.map((event: any) => (
                <Box key={event.date}>
                  <Box sx={dateBox}>{event.date}</Box>
                  {event.events.map((single: any) => (
                    <Box
                      sx={eventContainer}
                      key={single.shift_posting_id}
                      onPointerUp={() =>
                        navigate(`/jobs/${single.shift_posting_id}`, {
                          state: {jobTenantId: single.tenant_id},
                        })
                      }
                    >
                      {single.status === ShiftStatus.rejected ? (
                        <Box sx={redColumn} />
                      ) : single.status === ShiftStatus.applied ? (
                        <Box sx={orangeColumn} />
                      ) : single.status === ShiftStatus.confirmed ? (
                        <Box sx={greenColumn} />
                      ) : single.status === ShiftStatus.backup ? (
                        <Box sx={blueColumn} />
                      ) : single.status === 'missed' ? (
                        <Box sx={redColumn} />
                      ) : (
                        <Box sx={greyColumn} />
                      )}
                      <Box sx={detailBox}>
                        <Box sx={eventTitle}>{single.title}</Box>
                        <Box
                          sx={eventLocation}
                        >{`Status: ${single.status}`}</Box>
                        <Box
                          sx={eventLocation}
                        >{`${single.tenant_name} - ${single.location}`}</Box>
                      </Box>
                      <Box sx={timeBox}>
                        <Box sx={timeDetail}>
                          {single.approved_start
                            ? moment(single.approved_start).format('LT')
                            : ''}
                        </Box>
                        <Box sx={timeDetail}>
                          {single.approved_end
                            ? moment(single.approved_end).format('LT')
                            : ''}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ))}
              {loading && <ScrollLoader />}
            </>
          )}
        </Box>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
      <ContractChecker
        open={contractChecker}
        onClose={() => setContractChecker(!contractChecker)}
        requiredContracts={requiredContracts}
        generalCheck
      />
      <TimezoneChecker onClose={() => {}} />
      <MandatoryDocumentChecker onClose={() => {}} />
    </>
  );
};
