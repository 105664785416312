/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

export const ContractChecker = ({
  open,
  onClose,
  requiredContracts,
  generalCheck,
}: any) => {
  const navigate = useNavigate();

  const handleContractRedirection = (contract: any) => {
    if (contract.tenant_id !== undefined) {
      navigate(`/contracts/${contract.id}`, {
        state: {tenant_id: contract.tenant_id},
      });
    } else {
      navigate(`/contracts/${contract.id}`);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">Sign Employment Contract</DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          {generalCheck
            ? 'Please ensure that you have signed all the employment contracts'
            : 'Please ensure that you have signed all the employment contracts before applying for this position'}
        </Box>
        <Box sx={{maxHeight: '280px', overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {requiredContracts &&
              requiredContracts.map((contract: any) => (
                <Grid
                  container
                  key={contract?.id}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                      }}
                    >
                      {contract?.name}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    {contract?.sign_status ? (
                      <CheckCircle sx={{color: 'success.main'}} />
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{width: '50px'}}
                        onClick={() => handleContractRedirection(contract)}
                      >
                        Sign
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
