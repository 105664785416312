/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {
  listMandatoryDocuments,
  allMandatoryFromGQL,
} from '../../../providers/api/documents';
import {getCurrentLoggedUser} from '../../../providers/api/profile';
import WarningIcon from '@mui/icons-material/Warning';
import {useNavigate} from 'react-router-dom';

const checkExpiry = (expiry: string) =>
  moment(expiry, 'YYYY-MM-DDTHH:mm:ss.SSSZ').diff(moment(), 'days');

export const MandatoryDocumentChecker = ({onClose, setIsDocsExpired}: any) => {
  const expirationThreshold = 2592000000; // 30 days

  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const [expiringDocuments, setExpiringDocuments] = useState([]);
  const [expiredDocuments, setExpiredDocuments] = useState([]);
  const staffId: string = getCurrentLoggedUser();

  useEffect(() => {
    listMandatoryDocuments(staffId)
      .then(docs => {
        const expired: any = [];
        const expiring = docs.filter((item: any) => {
          const dateNow = new Date().getTime();
          const docExpiration = item.expiration;
          let isExpiring = false;

          if (docExpiration) {
            const diff = new Date(docExpiration).getTime() - dateNow;

            isExpiring = diff < expirationThreshold;

            // already expired
            if (dateNow > new Date(docExpiration).getTime()) {
              isExpiring = false;
              expired.push(item);
            }
          }

          return isExpiring;
        });

        if (expired.length || expiring.length) {
          if (setIsDocsExpired && expired.length) setIsDocsExpired(true);

          setModalOpen(true);
        }

        setExpiringDocuments(expiring);
        setExpiredDocuments(expired);
      })
      .catch(err => console.log('err', err));
  }, [staffId, setIsDocsExpired]);

  const handleRedirection = () => {
    navigate('/documents');
  };

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">
        <WarningIcon /> {'Mandatory Documents'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          {'These document/s need attention:'}
        </Box>
        <Box sx={{maxHeight: '280px', mb: 2, overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {expiredDocuments &&
              expiredDocuments.map((doc: any) => (
                <Grid
                  container
                  key={doc?.id}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                        color: 'error.dark',
                      }}
                    >
                      {`${allMandatoryFromGQL[
                        doc?.type
                      ].toUpperCase()} - Expired (${Math.abs(
                        checkExpiry(doc.expiration)
                      )} ${
                        Math.abs(checkExpiry(doc.expiration)) === 1
                          ? 'day'
                          : 'days'
                      } ago)`}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Button
                      variant="outlined"
                      sx={{width: '50px'}}
                      onClick={handleRedirection}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>

        <Box sx={{maxHeight: '280px', overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {expiringDocuments &&
              expiringDocuments.map((doc: any) => (
                <Grid
                  container
                  key={doc?.id}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                        color: 'warning.dark',
                      }}
                    >
                      {`${allMandatoryFromGQL[
                        doc?.type
                      ].toUpperCase()} - Expiring (${checkExpiry(
                        doc.expiration
                      )} ${
                        checkExpiry(doc.expiration) === 1 ? 'day' : 'days'
                      })`}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    <Button
                      variant="outlined"
                      sx={{width: '50px'}}
                      onClick={handleRedirection}
                    >
                      Upload
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={() => setModalOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
