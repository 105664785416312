export const formContainer = {
  display: 'flex',
  height: '80px',
  alignItems: 'center',
};

export const checkBoxLarge = {
  '& .MuiSvgIcon-root': {fontSize: 38, color: 'info.main'},
};

export const smallScreenCell = {
  width: {xs: '70px'},
  paddingY: {xs: 0, sm: '16px'},
  paddingRight: {xs: 0, sm: '3px'},
  paddingLeft: {xs: 0, sm: '16px'},
};

export const smallScreenCellAlt = {
  width: {xs: '70px'},
  paddingY: {xs: 0, sm: '16px'},
  paddingX: 0,
};

export const tableContainer = {
  paddingX: '5px',
  paddingBottom: '15px',
  width: '100%',
};

export const itemLabel = {
  fontSize: '18px',
  fontWeight: 'bold',
  textAlign: 'center',
};

export const selectAllButton = {
  width: '100%',
  height: '2rem',
  backgroundColor: 'info.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'info.main',
  },
  '&:disabled': {
    backgroundColor: 'secondary.main',
  },
};

export const buttonContainer = {
  display: 'flex',
  justifyContent: 'center',
  pt: {xs: 0.5, sm: 1},
};
