/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  photoIcon,
  qualiTextField,
} from '../../../ProfileViewStyles';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {CustomDatePicker} from '../../../../../../components/common/CustomDatePicker/CustomDatePicker';
import Moment from 'react-moment';
import moment, {MomentInputObject} from 'moment';
import {handleCustomError} from '../../../../../RouteErrorView/RouteErrorView';
import {
  checkSinUniqueness,
  createSinDocument,
  createStaffDocInGlobal,
} from '../../../../../../providers/api/documents';

export const InAppNewSinDoc = () => {
  const navigate = useNavigate();
  const [cert, setCert] = useState<any>('');
  const [sin, setSin] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [expiry, setExpiry] = useState<Moment | null>(null);
  const staffId = localStorage.getItem('email');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();

  const handleCertChange = (newObj: any) => setCert(newObj.type);

  const idExpired = () =>
    !moment(expiry as MomentInputObject).isAfter(moment());

  const handleExpiryChange = (newValue: Moment | null) => setExpiry(newValue);

  const handleSin = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/\s/g, '');
    if (newValue.length <= 9) {
      setSin(newValue);
    }
  };

  const validateSin = () => {
    let sumSin = 0;
    let doubledNumberSin = false;

    if (sin) {
      for (let i = sin.length - 1; i >= 0; i--) {
        const currentDigit = parseInt(sin.charAt(i), 10);
        if (doubledNumberSin) {
          if (currentDigit * 2 > 9) {
            sumSin += currentDigit * 2 - 9;
          } else {
            sumSin += currentDigit * 2;
          }
        } else {
          sumSin += currentDigit;
        }
        doubledNumberSin = !doubledNumberSin;
      }
    }
    return sumSin % 10 === 0;
  };

  const handleReturn = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => navigate('/documents'), 700);
  };

  const handleUploadPhoto = async () => {
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      return uploaded?.data?.result?.Location;
    } catch (error) {
      handleCustomError(error);
    }
  };

  const createSinOrCra = async (photo: string) => {
    try {
      const docNo = cert === 'sin' ? sin : '';
      const expiryDate = cert === 'sin' && sin.startsWith('9') ? expiry : null;

      const res = await createSinDocument(
        staffId!,
        cert,
        docNo,
        expiryDate,
        photo
      );

      const formattedType =
        cert === 'sin' ? 'mandatory_sin_default' : 'mandatory_sin_cra';

      await createStaffDocInGlobal(
        staffId!,
        res?.data?.createStaffDocument?.id,
        formattedType
      );
    } catch (error) {
      handleCustomError(error);
    }
  };

  const createSinDocInGlobal = async () => {
    setSubmitting(true);

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      if (cert === 'sin') {
        const uniqueSin = await checkSinUniqueness(sin);

        if (uniqueSin && uniqueSin.length !== 0) {
          handlePopUp('error', 'SIN has been registered');
          setSubmitting(false);
          return;
        }
      }

      const photo = await handleUploadPhoto();

      await createSinOrCra(photo);
      handleReturn();
    } catch (error) {
      handleCustomError(error);
    }
  };

  const handleSinSubmit = async () => {
    try {
      if (sin && validateSin()) {
        if (sin.slice(0, 1) === '9') {
          if (idExpired()) {
            handlePopUp('error', 'Your SIN is expired');
          } else {
            await createSinDocInGlobal();
          }
        } else {
          await createSinDocInGlobal();
        }
      } else {
        handlePopUp('error', 'Please provide a valid SIN');
      }
    } catch (error) {
      handleCustomError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      cert === 'sin' ? await handleSinSubmit() : await createSinDocInGlobal();
    } catch (error) {
      handleCustomError(error);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload SIN Document">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mx: 2,
              mt: 3,
            }}
          >
            <TextField
              value={cert}
              select
              label="select document type"
              sx={{width: '100%', textTransform: 'capitalize'}}
            >
              {SINDescList.map(o => (
                <MenuItem
                  key={o.type}
                  value={o.type}
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleCertChange(o)}
                >
                  {o.desc}
                </MenuItem>
              ))}
            </TextField>
            {cert === 'sin' && (
              <TextField
                sx={qualiTextField}
                type="text"
                name="exp"
                label="SIN number"
                value={sin}
                onChange={handleSin}
                inputProps={{
                  pattern: '\\d*',
                }}
              />
            )}
            {cert === 'sin' && sin && sin.slice(0, 1) === '9' && (
              <CustomDatePicker
                value={expiry}
                handleChange={handleExpiryChange}
                label="Expiry Date"
              />
            )}
            {cert !== '' && (
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
                <Button variant="outlined" sx={continueBtn} component="label">
                  <PhotoCamera sx={photoIcon} />
                  Upload SIN Document
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={changeHandler}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleSubmit}
                  disabled={submitting}
                >
                  {submitting ? (
                    <CircularProgress
                      size="1.3rem"
                      sx={{color: 'primary.light', fontSize: '12px'}}
                    />
                  ) : (
                    'Save'
                  )}
                </Button>
              </Box>
            )}
            <PopUp
              isOpen={popUp}
              onClose={closePopUp}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};

export const SINDescList = [
  {
    type: 'sin',
    desc: 'I have a valid SIN',
    value: 'mandatory_sin_default',
  },
  {
    type: 'cra',
    desc: 'I do NOT have a SIN, but I have registered for one',
    value: 'mandatory_sin_cra',
  },
];
