import {Box, Button, Dialog, DialogTitle} from '@mui/material';
import {CustomDialogProps} from '../../../types/types';
import {continueBtn} from '../../../views/Public/LandingView/LandingViewStyles';

export const CustomDialog = (props: CustomDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.close}>
      <Box sx={{width: '100vw', maxWidth: '100%'}}>
        <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
          {props.title}
        </DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3,
            p: 1,
          }}
        >
          <Button
            variant="outlined"
            sx={props.customLeftBtn ? props.customLeftBtn : continueBtn}
            onPointerUp={props.leftOnClick}
          >
            {props.customLeftText ? props.customLeftText : 'No'}
          </Button>
          <Button
            variant="outlined"
            sx={props.customRightBtn ? props.customRightBtn : continueBtn}
            onPointerUp={props.rightOnClick}
          >
            {props.customRightText ? props.customRightText : 'Yes'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
