/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {createRoot} from 'react-dom/client';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {appTheme} from './Theme';
import {CssBaseline, ThemeProvider} from '@mui/material';
import '../assets/scss/app.scss';
import {privateRoutes} from './routes/private';
import {publicRoutes} from './routes/public';
import {BadgeProvider} from './state/badge/BadgeContext';
import {AuthProvider} from './providers/AuthProvider';
import GoogleMapsProvider from './providers/GoogleMapsProvider';
import ReactGA from 'react-ga4';
import {useEffect} from 'react';
import {Provider} from '@rollbar/react';

// Tracking ID for Google Analytics
const TRACKING_ID = 'G-ZWBX80HQXC';
ReactGA.initialize(TRACKING_ID);

const container = document.getElementById('App') as Element;

const reactRoot = createRoot(container, {
  onRecoverableError: error => {
    console.log(`onRecoverableError: ${error}`);
    console.log(error);
  },
});

const browserRouter = createBrowserRouter([...privateRoutes, ...publicRoutes]);

console.log('CURRENT_APP_VERSION', process.env.CURRENT_APP_VERSION);

const rollbarConfig = {
  accessToken: process.env.ROLLBAR_STAFF_KEY,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.CLOUD_ENVIRONMENT,
  autoInstrument: false,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
    currentAppVersion: process.env.CURRENT_APP_VERSION,
    currentLoggedInUser: localStorage.getItem('email'),
  },
};

const RootContainer = () => {
  useEffect(() => {
    ReactGA.send({hitType: 'pageview', page: window?.location?.pathname});
  }, []);

  return (
    // <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <BadgeProvider>
          <AuthProvider>
            <GoogleMapsProvider>
              <RouterProvider router={browserRouter} />
            </GoogleMapsProvider>
          </AuthProvider>
        </BadgeProvider>
      </ThemeProvider>
    </Provider>
    // </React.StrictMode>
  );
};

reactRoot.render(<RootContainer />);
function getCurrentLoggedOps() {
  throw new Error('Function not implemented.');
}
