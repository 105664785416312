/* eslint-disable @typescript-eslint/no-explicit-any */
import {Close} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import {questionTextField} from '../../../views/Private/DigiScoreView/TrainingCenter/TrainingCenterStyles';
import {useState} from 'react';
import {app} from '../../../providers/Accounts';

import {PopUp} from '../PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {createNewSystemIssue} from '../../../providers/api/systemIssue';

export const ReportBugDialog = ({open, onClose}: any) => {
  const [text, setText] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [submitting, setSubmitting] = useState(false);

  const handleBugReport = () => {
    setSubmitting(true);
    createNewSystemIssue(app.session, text)
      .then(() => {
        setSubmitting(false);
        handlePopUp('success', 'Your issue has been submitted');
      })
      .catch((error: any) => {
        console.log(error);
        console.log(error);
      })
      .finally(() => {
        setTimeout(() => {
          onClose();
        }, 1000);
      });
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">
        Tell us more
        <IconButton
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          Please describe your issue in details here.
        </Box>
        <Box sx={{maxHeight: '280px', overflowY: 'auto'}}>
          <TextField
            multiline
            placeholder="I was trying to apply [job title] for [company name] but [error description] happened."
            variant="outlined"
            rows={10}
            sx={questionTextField}
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={handleBugReport}>
          {submitting ? (
            <CircularProgress
              size="1.3rem"
              sx={{color: 'info.main', fontSize: '12px'}}
            />
          ) : (
            'Send'
          )}
        </Button>
      </DialogActions>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={5000}
        status={status}
        message={message}
      />
    </Dialog>
  );
};
