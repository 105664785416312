/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react';
import {
  checkStaffCertificationInDefault,
  createStaffCertificationInDefaultTenant,
  createStaffCertificationInTenants,
  fetchAllCertifications,
} from '../../../../../../providers/api/certifications';
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {bottomSubPageLimit, photoIcon} from '../../../ProfileViewStyles';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {CustomTextLoad} from '../../../../../../components/common/CustomTextLoad/CustomTextLoad';
import {useNavigate} from 'react-router-dom';
import {fetchStaffProfileRaw} from '../../../../../../providers/api/profile';

import {CustomDatePicker} from '../../../../../../components/common/CustomDatePicker/CustomDatePicker';
import Moment from 'react-moment';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';

export const InAppCertDocView = () => {
  const [certList, setCertList] = useState([]);
  const [loadingCertList, setLoadingCertList] = useState(true);
  const [cert, setCert] = useState<any>({});
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const staffId = localStorage.getItem('email');
  const tenantId = localStorage.getItem('tenantId');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [certName, setCertName] = useState('');
  const navigate = useNavigate();
  const [expiry, setExpiry] = useState<Moment | null>(null);

  useEffect(() => {
    loadCertList().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadCertList = async () => {
    try {
      const res: any = await fetchAllCertifications();
      setCertList(res);
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setLoadingCertList(false);
    }
  };

  const handleCertChange = (newValue: any) => {
    setCertName(newValue?.name);
    setCert(newValue);
  };

  const handleReturn = () => {
    handlePopUp(
      'success',
      'Certification uploaded successfully. Now redirect you back...'
    );
    setTimeout(() => {
      navigate('/documents/certifications');
    }, 700);
  };

  const handleChange = (newValue: Moment | null) => setExpiry(newValue);

  const uploadCertification = async () => {
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      return uploaded?.data?.result?.Location;
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const createCertificationInGlobal = async (certPhoto: any) => {
    try {
      const docNo = new Date().toISOString();

      const response: any = await createStaffCertificationInDefaultTenant(
        staffId!,
        cert,
        docNo,
        expiry ? expiry : null,
        certPhoto
      );

      const staffProfile: any = await fetchStaffProfileRaw(staffId!);

      if (
        Array.isArray(staffProfile?.tenants) &&
        staffProfile.tenants.length !== 0
      ) {
        for (const tenant of staffProfile.tenants) {
          try {
            await createStaffCertificationInTenants(
              response?.data?.createStaffDocument?.id,
              cert,
              staffId!,
              tenant,
              tenantId!
            );
          } catch (err) {
            console.log(err);
            console.log(err);
          }
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const addCert = async () => {
    setSubmitting(true);

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const certExisted = await checkStaffCertificationInDefault(
        staffId!,
        certName
      );

      if (certExisted) {
        handlePopUp('error', 'You have uploaded this certification before.');
      } else {
        const certPhoto = await uploadCertification();
        await createCertificationInGlobal(certPhoto);
        handleReturn();
      }
    } catch (error) {
      console.log(error);
      console.log(error);
      handlePopUp('error', 'Unable to upload document');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Upload Certification">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, m: 2}}>
            {loadingCertList ? (
              <>
                <CustomTextLoad height={50} mb={10} />
                <CustomTextLoad height={50} mb={10} />
              </>
            ) : (
              <>
                <TextField
                  value={certName}
                  select
                  label="select certification"
                  sx={{width: '100%', textTransform: 'capitalize'}}
                >
                  {certList &&
                    certList.map((cert: any) => (
                      <MenuItem
                        key={cert?.id}
                        value={cert?.name}
                        onPointerUp={() => handleCertChange(cert)}
                        sx={{textTransform: 'capitalize'}}
                      >
                        {cert?.name}
                      </MenuItem>
                    ))}
                </TextField>
                <CustomDatePicker
                  value={expiry}
                  handleChange={handleChange}
                  label="Expiry Date (Optional)"
                />
              </>
            )}
            <Button variant="outlined" sx={continueBtn} component="label">
              <PhotoCamera sx={photoIcon} />
              Upload Certification
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={changeHandler}
              />
            </Button>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={addCert}
              disabled={submitting || loadingCertList || !cert.name}
            >
              {submitting ? (
                <CircularProgress
                  size="1.3rem"
                  sx={{color: 'primary.light', fontSize: '12px'}}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
