/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCurrentTabTitle} from '../../hooks/useCurrentTabTitle';

export const PublicRoute = ({children}: any) => {
  document.title = useCurrentTabTitle();

  return children;
};

export const pageGuards: any = [
  {url: '/terms', page: '0'},
  {url: '/privacy', page: '1'},
  {url: '/preparation', page: '2'},
  {url: '/info/account', page: '3'},
  {url: '/info/personal', page: '4'},
  {url: '/info/availability', page: '5'},
  {url: '/info/id', page: '6'},
  {url: '/info/cert', page: '7'},
  {url: '/info/emergency', page: '8'},
  {url: '/review', page: '9'},
];
