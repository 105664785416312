/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {listVacationBlackouts} from '@digistaff/business';
import {
  countStaffVacations,
  createStaffVacation,
  createStaffVacationCount,
  getStaffProfileTenant,
  getStaffVacationCount,
  listStaffVacations,
  updateStaffVacation,
} from '@digistaff/staff';
import {app, getCurrentUser} from '../Accounts';

export const fetchVacationHistory = async () => {
  try {
    let userId;
    userId = localStorage.getItem('email');
    if (!userId) {
      const currentUser: any = await getCurrentUser(app);
      userId = currentUser?.user?.Username;
    }

    const options = {
      filter: {staff_id: userId},
      order: [{column: 'created_at', order: 'desc'}],
    };

    const res: any = await listStaffVacations(app, options);
    const vacationHist = res?.data?.listStaffVacations;
    const historyCountRes: any = await countStaffVacations(app, options);
    const count = historyCountRes?.data?.countStaffVacations;

    return {vacationHist, count};
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchVacationCount = async () => {
  try {
    let userId;
    userId = localStorage.getItem('email');
    if (!userId) {
      const currentUser: any = await getCurrentUser(app);
      userId = currentUser?.user?.Username;
    }
    const res: any = await getStaffVacationCount(app, userId);

    const vcSummary = res?.data?.getStaffVacationCount
      ? res.data.getStaffVacationCount
      : {accumulated_days: 0, consumed_days: 0, count: 0, credit_days: 0};
    const profileRes = await getStaffProfileTenant(app, userId);
    const staffCreated = profileRes?.data?.getStaffProfileTenant?.approved_date;

    return {vcSummary, staffCreated};
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchVacationBlackout = async () => {
  try {
    const res = await listVacationBlackouts(app, {filter: {status: 'active'}});
    const datesArray = res?.data?.listVacationBlackouts;

    const blackoutDates: Date[] = [];
    datesArray.forEach((dateObj: any) => {
      const startDate = new Date(dateObj.start_date);
      const endDate = new Date(dateObj.end_date);
      const currentDate = startDate;
      while (currentDate <= endDate) {
        blackoutDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
    });

    return blackoutDates;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createVacationCount = async (staffId: string) => {
  try {
    const payload = {id: staffId, count: 0};
    return await createStaffVacationCount(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createNewVacation = async (startDate: string, endDate: string) => {
  try {
    let userId;
    userId = localStorage.getItem('email');
    if (!userId) {
      const currentUser: any = await getCurrentUser(app);
      userId = currentUser?.user?.Username;
    }
    const payload = {
      staff_id: userId,
      start_date: startDate,
      end_date: endDate,
      status: 'pending',
    };
    const res: any = await createStaffVacation(app, payload);
    const createdVacation = res.data.createStaffVacation;
    return createdVacation;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchStaffVacation = async () => {
  try {
    let userId;
    userId = localStorage.getItem('email');
    if (!userId) {
      const currentUser: any = await getCurrentUser(app);
      userId = currentUser?.user?.Username;
    }

    const vacationListRes: any = await listStaffVacations(app, {
      filter: {staff_id: userId, status: 'pending'},
      limit: 1,
      order: [{column: 'created_at', order: 'desc'}],
    });

    return vacationListRes?.data?.listStaffVacations;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const cancelVacation = async (staffVacationId: string) => {
  try {
    const payload = {
      status: 'cancelled',
    };
    return await updateStaffVacation(app, staffVacationId, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
