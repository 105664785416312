/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {WorkOutline} from '@mui/icons-material';
import {Box, Grid, TextField} from '@mui/material';
import {useEffect, useState} from 'react';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../../components/common/PopUp/PopUp';
import {
  cardBox,
  editDelete,
  emptyBox,
  leftIcon,
  leftPart,
  rightPart,
} from '../../../../../components/common/QualificationDrawer/QualificationDrawerStyles';
import {usePopUp} from '../../../../../hooks/usePopUp';
import {
  deleteStaffWorkSkill,
  fetchStaffSkillsList,
} from '../../../../../providers/api/qualification';
import {cardContainer} from '../../../JobView/JobViewStyles';
import {
  bottomSubPageLimit,
  editQualiInput,
  innerGridContainer,
  innerGridTitle,
} from '../../ProfileViewStyles';

export const SkillsListView = () => {
  const [skills, setSkills] = useState<any>([]);
  const [editIndex, setEditIndex] = useState(-1);
  const {popUp, closePopUp, handlePopUp, message, status} = usePopUp();
  const staffId = localStorage.getItem('email');

  useEffect(() => {
    loadSkill().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const loadSkill = async () => {
    try {
      if (staffId) {
        const skillRes = await fetchStaffSkillsList(staffId);
        setSkills(skillRes);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const handleDelete = (item: any) => {
    deleteStaffWorkSkill(item.id)
      .then(() => {
        handlePopUp('success', 'Removed qualification');
        loadSkill().catch(err => {
          console.log(err);
          console.log(err);
        });
      })
      .catch(err => {
        console.log(err);
        console.log(err);
        handlePopUp('error', 'Unable to remove qualification');
      });
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="All Skills">
          <Box sx={cardContainer}>
            {skills.length > 0 ? (
              skills.map((item: any, index: number) => (
                <Box sx={cardBox} key={index}>
                  <Grid container>
                    <Grid item xs={3} sx={leftPart}>
                      <WorkOutline sx={leftIcon} />
                    </Grid>
                    <Grid item xs={9} sx={rightPart}>
                      <Grid container sx={innerGridContainer}>
                        <Grid item xs={4} sx={innerGridTitle}>
                          Skill
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            sx={editQualiInput}
                            value={item.name}
                            disabled={editIndex !== index}
                            name="name"
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={innerGridContainer}>
                        <Grid item xs={4} sx={innerGridTitle}>
                          Years
                        </Grid>
                        <Grid item xs={8}>
                          <TextField
                            sx={editQualiInput}
                            value={item.duration_years}
                            disabled={editIndex !== index}
                            name="duration_years"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                      <Box sx={editDelete}>
                        <Box
                          sx={{color: 'error.main', cursor: 'pointer'}}
                          onPointerUp={() => handleDelete(item)}
                        >
                          Delete
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ))
            ) : (
              <Box sx={emptyBox}>
                Add New Skill to upgrade your qualifications
              </Box>
            )}
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
