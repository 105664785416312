/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {AvailabilityTableProps} from '../../../types/types';
import {
  buttonContainer,
  checkBoxLarge,
  itemLabel,
  selectAllButton,
  smallScreenCell,
  smallScreenCellAlt,
  tableContainer,
} from './AvailabilityTableStyles';
import {schedule} from './schedule';

export const AvailabilityTable = ({
  weekTable,
  setWeekTable,
}: AvailabilityTableProps) => {
  const toggleBox = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedDay: string,
    selectedProperty: string
  ) => {
    const newWeekTable = [...weekTable];
    const dayIndex = newWeekTable.findIndex((d: any) => d.day === selectedDay);
    const checked = event.target.checked;

    const setters: {[key: string]: () => void} = {
      morning: () => (newWeekTable[dayIndex].morning = checked),
      afternoon: () => (newWeekTable[dayIndex].afternoon = checked),
      night: () => (newWeekTable[dayIndex].night = checked),
      all: () => {
        newWeekTable[dayIndex].morning = checked;
        newWeekTable[dayIndex].afternoon = checked;
        newWeekTable[dayIndex].night = checked;
      },
    };

    setters[selectedProperty]();
    setWeekTable(newWeekTable);
  };

  const handleUnselectAll = () => {
    const updatedWeekTable = weekTable.map((day: any) => {
      return {
        ...day,
        morning: false,
        afternoon: false,
        night: false,
      };
    });
    setWeekTable(updatedWeekTable);
  };

  const checkTable = () => {
    for (let i = 0; i < schedule.length; i++) {
      if (schedule[i].morning || schedule[i].afternoon || schedule[i].night) {
        return true;
      }
    }
    return false;
  };

  return (
    <Box sx={tableContainer}>
      {checkTable() && (
        <Box sx={buttonContainer}>
          <Button
            variant="outlined"
            sx={selectAllButton}
            onPointerUp={handleUnselectAll}
          >
            Unselect All
          </Button>
        </Box>
      )}
      <Table>
        <TableHead>
          <TableRow>
            {['', 'All Day', 'Morning', 'Afternoon', 'Night'].map(
              (item: string) => (
                <TableCell key={item} align="center" sx={smallScreenCell}>
                  {item}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {weekTable.map((item: any) => (
            <TableRow key={item.day}>
              <TableCell component="th" scope="row" sx={smallScreenCell}>
                <Box sx={itemLabel}>{item.label}</Box>
              </TableCell>
              <TableCell align="center" sx={smallScreenCell}>
                <Checkbox
                  sx={checkBoxLarge}
                  checked={item.morning && item.afternoon && item.night}
                  indeterminate={
                    item.morning !== item.afternoon ||
                    item.morning !== item.night
                  }
                  onChange={event => toggleBox(event, item.day, 'all')}
                />
              </TableCell>
              <TableCell align="center" sx={smallScreenCell}>
                <Checkbox
                  sx={checkBoxLarge}
                  checked={item.morning}
                  onChange={event => toggleBox(event, item.day, 'morning')}
                />
              </TableCell>
              <TableCell align="center" sx={smallScreenCellAlt}>
                <Checkbox
                  sx={checkBoxLarge}
                  checked={item.afternoon}
                  onChange={event => toggleBox(event, item.day, 'afternoon')}
                />
              </TableCell>
              <TableCell align="center" sx={smallScreenCellAlt}>
                <Checkbox
                  sx={checkBoxLarge}
                  checked={item.night}
                  onChange={event => toggleBox(event, item.day, 'night')}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
