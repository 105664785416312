/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {Box, Button, Checkbox, MenuItem, TextField} from '@mui/material';
import moment, {Moment} from 'moment';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CustomDatePicker} from '../../../components/common/CustomDatePicker/CustomDatePicker';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {flexAlign} from '../../Private/ProfileView/ProfileViewStyles';
import {errorText, RegPersonalField} from './RegistrationStyles';
import {checkPersonalForm} from './RegistrationUtils';
import {AddressAutoComplete} from '../../../components/common/AddressAutoComplete/AddressAutoComplete';
import {
  checkOnboardingStatus,
  getUserPersonalInfo,
  updateStaffProfileWithPayload,
  updateUserProfileInPersonal,
} from '../../../providers/api/profile';
import {checkBoxLarge} from '../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {useCurrentUser} from '../../../hooks/useCurrentUser';

import {DateRangeOutlined} from '@mui/icons-material';

export const PersonalInfoView = () => {
  const [gender, setGender] = useState('');
  const [vaccineStatus, setVaccineStatus] = useState('');
  const [birthday, setBirthday] = useState<Moment | null>(null);
  const [location, setLocation] = useState({});
  const [unit, setUnit] = useState('');
  const [error, setError] = useState({
    gender: '',
    vaccineStatus: '',
    birthday: '',
    address: '',
    postalCode: '',
  });
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const staffId = sessionStorage.getItem('email');
  const {userValid, sessionInvalidRedirection, errMsg} = useCurrentUser();

  useEffect(() => {
    retrieveUserProfile().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const retrieveUserProfile = async () => {
    try {
      const retrievedProfile = await getUserPersonalInfo(staffId!);
      if (retrievedProfile) {
        const {
          genderRetrieved,
          vaccineStatusRetrieved,
          birthdayRetrieved,
          address_unit,
          addressRetrieved,
        } = retrievedProfile;

        setGender(genderRetrieved || '');
        setVaccineStatus(vaccineStatusRetrieved || '');
        setBirthday(birthdayRetrieved ? moment(birthdayRetrieved) : null);
        setUnit(address_unit || '');
        setLocation(addressRetrieved);
      }
    } catch (err: any) {
      if (!err.message.includes('null is not an object')) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const formatAddress = (address: any) => {
    const {street, city, province, postal, country} = address;
    return [street, city, province, postal, country]
      .filter(value => value !== null)
      .join(', ');
  };

  const handleGender = (e: React.ChangeEvent<HTMLInputElement>) =>
    setGender(e.target.value);

  const handleVaccineStatus = (e: React.ChangeEvent<HTMLInputElement>) =>
    setVaccineStatus(e.target.value);

  const handleBirthday = (newValue: Moment | null) => setBirthday(newValue);

  const handleUnit = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUnit(e.target.value);

  const handleCheck = () => {
    checkPersonalForm({
      gender,
      vaccineStatus,
      birthday,
      location,
      error,
      setError,
    });
    setChecked(prev => !prev);
  };

  const formHasError = () => {
    const errorFields = Object.values(error);
    for (let i = 0; i < errorFields.length; i++) {
      if (errorFields[i] !== '') {
        return true;
      }
    }
    return false;
  };

  const handleData = async () => {
    const userStatus = await userValid();
    if (!userStatus) {
      handlePopUp('error', errMsg);
      sessionInvalidRedirection();
      return;
    }

    updateUserProfileInPersonal({
      staffId,
      gender,
      vaccineStatus,
      birthday,
      unit,
      location,
    }).catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  const handleNext = async () => {
    try {
      await handleData();
      const onboardingStatus = await checkOnboardingStatus();
      if (onboardingStatus === 9) {
        navigate('/review');
      } else {
        // Only update onboarding step if is user's first time visit this page
        if (onboardingStatus === 4) {
          await updateStaffProfileWithPayload(staffId!, {
            current_onboarding_step: 5,
          });
        }
        navigate('/info/availability');
      }
    } catch (err) {
      handlePopUp(
        'error',
        'Unable to proceed to next step. Please contact admin.'
      );
      console.log(err);
      console.log(err);
    }
  };

  const cantPass = () => {
    handlePopUp(
      'error',
      'Please make sure you have filled in all information correctly'
    );
    setChecked(false);
  };

  const handleClick = () => (formHasError() ? cantPass() : handleNext());

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/account" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Personal Information">
          <Box sx={{p: 2}}>
            <Box>
              <Box sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                <Box sx={errorText}>{error.gender}</Box>
                <TextField
                  label="Gender"
                  sx={RegPersonalField}
                  select
                  value={gender}
                  onChange={handleGender}
                  id="gender-field"
                >
                  {genderList.map(option => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{textTransform: 'capitalize'}}
                      id={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Box sx={errorText}>{error.vaccineStatus}</Box>
                <TextField
                  label="Vaccination Status"
                  sx={RegPersonalField}
                  select
                  value={vaccineStatus}
                  onChange={handleVaccineStatus}
                  id="vaccine-field"
                >
                  {vaccineStatusList.map(option => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{textTransform: 'capitalize'}}
                      id={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <Box sx={errorText}>{error.birthday}</Box>
                <CustomDatePicker
                  label="Birthday"
                  value={birthday}
                  handleChange={handleBirthday}
                  maxDate={new Date()}
                />
                <Box sx={{mb: 2}} />
                <Box sx={errorText}>{error.address}</Box>
                <AddressAutoComplete
                  initialValue={
                    Object.keys(location).length === 0
                      ? ''
                      : formatAddress(location)
                  }
                  onChange={address => setLocation(address)}
                  id="address-field"
                />

                <TextField
                  label="Unit (Optional)"
                  sx={RegPersonalField}
                  value={unit}
                  onChange={handleUnit}
                  id="unit-field"
                />
                <Box sx={flexAlign}>
                  <Checkbox
                    checked={checked}
                    onChange={handleCheck}
                    sx={checkBoxLarge}
                    id="checkbox"
                  />
                  <Box sx={{fontSize: '12px'}}>
                    By checking this box I confirm that I have entered my legal
                    name. It exactly matches the name on my government issued
                    documents.
                  </Box>
                </Box>
                <Box sx={{mt: 2}}>
                  <Button
                    variant="outlined"
                    sx={continueBtn}
                    onPointerUp={handleClick}
                    disabled={!checked}
                    id="next-btn"
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};

const vaccineStatusList = [
  'none',
  '1 dose',
  '2 doses',
  '1 booster',
  '2 boosters',
  '3 boosters',
];

export const genderList = ['female', 'male', 'another gender'];
