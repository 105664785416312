import {app} from '../Accounts';
import {createStaffDigiScore, getStaffDigiScore} from '@digistaff/business';

export const fetchDigiScore = async (staffId: string) => {
  try {
    const res = await getStaffDigiScore(app, staffId);
    return res.data.getDigiscore.score;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createDigiscore = async (staffId: string) => {
  try {
    const payload = {id: staffId, score: 500};
    return await createStaffDigiScore(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
