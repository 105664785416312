export const scrollLimit = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: {xs: '58px', sm: '66px'},
  p: 0,
  overflowY: 'scroll',
  width: '100%',
};

export const msgBox = {
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflowX: 'hidden',
};

export const avatarBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mx: 1,
  borderRadius: '50%',
  border: '1px solid',
  borderColor: 'secondary.main',
};

export const textBox = {
  width: '100%',
  height: '5rem',
  display: 'flex',
  pb: 1,
  pt: 1,
  flexDirection: 'column',
  borderTop: 1,
  borderBottom: 1,
  borderColor: 'secondary.light',
};

export const nameBox = {
  fontSize: '1rem',
  width: '70%',
};

export const announcementBox = {
  display: '-webkit-box',
  width: {xs: '180px', sm: '280px', md: '500px'},
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  textTransform: 'capitalize',
};

export const timeBox = {position: 'absolute', top: '12px', right: '20px'};

export const timeBoxUnRead = {
  position: 'absolute',
  top: '12px',
  right: '20px',
  color: 'info.main',
};

export const detailBox = {
  fontSize: '0.8rem',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  width: {xs: '200px', sm: '280px', md: '500px'},
};

export const countBox = {
  position: 'absolute',
  top: '35px',
  right: '20px',
  backgroundColor: 'info.light',
  borderRadius: '15px',
  width: '25px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.6rem',
};

export const searchContent = {
  width: '80px',
  height: '80px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  m: 2,
  cursor: 'pointer',
};

export const blankBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'secondary.dark',
};
