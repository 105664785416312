import {Box, Checkbox} from '@mui/material';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {userScrollLimit} from '../LandingView/LandingViewStyles';
import {flexAlign} from '../../Private/ProfileView/ProfileViewStyles';
import {QuizNavigationButton} from '../../../components/common/QuizNavigationBtn/QuizNavigationButton';
import {useNavigate} from 'react-router-dom';
import {termsBox} from './RegistrationStyles';
import {useState} from 'react';
import {checkBoxLarge} from '../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {PrivacyPolicy} from '../../../components/common/GeneralTermsAndPrivacy/PrivacyPolicy';

export const PrivacyTermsView = () => {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const handleCheck = () => setChecked(prev => !prev);

  const handleNext = () => {
    navigate('/preparation');
  };

  const handlePrev = () => navigate(-1);

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/terms" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Privacy Policy">
          <Box sx={{p: 2}}>
            <Box sx={termsBox}>
              <PrivacyPolicy />
            </Box>
            <Box sx={{my: 3}}>
              <Box sx={flexAlign}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  sx={checkBoxLarge}
                />
                <Box sx={{fontSize: '12px'}}>
                  By checking this box I confirm that I have read and agree to
                  be bound by the Agreement above. I also confirm that I am of
                  the legal age of majority in the jurisdiction in which reside
                  (at least 18 years of age in many countries).
                </Box>
              </Box>
            </Box>
            <QuizNavigationButton
              rightDisabled={!checked}
              leftOnClick={handlePrev}
              rightOnClick={handleNext}
            />
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
