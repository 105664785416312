/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {bottomSubPageLimit, flexCtrCtr} from './ProfileViewStyles';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {useEffect, useState} from 'react';

import {fetchEquipmentRental} from '../../../providers/api/equipment';
import {CustomTextLoad} from '../../../components/common/CustomTextLoad/CustomTextLoad';
import moment from 'moment';

export const EqRentalView = () => {
  const staffId = localStorage.getItem('email');
  const [eqList, setEqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const eqListHistory = eqList && eqList.slice(startIndex, endIndex);

  useEffect(() => {
    const loadEqRentalList = async () => {
      try {
        const res = await fetchEquipmentRental(staffId!);
        setEqList(res);
        setCount(res?.length);
        setLoading(false);
      } catch (error) {
        console.log(error);
        console.log(error);
      }
    };
    loadEqRentalList().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [staffId]);

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Equipment Rental">
          {loading ? (
            <Box sx={{width: '100%'}}>
              <CustomTextLoad height={500} fullWidth />
            </Box>
          ) : (
            <>
              {eqListHistory.length === 0 ? (
                <Box sx={{...flexCtrCtr, mt: '200px'}}>
                  You don&apos;t have any equipment rental history yet.
                </Box>
              ) : (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Rental Date</TableCell>
                        <TableCell align="center">Equipment</TableCell>
                        <TableCell align="right">Expiry Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {eqListHistory.map((row: any) => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            <Box>
                              {moment(row.rental_date).format('DD MMM YYYY')}
                            </Box>
                            <Box>{moment(row.rental_date).format('LT')}</Box>
                          </TableCell>
                          <TableCell align="center">
                            {row.equipment_id}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Box>
                              {moment(row.expiration).format('DD MMM YYYY')}
                            </Box>
                            <Box>{moment(row.expiration).format('LT')}</Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{
                      '.MuiTablePagination-toolbar': {
                        width: '50%',
                        height: '35px',
                      },
                    }}
                  />
                </>
              )}
            </>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
