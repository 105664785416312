export const userScrollLimit = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '0px',
  p: 0,
  overflowY: 'scroll',
};

export const signUpBox = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100vw',
  maxWidth: '100%',
  overFlow: 'hidden',
};

export const createAccContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: {xs: '3rem', sm: '3.5rem'},
  backgroundColor: 'primary.light',
  borderTop: '1px solid',
  borderColor: 'secondary.main',
};

export const emailInput = {
  width: '100%',
  height: '53px',
  backgroundColor: 'secondary.main',
  border: 'none',
  '& .MuiInput-input': {
    marginLeft: '10px',
  },
};

export const textFieldSignIn = {
  width: '100%',
  height: '2rem',
  backgroundColor: 'secondary.main',
  border: 'none',
  '& .MuiOutlinedInput-input': {
    height: '0.55em',
  },
};

export const accTextBox = {fontWeight: 'bold', fontSize: '24'};

export const descContainer = {
  backgroundColor: 'primary.light',
  display: 'flex',
  flexDirection: 'column',
  px: 2,
  pt: {xs: '10px', sm: '20px'},
  width: '100%',
  height: {xs: '230px', sm: '340px', md: '600px', lg: '900px'},
};

export const primaryTitleBox = {fontSize: '16px', color: 'secondary.dark'};

export const secondTitleBox = {
  fontSize: '14px',
  color: 'secondary.dark',
  pb: 1,
};

export const iframeBox = {
  height: {xs: '160px', sm: '230px', md: '450px', lg: '800px'},
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export const authContainer = {
  height: {xs: '250px', sm: '270px', md: '350px'},
};

export const emailSignUp = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  height: {xs: '150px', sm: '170px'},
  px: 2,
  pt: 2,
  position: 'relative',
};

export const emailAddrBox = {
  pt: 1,
  position: 'absolute',
  top: '5px',
  display: 'flex',
  alignItems: 'center',
};

export const authSignUp = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: {xs: '40px', sm: '60px'},
  px: 2,
  pt: 2,
};

export const continueBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'info.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'info.main',
    borderColor: 'info.main',
  },
  '&:disabled': {
    backgroundColor: 'secondary.main',
  },
};

export const emailError = {
  color: 'error.main',
  fontSize: '0.7rem',
  ml: {xs: 0.5, sm: 1},
};

export const forgotPwBox = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 3,
};

export const forgotPwTxt = {color: 'secondary.dark', cursor: 'pointer'};
