import {Box, Button, CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {
  alphabetBox,
  alphabetBoxAlt,
  quizBtn,
  quizBtnAlt,
} from '../DigiScoreView/TrainingCenter/TrainingCenterStyles';
import {useEffect, useState} from 'react';
import {usePopUp} from '../../../hooks/usePopUp';
import {fetchAgencyList, fetchTenantName} from '../../../providers/api/tenancy';
import {
  bottomSubPageLimit,
  circularBox,
} from '../ProfileView/ProfileViewStyles';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {setTenantKey} from '@digistaff/app';
import {app} from '../../../providers/Accounts';

import {fetchStaffSuspendStatusByTenantId} from '../../../providers/api/profile';
import {TenantListProps} from '../../../types/types';

export const AgencySelectView = () => {
  const [agencyList, setAgencyList] = useState<TenantListProps[]>([]);
  const [loading, setLoading] = useState(true);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [currentAgent, setCurrentAgent] = useState('');
  const navigate = useNavigate();
  const staffId = localStorage.getItem('email');

  useEffect(() => {
    const selectDefaultAgent = () => {
      setTenantKey(app, 'default');
      localStorage.setItem('tenantId', 'default');
      setCurrentAgent('default');
      navigate('/jobs');
    };

    const loadAgency = async () => {
      try {
        const tenantIds = await fetchAgencyList(staffId!);

        if (tenantIds === null) {
          selectDefaultAgent();
          localStorage.setItem('tenantList', 'default');
        } else {
          const tempList = await Promise.all(
            tenantIds.map(async (tenantId: string) => {
              const tenant = await fetchTenantName(tenantId);
              const isSuspended = await fetchStaffSuspendStatusByTenantId(
                tenantId
              );
              return {...tenant, isSuspended};
            })
          );

          if (tempList[0] === null) {
            selectDefaultAgent();
            localStorage.setItem('tenantList', 'default');
          } else {
            setAgencyList(tempList);
            localStorage.setItem('tenantList', JSON.stringify(tempList));
          }
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    loadAgency().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [staffId, navigate]);

  const checkStaffSuspendStatus = async (tenantId: string) => {
    if (tenantId === 'default') return;
    try {
      const res = await fetchStaffSuspendStatusByTenantId(tenantId);
      if (res) {
        handlePopUp(
          'error',
          'Your account on this profile has been suspended by the agency admin. For further details, please get in touch with the agency.'
        );
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
      console.log(error);
      return error;
    }
  };

  const selectAgency = async (tenantId: string) => {
    const isSuspended = await checkStaffSuspendStatus(tenantId);
    if (isSuspended) return;

    localStorage.setItem('tenantId', tenantId);
    setTenantKey(app, tenantId);
    setCurrentAgent(tenantId);
    handlePopUp('success', 'Selected agency');
    setTimeout(() => {
      navigate('/jobs');
    }, 700);
  };

  return (
    <>
      <Navbar />
      <Box sx={bottomSubPageLimit}>
        {loading ? (
          <Box sx={circularBox}>
            <CircularProgress />
          </Box>
        ) : (
          <PageWrapper title="Select Agency Profile">
            <Box sx={{px: 2}}>
              {agencyList.length === 0 && (
                <Button
                  variant="outlined"
                  sx={currentAgent === 'default' ? quizBtnAlt : quizBtn}
                  onPointerUp={() => selectAgency('default')}
                >
                  <Box
                    sx={
                      currentAgent === 'default' ? alphabetBoxAlt : alphabetBox
                    }
                  >
                    1
                  </Box>
                  DigiJobs
                </Button>
              )}

              {agencyList.map((agent: TenantListProps, idx: number) => (
                <Button
                  variant="outlined"
                  key={agent.id}
                  sx={agent.id === currentAgent ? quizBtnAlt : quizBtn}
                  name={agent.id}
                  onPointerUp={() => selectAgency(agent.id)}
                >
                  <Box
                    sx={
                      agent.id === currentAgent ? alphabetBoxAlt : alphabetBox
                    }
                  >
                    {1 + idx}
                  </Box>
                  {agent.name} {agent.isSuspended && '(Suspended)'}
                </Button>
              ))}
            </Box>
          </PageWrapper>
        )}
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
