import {Box, Grid} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {bottomSubPageLimit} from '../ProfileView/ProfileViewStyles';
import ForkLiftingBadge from '../../../../assets/images/ForkLiftingBadge.svg';
import SuperStarBadge from '../../../../assets/images/SuperStarBadge.svg';
import FirstShiftBadge from '../../../../assets/images/FirstShiftBadge.svg';
import MessageBadge from '../../../../assets/images/MessageBadge.svg';
import {badgeBox, badgeIcon} from './DigiScoreStyle';

export const BadgeView = () => {
  const allAchievements = [
    {date: '7 Jan 2023', src: MessageBadge},
    {date: '27 Dec 2022', src: SuperStarBadge},
    {date: '25 Nov 2022', src: ForkLiftingBadge},
    {date: '18 Aug 2022', src: FirstShiftBadge},
  ];

  return (
    <>
      <Navbar rightTop backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="All Badges">
          <Grid container>
            {allAchievements.map(item => (
              <Grid item xs={6} key={item.src} sx={badgeBox}>
                <Box component="img" src={item.src} sx={badgeIcon} />
                <Box sx={{color: 'secondary.main'}}>{item.date}</Box>
              </Grid>
            ))}
          </Grid>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
