/* eslint-disable @typescript-eslint/no-explicit-any */
import {Alert, Avatar, Box, Grid, Skeleton, Snackbar} from '@mui/material';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {bottomSubPageLimit} from '../../ProfileView/ProfileViewStyles';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';
import {
  avatarTraining,
  gridContainerTraining,
  infoCardBottom,
  infoCardBox,
  infoCardTextBox,
  infoCardTop,
  instructBox,
  modelDesc,
  modelTitle,
  recommendBox,
  scoreBox,
  trainingModelBox,
} from './TrainingCenterStyles';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Info} from '@mui/icons-material';
import {
  fetchTrainingModules,
  fetchTrainingSession,
} from '../../../../providers/api/training';
import {ScrollLoader} from '../../../../components/common/Loader/ScrollLoader';
import {fetchDigiScore} from '../../../../providers/api/digiScore';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';
import {getUserNameAndPic} from '../../../../providers/api/profile';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#3E82BF',
  },
}));

export const TrainingCenterMainView = () => {
  const [trainingCourses, setTrainingCourses] = useState<any>([]);
  const [snackBar, setSnackBar] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [trainingStatus, setTrainingStatus] = useState<Map<string, string>>(
    new Map()
  );
  const staffId = localStorage.getItem('email');
  const [digiScore, setDigiScore] = useState(null);
  const [scoreLoading, setScoreLoading] = useState(true);
  const [profilePic, setProfilePic] = useState('');
  const [picLoading, setPicLoading] = useState(true);
  const [userName, setUserName] = useState('');
  const minScore = 100;
  const maxScore = 800;
  const tenantId = localStorage.getItem('tenantId');

  useEffect(() => {
    const loadStaffProfilePic = () => {
      if (staffId) {
        getUserNameAndPic(staffId)
          .then(res => {
            setUserName(`${res.first_name} ${res.last_name}`);

            setProfilePic(res.avatar);
          })
          .catch(err => {
            console.log(err);
            console.log(err);
          })
          .finally(() => setPicLoading(false));
      }
    };

    const loadDigiScore = () => {
      if (staffId) {
        setScoreLoading(true);
        fetchDigiScore(staffId)
          .then(res => setDigiScore(res))
          .catch(err => {
            console.log(err);
            console.log(err);
          })
          .finally(() => setScoreLoading(false));
      }
    };

    loadStaffProfilePic();
    loadDigiScore();
    if (tenantId !== 'default') {
      loadTrainingModules();
    }
  }, [staffId, tenantId]);

  useEffect(() => {
    const loadTrainingSession = () => {
      if (trainingCourses.length > 0) {
        const newTrainingStatus = new Map<string, string>();
        trainingCourses.forEach(async (course: any) => {
          try {
            const trainingSession = await fetchTrainingSession(course.id);

            if (trainingSession.length === 0) {
              newTrainingStatus.set(course.id, '');
            } else if (trainingSession[0].status === 'pending') {
              newTrainingStatus.set(course.id, 'In Progress');
            } else if (trainingSession[0].status === 'pass') {
              newTrainingStatus.set(course.id, 'Completed');
            } else if (trainingSession[0].status === 'fail') {
              newTrainingStatus.set(course.id, 'Retake');
            } else {
              newTrainingStatus.set(course.id, '');
            }

            setTrainingStatus(new Map(newTrainingStatus));
          } catch (err) {
            console.log(err);
            console.log(err);
          }
        });
      }
    };

    loadTrainingSession();
  }, [trainingCourses]);

  const loadTrainingModules = () => {
    fetchTrainingModules()
      .then(res => setTrainingCourses(res))
      .catch(err => {
        console.log(err);
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleSnackBar = () => setSnackBar(!snackBar);

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Training Center">
          <Box sx={infoCardBox}>
            <Box sx={infoCardTop}>
              <Box sx={infoCardTextBox}>Welcome Back! {userName}</Box>
              {picLoading ? (
                <Skeleton
                  sx={avatarTraining}
                  variant="circular"
                  width={50}
                  height={50}
                />
              ) : (
                <Avatar sx={avatarTraining} src={profilePic} />
              )}
            </Box>
            <Box sx={infoCardBottom}>
              <Grid container sx={gridContainerTraining}>
                <Grid item xs={5}>
                  Your DigiScore
                </Grid>
                <Grid item xs={7}>
                  {scoreLoading ? (
                    <CustomTextLoad height={10} fullWidth />
                  ) : (
                    <BorderLinearProgress
                      variant="determinate"
                      value={digiScore ? (digiScore / 800) * 100 : 0}
                    />
                  )}
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={7} sx={scoreBox}>
                  <Box>{minScore}</Box>
                  <Box>{maxScore}</Box>
                </Grid>
                <Grid item xs sx={recommendBox} onClick={handleSnackBar}>
                  <Box sx={{fontSize: '13px', color: 'secondary.dark'}}>
                    Recommendations to improve DigiScore
                  </Box>
                  <Info sx={{fontSize: '16px', color: 'secondary.dark'}} />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={instructBox}>
            <Box sx={{fontWeight: 'bold'}}>Instructions</Box>
            <Box>
              Allow some time to complete your training modules. All training
              modules assigned to you by the agency must be completed in order
              for you to begin work!
            </Box>
          </Box>
          {loading ? (
            <ScrollLoader />
          ) : (
            <Grid container spacing={2} sx={{px: 2, mb: 2}}>
              {trainingCourses.map((item: any) => (
                <Grid item xs={12} key={item.id}>
                  <Box
                    sx={trainingModelBox}
                    onPointerUp={() => navigate(`/training/course/${item.id}`)}
                  >
                    <Box sx={modelTitle}>{item.title}</Box>
                    <Box sx={modelDesc}>{item.desc}</Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'primary.main',
                      }}
                    >
                      {trainingStatus.get(item.id) || ''}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
      <Snackbar
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        open={snackBar}
        onClose={handleSnackBar}
        sx={{width: '100%'}}
      >
        <Alert severity="info" sx={{fontSize: '14px', mx: 2}}>
          <Box sx={{fontWeight: 'bold'}}>How to Increase Your DigiScore:</Box>
          <Box>- Showing up on time or early for shift</Box>
          <Box>- Completing a shift successfully</Box>
          <Box>
            - Responding yes to campaigns and accepting the shift when offered
            by ops
          </Box>
          <Box>- Great supervisor ratings (5 out of 5)</Box>
        </Alert>
      </Snackbar>
    </>
  );
};
