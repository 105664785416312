/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Avatar, LinearProgress} from '@mui/material';
import {BottomBar} from '../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {
  announcementBox,
  avatarBox,
  countBox,
  detailBox,
  msgBox,
  nameBox,
  scrollLimit,
  textBox,
  timeBox,
} from './ChatViewStyles';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {WorkOutline} from '@mui/icons-material';
import {CustomSlider} from '../../../components/common/Animation/CustomSlider';
import {useEffect, useState} from 'react';
import {
  fetchChatConversations,
  fetchMsgCount,
} from '../../../providers/api/chat';
import {DSGQLSubscription} from '@digistaff/common';
import {getAppsyncStaffAPI, chatSubscriptionGQL} from '@digistaff/notification';
import {EmptyPage} from '../../../components/common/EmptyPageLoader/EmptyPageLoader';
import {app} from '../../../providers/Accounts';

export const ChatView = () => {
  const navigate = useNavigate();
  const [conversation, setConversation] = useState<any>([]);
  const staffId = localStorage.getItem('email');
  const tenantId = localStorage.getItem('tenantId');
  const [loading, setLoading] = useState(true);
  const appsyncAPI = getAppsyncStaffAPI(app.environment);

  useEffect(() => {
    if (tenantId === 'default') {
      setLoading(false);
      setConversation([]);
      return;
    }
    loadConversations().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  useEffect(() => {
    const gqlSubs: any[] = [];
    for (const index in conversation) {
      const gqlSub = new DSGQLSubscription(chatSubscriptionGQL, appsyncAPI, {
        id: conversation[index].conversation_id,
      });

      gqlSubs.push(gqlSub);

      gqlSub.on((payload: any) => {
        const newMessage = payload.payload.data.onCreateChatMessage;
        if (newMessage) {
          loadConversations().catch(err => {
            console.log(err);
            console.log(err);
          });
        }
      });
    }
    return () => {
      for (const index in gqlSubs) {
        gqlSubs[index].stop();
      }
    };
  }, [conversation]);

  const getBusinessData = () => {
    const tenants = JSON.parse(localStorage.getItem('tenantList') || '[]');
    const tenant = tenants && tenants.find((t: any) => t.id === tenantId);

    return {
      avatarSrc: tenant.logo || '',
      tenantName: tenant.name || '',
    };
  };

  const loadConversations = async () => {
    try {
      if (staffId) {
        const res = await fetchChatConversations(staffId);
        const conversationsWithBusinessData = await Promise.all(
          res.map(async (conv: any) => {
            const businessData = getBusinessData();
            const msgCount = await fetchMsgCount(
              conv.conversation_id,
              staffId!
            );

            return {...conv, ...businessData, msgCount: msgCount};
          })
        );

        setConversation(conversationsWithBusinessData);
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getMessageTime = (lastMessage: any) => {
    const messageTime = moment(lastMessage.updated_at);
    const now = moment();
    if (messageTime.isSame(now, 'day')) {
      return messageTime.format('LT');
    } else if (messageTime.isBefore(now, 'day')) {
      return messageTime.format('DD MMM YYYY');
    }
    return '';
  };

  const lastMsg = (nestListChatMessage: any) => {
    if (nestListChatMessage.length === 0) {
      return null;
    }
    const sortedMessages = [...nestListChatMessage].sort(
      (a: any, b: any) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
    );
    return sortedMessages[0];
  };

  return (
    <>
      <Navbar cancelBtn />
      <CustomSlider direction="left" fadeIn={200}>
        <Box sx={scrollLimit}>
          {loading ? (
            <LinearProgress color="info" />
          ) : (
            <>
              {conversation?.length === 0 ||
              conversation[0]?.nestGetChatConversation?.nestListChatMessage
                .length === 0 ? (
                <>
                  {tenantId === 'default' ? (
                    <EmptyPage msg="You are currently not associated with any agency or you are not in the agency profile. Please click on Me located in the bottom right corner and switch your profile to access agency-specific messages." />
                  ) : (
                    <EmptyPage
                      msg="You don't have any message yet. New message will be
                  shown here."
                    />
                  )}
                </>
              ) : (
                <>
                  {conversation &&
                    conversation.map((p: any) => (
                      <Box
                        sx={msgBox}
                        key={p.id}
                        onPointerUp={() =>
                          navigate(`/chat/${p.nestGetChatConversation.id}`, {
                            state: {param1: p.tenantName, param2: p.avatarSrc},
                          })
                        }
                      >
                        <Box sx={avatarBox}>
                          <Avatar src={p.avatarSrc || undefined}>
                            {!p.avatarSrc && <WorkOutline />}
                          </Avatar>
                        </Box>
                        <Box sx={textBox}>
                          <Box sx={nameBox}>
                            <Box sx={announcementBox}>{p.tenantName}</Box>
                          </Box>
                          <Box sx={timeBox}>
                            {getMessageTime(
                              lastMsg(
                                p.nestGetChatConversation.nestListChatMessage
                              )
                            )}
                          </Box>
                          {p.msgCount !== 0 && (
                            <Box sx={countBox}>{p.msgCount}</Box>
                          )}
                          <Box sx={detailBox}>
                            {lastMsg(
                              p.nestGetChatConversation.nestListChatMessage
                            )?.message || ''}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                </>
              )}
            </>
          )}
        </Box>
      </CustomSlider>
      <BottomBar />
    </>
  );
};
