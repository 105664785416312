import {Box, Button, Divider, Grid, MenuItem, TextField} from '@mui/material';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {
  bottomSubPageLimit,
  contentBgLayout,
  headerLabel,
  textFieldEmer,
} from '../ProfileViewStyles';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import {useEffect, useState} from 'react';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../hooks/usePopUp';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {EmergencyContactProps} from '../../../../types/types';
import {
  getEmergencyContactList,
  updateEmergencyContactApp,
} from '../../../../providers/api/emergencyContact';
import {relationships} from '../../../Public/Registration/EmergencyRegisterView';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';

const initialContactState: EmergencyContactProps = {
  relationship: '',
  name: '',
  phone: '',
};

export const EmergencyContact = () => {
  const [emerOne, setEmerOne] = useState(initialContactState);
  const [saveContact, setSaveContact] = useState(false);
  const [emerTwo, setEmerTwo] = useState(initialContactState);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const staffId = localStorage.getItem('email');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadEmergencyContact = async () => {
      if (staffId) {
        try {
          const {
            retrievedOne,
            retrievedTwo,
          }: {
            retrievedOne: EmergencyContactProps;
            retrievedTwo: EmergencyContactProps;
          } = await getEmergencyContactList(staffId);

          setEmerOne(retrievedOne);
          setEmerTwo(retrievedTwo);
          setSaveContact(true);
          setLoading(false);
        } catch (err) {
          console.log(err);
          console.log(err);
        }
      }
    };
    loadEmergencyContact().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [staffId]);

  const infoNotCompleted = (emergencyContact: EmergencyContactProps) => {
    const phone = emergencyContact.phone;
    const phoneValidity = phone && isPossiblePhoneNumber(phone);
    if (
      !emergencyContact.name ||
      !emergencyContact.relationship ||
      phoneValidity === false ||
      !phoneValidity ||
      phoneValidity === undefined
    ) {
      return true;
    }
    return false;
  };

  const updateEmergencyContact = () => {
    updateEmergencyContactApp(staffId!, emerOne, emerTwo)
      .then(() => {
        handleSaveContact();
        handlePopUp('success', 'Your Emergency Contact has been saved');
      })
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const handleSaveContact = () => setSaveContact(!saveContact);

  const handleSubmit = () => {
    if (infoNotCompleted(emerOne)) {
      handlePopUp(
        'error',
        'Please fill in at least one valid Emergency Contact in Primary'
      );
    } else {
      updateEmergencyContact();
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Emergency Contact">
          <Box sx={contentBgLayout}>
            <Box sx={{py: 1}} />
            <Box sx={headerLabel}>Primary</Box>
            {loading ? (
              <CustomTextLoad height={54} />
            ) : (
              <Grid container sx={{width: '100%'}}>
                <Grid item xs={4}>
                  <TextField
                    value={emerOne.relationship}
                    disabled={saveContact ? true : false}
                    sx={textFieldEmer}
                    select
                    label="Select Relationship"
                  >
                    {relationships.map(p => (
                      <MenuItem
                        key={p}
                        value={p}
                        onPointerUp={() =>
                          setEmerOne({...emerOne, relationship: p})
                        }
                      >
                        {p}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={textFieldEmer}
                    value={emerOne.name}
                    onChange={e =>
                      setEmerOne({...emerOne, name: e.target.value})
                    }
                    disabled={saveContact ? true : false}
                    label="Name"
                  />
                </Grid>
              </Grid>
            )}
            <Box sx={{py: 1}}>
              {loading ? (
                <CustomTextLoad height={54} />
              ) : (
                <PhoneInput
                  placeholder="Input phone number"
                  international
                  countryCallingCodeEditable
                  defaultCountry="CA"
                  value={emerOne.phone}
                  onChange={(v: string) => setEmerOne({...emerOne, phone: v})}
                  disabled={saveContact ? true : false}
                  maxLength={15}
                />
              )}
            </Box>
            <Box sx={{py: 2}}>
              <Divider />
            </Box>
            <Box sx={headerLabel}>Secondary</Box>
            {loading ? (
              <CustomTextLoad height={54} />
            ) : (
              <Grid container sx={{width: '100%'}}>
                <Grid item xs={4}>
                  <TextField
                    value={emerTwo.relationship}
                    disabled={saveContact ? true : false}
                    sx={textFieldEmer}
                    select
                    label="Select Relationship"
                  >
                    {relationships.map(p => (
                      <MenuItem
                        key={p}
                        value={p}
                        onPointerUp={() =>
                          setEmerTwo({...emerTwo, relationship: p})
                        }
                      >
                        {p}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    sx={textFieldEmer}
                    value={emerTwo.name}
                    onChange={e =>
                      setEmerTwo({...emerTwo, name: e.target.value})
                    }
                    disabled={saveContact ? true : false}
                    label="Name"
                  />
                </Grid>
              </Grid>
            )}
            <Box sx={{py: 1}}>
              {loading ? (
                <CustomTextLoad height={54} />
              ) : (
                <PhoneInput
                  international
                  countryCallingCodeEditable
                  defaultCountry="CA"
                  value={emerTwo.phone}
                  onChange={(v: string) => setEmerTwo({...emerTwo, phone: v})}
                  disabled={saveContact ? true : false}
                  maxLength={15}
                />
              )}
            </Box>
            <Box sx={{py: 1}} />
            {loading ? (
              <CustomTextLoad height={50} />
            ) : (
              <>
                {saveContact ? (
                  <Button
                    sx={continueBtn}
                    onPointerUp={handleSaveContact}
                    variant="outlined"
                    disabled={loading}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    sx={continueBtn}
                    onPointerUp={handleSubmit}
                    variant="outlined"
                  >
                    Save
                  </Button>
                )}
              </>
            )}
          </Box>
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={2000}
        status={status}
        message={message}
      />
      <BottomBar />
    </>
  );
};
