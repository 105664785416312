/* eslint-disable @typescript-eslint/no-explicit-any */
import {Avatar, Box, Chip, Toolbar, AppBar, TextField} from '@mui/material';
import {
  chipBox,
  chipBoxAlt,
  chipContainer,
  infoBox,
  leftMsgBox,
  leftMsgTick,
  leftTextBox,
  leftTime,
  rightMsgBox,
  rightMsgTick,
  rightTextBox,
  rightTime,
  warningTextBox,
  warningTick,
  warningTime,
} from './MessageViewStyles';
import {callBox, msgAppBar} from './MessageViewStyles';
import {ArrowBackIosNew, Call, WorkOutline} from '@mui/icons-material';
import {Done, DoneAll} from '@mui/icons-material';
import {avatarBox} from '../ChatViewStyles';
import moment from 'moment';
import styled from '@emotion/styled';

export const MsgAppBar = ({
  goToChat,
  senderTitle,
  avatarUrl,
  handleDialog,
}: any) => {
  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="fixed" sx={msgAppBar}>
        <Toolbar>
          <ArrowBackIosNew
            onPointerUp={goToChat}
            sx={{cursor: 'pointer', color: 'info.main'}}
          />
          <Box sx={avatarBox}>
            <Avatar src={avatarUrl || undefined}>
              {!avatarUrl && <WorkOutline />}
            </Avatar>
          </Box>
          <Box sx={{textTransform: 'capitalize', color: 'primary.main'}}>
            {senderTitle}
          </Box>
          {senderTitle === 'Company Announcement' && (
            <Box sx={callBox} onPointerUp={handleDialog}>
              <Call />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export const getMessageTime = (createdAt: string) => {
  const messageTime = moment(createdAt);
  const now = moment();
  if (messageTime.isSame(now, 'day')) {
    return messageTime.format('LT');
  } else if (messageTime.isBefore(now, 'day')) {
    return messageTime.format('DD MMM HH:mm');
  }
  return '';
};

export const MsgBoxRight = ({item, profilePic}: any) => {
  return (
    <Box sx={rightMsgBox}>
      <Box sx={rightTextBox}>
        {item.message.split('\n').map((line: string, index: number) => (
          <div key={index}>{line}</div>
        ))}
        <Box sx={infoBox}>
          <Box sx={rightTime}>{getMessageTime(item.created_at)}</Box>
          {item.status === 'seen' ? (
            <DoneAll sx={leftMsgTick} />
          ) : (
            <Done sx={leftMsgTick} />
          )}
        </Box>
      </Box>
      <Box sx={avatarBox}>
        <Avatar src={profilePic} />
      </Box>
    </Box>
  );
};

export const MsgBoxLeft = ({
  avatar,
  item,
  responseStates,
  handleReject,
  handleApply,
}: any) => {
  return (
    <>
      <Box sx={leftMsgBox}>
        <Box sx={avatarBox}>
          <Avatar src={avatar} />
        </Box>
        <Box sx={item.type === 'staff_warning' ? warningTextBox : leftTextBox}>
          {item.message}
          <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
            <Box sx={item.type === 'staff_warning' ? warningTime : leftTime}>
              {getMessageTime(item.created_at)}
            </Box>
            {item.status === 'seen' ? (
              <DoneAll
                sx={item.type === 'staff_warning' ? warningTick : rightMsgTick}
              />
            ) : (
              <Done
                sx={item.type === 'staff_warning' ? warningTick : rightMsgTick}
              />
            )}
          </Box>
          {item.type && item.type === 'shift_instance' && (
            <Box sx={chipContainer}>
              <Chip
                clickable
                label="No"
                sx={
                  responseStates[item.id] === 'no' ||
                  item.reference_response === 'no'
                    ? chipBoxAlt
                    : chipBox
                }
                onPointerUp={() => handleReject(item)}
              />
              <Chip
                clickable
                label="Yes"
                sx={
                  responseStates[item.id] === 'yes' ||
                  item.reference_response === 'yes'
                    ? chipBoxAlt
                    : chipBox
                }
                onPointerUp={() => handleApply(item)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export const MsgInputWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column-reverse',
  height: '100%',
});

export const MsgInputField = styled(TextField)(({theme}: any) => ({
  width: '100%',
  '& .MuiFormControl-root': {
    width: '100%',
  },
  '& .MuiInput-root': {
    padding: theme.spacing(1),
    borderRadius: '10px',
    overflowY: 'auto',
  },
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .MuiInputBase-root': {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '10px',
  },
}));

export const MsgInputContainer = styled(Box)(({theme}: any) => ({
  position: 'fixed',
  bottom: '68px',
  width: '100%',
  display: 'flex',
  padding: theme.spacing(1),
}));
