/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
declare const window: any;

import {createContext, useCallback, useEffect} from 'react';
import {AuthContextInterface} from '../types/types';
import {app, getCurrentUser, signOut} from '../providers/Accounts';
import {Bridge} from '../Bridge';
import {getCurrentToken} from '@digistaff/app';
import {setStaffFCMToken} from './api/profile';
import {handleCustomError} from '../views/RouteErrorView/RouteErrorView';

export const AuthContext = createContext<AuthContextInterface>(null!);

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const logOut = useCallback(async (isGetCurrentUser = false) => {
    try {
      if (app.session?.accessToken) await signOut(app);
      localStorage.clear();
      handleAuthRedirect(isGetCurrentUser);
    } catch (error) {
      // Prevent in case access token expired before calling sign out, user will still be redirected to login page
      getCurrentUser(app).catch(() => {
        localStorage.clear();
        handleAuthRedirect(isGetCurrentUser);
      });
    }
  }, []);

  // params is for stopping infinite reload
  const handleAuthRedirect = (isGetCurrentUser: boolean) => {
    if (!isGetCurrentUser) {
      window.location.href = '/';
    }
  };

  const submitFCMToken = async () => {
    try {
      const fcmToken = await Bridge.getFCMToken();
      setStaffFCMToken(fcmToken).catch(err => {
        console.log(err);
        console.log(err);
      });
    } catch (error) {
      if (error === undefined) {
        return Promise.resolve();
      } else {
        handleCustomError(error);
      }
    }
  };

  const setAuthToken = async (authToken: string) => {
    try {
      await Bridge.setAuthToken(authToken);
    } catch (error) {
      if (error === undefined) {
        return Promise.resolve();
      } else {
        handleCustomError(error);
      }
    }
  };

  useEffect(() => {
    const fetchUserAndSubmitTokens = async () => {
      try {
        // Check User login status
        await getCurrentUser(app);
        // Submits the auth token for the device to be able to send out location lat and long
        const authToken = getCurrentToken(app);
        await setAuthToken(authToken.accessToken);
        // Submits the push token transferred from the device using Device Bridge api
        await submitFCMToken();
      } catch (error: any) {
        // True for preventing infinite page reload
        await logOut(true);
      }
    };

    fetchUserAndSubmitTokens().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  return (
    <AuthContext.Provider value={{logOut}}>{children}</AuthContext.Provider>
  );
};
