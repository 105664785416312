/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  endTrainingSession,
  getTrainingMaterial,
  listMandatoryTrainings,
  listTrainingMaterial,
  listTrainingQuestion,
  listTrainingSession,
  startTrainingSession,
} from '@digistaff/business';
import {app, setTenantKey} from '../Accounts';
import {fileUpload} from '@digistaff/core';
import {withDefaultTenant, withTenant} from './withTenant';

export const fetchTrainingSession = async (trainingMaterialId: string) => {
  try {
    const staffId = localStorage.getItem('email');

    const filterCriteria = {
      staff_id: staffId,
      training_material_id: trainingMaterialId,
    };
    const orderCriteria = [{column: 'updated_at', order: 'desc'}];
    const options = {filter: filterCriteria, order: orderCriteria, limit: 1};
    const res = await listTrainingSession(app, options);
    const trainingSessionList = res.data.listTrainingSessions;

    return trainingSessionList;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchTrainingModules = async () => {
  try {
    const res = await listTrainingMaterial(app);

    const modules = res.data.listTrainingMaterials.map((training: any) => ({
      id: training.id,
      title: training.name,
      desc: training.description,
    }));

    return modules;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchTrainingData = async (trainingModuleId: string) => {
  try {
    const filterCriteria = {id: trainingModuleId};
    const options = {filter: filterCriteria};
    const res = await listTrainingMaterial(app, options);
    const trainingData = res.data.listTrainingMaterials[0];

    return trainingData;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const startNewTrainingSession = async (id: string) => {
  try {
    const res = await startTrainingSession(app, id);
    const newTrainingSession = res.data.createTrainingSession;

    return newTrainingSession;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchTrainingQuestions = async (trainingMaterialId: string) => {
  try {
    const filterCriteria = {training_material_id: trainingMaterialId};
    const options = {filter: filterCriteria};
    const res = await listTrainingQuestion(app, options);

    const formattedLink = (asset_link: string) => {
      const linkObj = JSON.parse(asset_link);
      return linkObj.url;
    };

    const trainingQuestions = res.data.listTrainingQuestions.map(
      (question: any) => ({
        id: question.id,
        type: question.type,
        questionTitle: question.name,
        desc: question.description,
        link: formattedLink(question.asset_link),
        img: JSON.parse(question.asset_image),
        choices: JSON.parse(question.options),
        correctAnswer: question.answer,
      })
    );

    return trainingQuestions;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const closeTrainingSession = async (
  trainingSessionId: string,
  reportData: any,
  staff_signature: string,
  first_start_time: string
) => {
  try {
    reportData[0].start = first_start_time;

    for (let i = 0; i < reportData.length - 1; i++) {
      reportData[i + 1].start = reportData[i].end;
    }

    const res = await endTrainingSession(
      app,
      trainingSessionId,
      reportData,
      staff_signature
    );

    return res?.data?.updateTrainingSession;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const uploadImageFile = async (
  base64Data: string,
  file_name: string
) => {
  try {
    const res: any = await fileUpload(
      app,
      base64Data,
      'image/jpeg',
      `${file_name}.jpeg`,
      'base64'
    );

    return res.data.result.Location;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchIndividualTraining = async (trainingId: string) => {
  try {
    const res = await getTrainingMaterial(app, trainingId);

    return res.data.getTrainingMaterial;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchMandatoryTrainingSession =
  withDefaultTenant(fetchTrainingSession);

export const fetchMandatoryTrainingData = withDefaultTenant(fetchTrainingData);

export const startNewMandatoryTrainingSession = withDefaultTenant(
  startNewTrainingSession
);

export const fetchMandatoryTrainingQuestions = withDefaultTenant(
  fetchTrainingQuestions
);

export const closeMandatoryTrainingSession =
  withDefaultTenant(closeTrainingSession);

export const uploadMandatoryImageFile = withDefaultTenant(uploadImageFile);

export const fetchTrainingSessionWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, fetchTrainingSession);

export const fetchTrainingDataWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, fetchTrainingData);

export const startNewTrainingSessionWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, startNewTrainingSession);

export const fetchTrainingQuestionsWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, fetchTrainingQuestions);

export const closeTrainingSessionWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, closeTrainingSession);

export const uploadImageFileWithTenant = (tenant_id: string) =>
  withTenant(tenant_id, uploadImageFile);

export const fetchMandatoryTrainings = async () => {
  const currentTenant = localStorage.getItem('tenantId');
  if (currentTenant === 'default') {
    const default_mandatory_tenant_key =
      process.env.CLOUD_ENVIRONMENT === 'dev'
        ? 'dev_test_tenant_key'
        : 'nzqvwhhikc';
    setTenantKey(app, default_mandatory_tenant_key);
  }
  try {
    const res = await listMandatoryTrainings(app);
    return res?.data?.listMandatoryTrainings;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  } finally {
    setTenantKey(app, currentTenant!);
  }
};
