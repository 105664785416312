/* eslint-disable @typescript-eslint/no-explicit-any */
import {Close} from '@mui/icons-material';
import {Box, Button, Drawer, TextField} from '@mui/material';
import {continueBtn} from '../../../views/Public/LandingView/LandingViewStyles';
import {
  drawerBox,
  drawerClose,
  drawerInnerBox,
  drawerTitleContainer,
  qualiJobTitle,
  qualiTextField,
} from '../../../views/Private/ProfileView/ProfileViewStyles';

export const SkillDrawer = ({open, close, handleSkills}: any) => {
  return (
    <Drawer anchor="bottom" open={open} onClose={close} sx={drawerBox}>
      <Box sx={drawerInnerBox}>
        <Box sx={drawerTitleContainer}>
          <Box sx={{fontSize: '24px'}}>Add skills</Box>
          <Close sx={drawerClose} onPointerUp={close} />
        </Box>
        <form onSubmit={handleSkills}>
          <Box sx={{width: '100%'}}>
            <Box sx={qualiJobTitle}>Skill</Box>
            <TextField sx={qualiTextField} name="skillTitle" />
            <Box sx={qualiJobTitle}>Years of experience</Box>
            <TextField sx={qualiTextField} type="number" name="exp" />
            <Box sx={{mt: 3}}>
              <Button variant="outlined" sx={continueBtn} type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};
