import {Box, Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../components/common/PageWrapper/PageWrapper';
import {continueBtn, userScrollLimit} from '../LandingView/LandingViewStyles';
import {contentBgLayout} from '../../Private/ProfileView/ProfileViewStyles';
import {prepareTitle} from './RegistrationStyles';

export const PreparationView = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/info/account');
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/privacy" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Prepare your documents">
          <Box sx={{p: 2}}>
            <Box sx={contentBgLayout}>
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                <Box>
                  To complete your DigiStaff registration, you will be required
                  to submit the following documents
                </Box>
                <Box sx={prepareTitle}>Government Issued Photo ID</Box>
                <Box>
                  All Photo ID must clearly show the following: Name, Date of
                  Birth, Photo, Signature. Accepted IDs are: Canadian
                  Driver&apos;s License or Passport from any country.
                </Box>
                <Box sx={prepareTitle}>Proof of Status</Box>
                <Box>
                  Citizenship, Permanent Residence, Work Permit, Study Permit,
                  Applied status with authorizing CRA letter with a 10 digit
                  application number
                </Box>
                <Box sx={prepareTitle}>Proof of SIN</Box>
                <Box>
                  Letter from the CRA that shows your SIN, full name, and expiry
                  if your SIN starts with 9. You may skip this step if you have
                  the CRA letter detailing your Applied Status with a 10 digit
                  application number
                </Box>
              </Box>
            </Box>
            <Box sx={{my: 3}} />
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleNext}
              id="next-btn"
            >
              next
            </Button>
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
