/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Grid,
} from '@mui/material';
import {CheckCircle} from '@mui/icons-material';
import {useNavigate} from 'react-router-dom';

export const TrainingChecker = ({open, onClose, requiredTrainings}: any) => {
  const navigate = useNavigate();

  const handleRedirect = (training: any) => {
    if (training.mandatory) {
      navigate(`/training/course/${training.id}`, {state: {mandatory: true}});
    } else {
      navigate(`/training/course/${training.id}`, {
        state: {tenant: training.tenant_id},
      });
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">Required Trainings</DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          Please ensure that you have completed all the required trainings
          before applying for position
        </Box>
        <Box sx={{maxHeight: '280px', overflowY: 'auto'}}>
          <Grid
            container
            spacing={2}
            sx={{width: '100%', mt: 0, ml: 0, pb: 0.5}}
          >
            {requiredTrainings &&
              requiredTrainings.map((training: any) => (
                <Grid
                  container
                  key={training.id}
                  spacing={2}
                  alignItems="center"
                  sx={{margin: '0px', width: '100%'}}
                >
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        wordWrap: 'break-word',
                      }}
                    >
                      {training?.name}{' '}
                      {training?.isExpired ? (
                        <span style={{color: 'red'}}>(Expired)</span>
                      ) : (
                        ''
                      )}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{display: 'flex', justifyContent: 'center'}}
                  >
                    {training?.status === 'pass' && !training?.isExpired ? (
                      <CheckCircle sx={{color: 'success.main'}} />
                    ) : (
                      <Button
                        variant="outlined"
                        onClick={() => handleRedirect(training)}
                        sx={{width: '50px'}}
                      >
                        {training?.status === 'pending'
                          ? 'Continue'
                          : training?.status === 'not started'
                          ? 'Start'
                          : 'Retake'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
