/* eslint-disable @typescript-eslint/no-explicit-any */
import {listStaffEquipmentRentals} from '@digistaff/staff';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';
import {app} from '../Accounts';

export const fetchEquipmentRental = async (staffId: string) => {
  try {
    const res: any = await listStaffEquipmentRentals(app, {
      filter: {staff_id: staffId, status: 'created'},
      order: [{column: 'created_at', order: 'desc'}],
    });

    return res?.data?.listStaffEquipmentRentals;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};
