/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {
  getWorkDocument,
  updateTenantStaffDocuments,
  updateWorkDocument,
} from '../../../../../../providers/api/documents';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  bottomSubPageLimit,
  flexAlign,
  photoIcon,
  qualiInstruct,
} from '../../../ProfileViewStyles';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {checkBoxLarge} from '../../../../../../components/common/AvailabilityTable/AvailabilityTableStyles';
import {statusList} from '../../../../../Public/Registration/ID/WorkDocRegView';
import {fetchAgencyList} from '../../../../../../providers/api/tenancy';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';

export const InAppWorkDocView = () => {
  const location = useLocation();
  const workDocId = location.state.param1;
  const workStatus = location.state.param2;
  const [option, setOption] = useState(false);
  const [checked, setChecked] = useState(false);
  const [tempStatus, setTempStatus] = useState(workStatus);
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const [imageUrl, setImageUrl] = useState('');
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const staffId = localStorage.getItem('email');
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();

  useEffect(() => {
    loadDoc().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [option]);

  const loadDoc = async () => {
    if (option) {
      try {
        const retrievedDoc: any = await getWorkDocument(workDocId);
        if (retrievedDoc) {
          setImageUrl(retrievedDoc._photo);
        } else {
          handlePopUp(
            'error',
            'Document unavailable. Now redirecting you to upload the required document...'
          );
          setTimeout(() => {
            navigate('/documents');
          }, 700);
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const handleWorkStatusChange = (newWorkStatus: string) => {
    setTempStatus(newWorkStatus);
    setChecked(false);
  };

  const handleOption = () => setOption(!option);

  const handleSubmit = async () => {
    setSubmitting(true);

    if (!staffId || !tempStatus) {
      return;
    }

    if (!isApplied()) {
      if (!fileSelected) {
        handlePopUp('error', 'Please upload document');
        setSubmitting(false);
        return;
      }
    }

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      const workPhoto = await processWorkPhoto(staffId);

      const res = await updateWorkDocument(workDocId, tempStatus, workPhoto);
      const tenantList = await fetchAgencyList(staffId!);

      if (tenantList && tenantList.length !== 0) {
        for (const tenantId of tenantList) {
          await updateTenantStaffDocuments(tenantId)(
            res.data.updateStaffDocument.id,
            'work',
            tempStatus
          );
        }
      }

      setSubmitting(false);
      closeDrawer();
    } catch (err) {
      console.log(err);
      console.log(err);
      handlePopUp('error', 'Unable to update work status');
    }
  };

  const processWorkPhoto = async (staffId: string) => {
    let workPhoto: any;

    if (!isApplied()) {
      const uploaded: any = await handleFileUpload(staffId);
      workPhoto = uploaded.data.result.Location;
    } else {
      workPhoto = '';
    }

    return workPhoto;
  };

  const closeDrawer = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => {
      navigate('/documents');
    }, 1000);
  };

  const isApplied = () => tempStatus === 'applied status';

  const isWorkOrStudy = () =>
    tempStatus === 'work permit' || tempStatus === 'study permit';

  const isCitizenOrPR = () =>
    tempStatus === 'permanent resident' || tempStatus === 'citizenship';

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Update Eligibility to Work">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 3,
              mx: 2,
            }}
          >
            {!option && (
              <>
                <Box sx={qualiInstruct}>
                  Changing your work status will require you to upload new
                  documents and reset your account verification status. Are you
                  sure you want to change your status?
                </Box>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleOption}
                >
                  Change my Work Status
                </Button>
              </>
            )}
            {option && (
              <Box
                sx={{my: 2, display: 'flex', flexDirection: 'column', gap: 2}}
              >
                <Box component="img" src={imageUrl} sx={{width: '100%'}} />
                <TextField
                  value={tempStatus}
                  select
                  label="Work Status"
                  sx={{width: '100%', textTransform: 'capitalize'}}
                >
                  {statusList.map(o => (
                    <MenuItem
                      key={o}
                      value={o}
                      onClick={() => handleWorkStatusChange(o)}
                      sx={{textTransform: 'capitalize'}}
                    >
                      {o}
                    </MenuItem>
                  ))}
                </TextField>
                {isCitizenOrPR() && (
                  <Button variant="outlined" sx={continueBtn} component="label">
                    <PhotoCamera sx={photoIcon} />
                    Upload Eligibility to Work
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={changeHandler}
                    />
                  </Button>
                )}
                {isWorkOrStudy() && (
                  <Button variant="outlined" sx={continueBtn} component="label">
                    <PhotoCamera sx={photoIcon} />
                    Upload Eligibility to Work
                    <input
                      hidden
                      accept="image/*"
                      multiple
                      type="file"
                      onChange={changeHandler}
                    />
                  </Button>
                )}
                {isApplied() && (
                  <Box>
                    Please upload the CRA letter indicating you are legally
                    allowed to work in the{' '}
                    <Box component="span" sx={{fontWeight: 'bold'}}>
                      SIN Section
                    </Box>
                    . This letter will include a 10 digit application number.
                    <Box sx={{my: 1}}>
                      Selecting the Applied status means you do NOT have a valid
                      permit or sin but you have a letter from the CRA allowing
                      you to work
                    </Box>
                    <Box sx={{py: 1}} />
                    <Button
                      variant="outlined"
                      sx={continueBtn}
                      onPointerUp={handleSubmit}
                    >
                      Confirm
                    </Button>
                  </Box>
                )}
                {tempStatus !== '' && !isApplied() && (
                  <>
                    <Box sx={{my: 1}}>
                      <Box sx={flexAlign}>
                        <Checkbox
                          sx={checkBoxLarge}
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                        I hereby certify that, to the best of my knowledge, the
                        provided information is true and accurate.
                      </Box>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={continueBtn}
                      onPointerUp={handleSubmit}
                      disabled={submitting || !checked}
                    >
                      {submitting ? (
                        <CircularProgress
                          size="1.3rem"
                          sx={{color: 'primary.light', fontSize: '12px'}}
                        />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </>
                )}
              </Box>
            )}
            <PopUp
              isOpen={popUp}
              onClose={() => setPopUp(false)}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
