import {Skeleton} from '@mui/material';

export interface CustomTextLoadProps {
  height: number;
  mb?: number;
  width?: number;
  fullWidth?: boolean;
}

export const CustomTextLoad = ({
  height,
  mb,
  width,
  fullWidth,
}: CustomTextLoadProps) => {
  return (
    <Skeleton
      variant="rectangular"
      height={height}
      width={fullWidth ? '100%' : width}
      style={{marginBottom: `${mb}px`}}
    />
  );
};
