import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {bottomSubPageLimit} from '../../ProfileViewStyles';

export const AppointmentHistory = () => {
  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Appointment History">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointmentHistory.map(item => (
                <TableRow key={item.date}>
                  <TableCell component="th" scope="row">
                    {item.date}
                    <br />
                    {item.timeSlot}
                  </TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};

const appointmentHistory = [
  {
    type: 'cheque pick up',
    date: '4-Dec-2022',
    timeSlot: '11:45 AM',
    status: 'checked in',
  },
  {
    type: 'cheque pick up',
    date: '14-Nov-2022',
    timeSlot: '10:45 AM',
    status: 'checked in',
  },
  {
    type: 'account issue',
    date: '6-Nov-2022',
    timeSlot: '12:45 AM',
    status: 'checked in',
  },
  {
    type: 'cheque pick up',
    date: '4-Nov-2022',
    timeSlot: '13:25 AM',
    status: 'checked in',
  },
  {
    type: 'cheque pick up',
    date: '4-Oct-2022',
    timeSlot: '11:45 AM',
    status: 'checked in',
  },
  {
    type: 'cheque pick up',
    date: '4-Sep-2022',
    timeSlot: '14:45 AM',
    status: 'checked in',
  },
  {
    type: 'cheque pick up',
    date: '4-Aug-2022',
    timeSlot: '15:45 AM',
    status: 'checked in',
  },
];
