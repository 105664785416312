/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {TextField} from '@mui/material';
import {Autocomplete} from '@react-google-maps/api';
import {useEffect, useState} from 'react';
import {RegPersonalField} from '../../../views/Public/Registration/RegistrationStyles';

export interface AutoCompleteProps {
  initialValue?: string;
  placeHolder?: string;
  onChange: (addressData: Object) => void;
  disabled?: any;
  style?: any;
  id?: any;
}

/**
 * Displays a TextField that will provide address suggestions
 *
 * @remarks
 * Uses the Google places API & react-google-maps package
 *
 * @param initialValue - Optional initial value for the TextField
 * @param placeholder - Optional placeholder for the TextField
 * @param onChange - Handles what happens when the value of the TextField changes
 */
export const AddressAutoComplete = ({
  initialValue,
  onChange,
  placeHolder,
  disabled,
  style,
  id,
}: AutoCompleteProps) => {
  const [text, setText] = useState('');
  const [place, setPlace] = useState<any>(null);

  useEffect(() => {
    setText(initialValue || '');
  }, [initialValue]);

  const onLoad = (autocomplete: any) => {
    setPlace(autocomplete);
  };

  const handleChange = () => {
    if (place !== null) {
      const data = place.getPlace();
      if (data.address_components) {
        const street = `${data.address_components[0].long_name} ${data.address_components[1].long_name}`;
        const city = data.address_components[2].long_name;
        const province =
          data.address_components[data.address_components.length - 3]
            .short_name;
        const postal =
          data.address_components[data.address_components.length - 1].long_name;
        const country =
          data.address_components[data.address_components.length - 2].long_name;
        const latitude = data.geometry?.location?.lat();
        const longitude = data.geometry?.location?.lng();

        const addressData = {
          street,
          city,
          province,
          postal,
          country,
          latitude,
          longitude,
        };
        onChange(addressData);
      }
      setText(place.getPlace().formatted_address + '');
    }
  };

  return (
    <Autocomplete
      onLoad={onLoad}
      onPlaceChanged={handleChange}
      restrictions={{country: 'ca'}}
    >
      <TextField
        label="Address"
        value={text}
        onChange={event => setText(event.target.value)}
        placeholder={placeHolder}
        sx={style ? style : RegPersonalField}
        disabled={disabled}
        id={id}
      />
    </Autocomplete>
  );
};
