/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {
  getGovernmentDocument,
  updateGovernmentDocument,
  updateTenantStaffDocuments,
} from '../../../../../../providers/api/documents';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grow,
  MenuItem,
  TextField,
} from '@mui/material';
import {bottomSubPageLimit, photoIcon} from '../../../ProfileViewStyles';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {fetchAgencyList} from '../../../../../../providers/api/tenancy';
import {useCurrentUser} from '../../../../../../hooks/useCurrentUser';

export const InAppGovDocView = () => {
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [cert, setCert] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const staffId = localStorage.getItem('email');
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const govDocId = location.state.param1;
  const navigate = useNavigate();
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();

  useEffect(() => {
    fetchGovernmentDocument().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const fetchGovernmentDocument = async () => {
    if (govDocId) {
      try {
        const retrievedDoc = await getGovernmentDocument(govDocId);
        if (retrievedDoc) {
          setCert(retrievedDoc.doc_type);
          setImageUrl(retrievedDoc._photo);
        } else {
          handlePopUp(
            'error',
            'Document unavailable. Now redirecting you to upload the required document...'
          );
          setTimeout(() => {
            navigate('/documents');
          }, 1000);
        }
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    }
  };

  const updateGovDoc = async () => {
    setSubmitting(true);

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const userStatus = await userValid();
      if (!userStatus) {
        handlePopUp('error', inAppErrMsg);
        sessionInvalidRedirection();
        return;
      }

      const uploaded: any = await handleFileUpload(staffId!);
      const govPhoto = uploaded.data.result.Location;

      const res = await updateGovernmentDocument(govDocId!, cert, govPhoto);

      const tenantList = await fetchAgencyList(staffId!);

      if (tenantList && tenantList.length !== 0) {
        for (const tenantId of tenantList) {
          await updateTenantStaffDocuments(tenantId)(
            res.data.updateStaffDocument.id,
            'gov',
            cert
          );
        }
      }

      autoCloseDrawer();
    } catch (err) {
      console.log(err);
      console.log(err);
      handlePopUp('error', 'Unable to upload document');
    } finally {
      setSubmitting(false);
    }
  };

  const autoCloseDrawer = () => {
    handlePopUp(
      'success',
      'Your documents have been uploaded for verification'
    );
    setTimeout(() => navigate('/documents'), 700);
  };

  const handleSubmit = () => {
    updateGovDoc().catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  const emptyFields = () => {
    setCert('');
    setImageUrl('');
  };

  const handleCertChange = (newValue: string) => {
    emptyFields();
    setCert(newValue);
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Update Government Issued ID">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              mx: 2,
              mt: 3,
            }}
          >
            <Box component="img" src={imageUrl} sx={{width: '100%'}} />
            <TextField
              value={cert}
              select
              label="select document type"
              sx={{width: '100%', textTransform: 'capitalize'}}
            >
              {['passport', 'driver license'].map(o => (
                <MenuItem
                  key={o}
                  value={o}
                  sx={{textTransform: 'capitalize'}}
                  onClick={() => handleCertChange(o)}
                >
                  {o}
                </MenuItem>
              ))}
            </TextField>
            {cert !== '' && (
              <Grow in={true} unmountOnExit>
                <Box sx={{display: 'flex', flexDirection: 'column', gap: 3}}>
                  <Button variant="outlined" sx={continueBtn} component="label">
                    <PhotoCamera sx={photoIcon} />
                    Upload Government Issued ID
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={changeHandler}
                    />
                  </Button>
                  <Button
                    variant="outlined"
                    sx={continueBtn}
                    onPointerUp={handleSubmit}
                    disabled={submitting}
                  >
                    {submitting ? (
                      <CircularProgress
                        size="1.3rem"
                        sx={{color: 'primary.light', fontSize: '12px'}}
                      />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Box>
              </Grow>
            )}
            <PopUp
              isOpen={popUp}
              onClose={closePopUp}
              message={message}
              status={status}
            />
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
