/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {ScrollLoader} from '../../../../components/common/Loader/ScrollLoader';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {
  fetchMandatoryTrainingData,
  fetchMandatoryTrainingSession,
  fetchTrainingData,
  fetchTrainingDataWithTenant,
  fetchTrainingSession,
  fetchTrainingSessionWithTenant,
  startNewMandatoryTrainingSession,
  startNewTrainingSession,
  startNewTrainingSessionWithTenant,
} from '../../../../providers/api/training';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {bottomSubPageLimit} from '../../ProfileView/ProfileViewStyles';
import {
  courseDesc,
  courseTitle,
  materialLink,
  trainingImgBox,
  urlBox,
} from './TrainingCenterStyles';
import {usePopUp} from '../../../../hooks/usePopUp';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {Bridge} from '../../../../Bridge';

import {isTrainingSessionExpired} from '../../JobView/JobHelper';

export const TrainingVideoView = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [course, setCourse] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const location = useLocation();
  const isMandatory = location?.state?.mandatory;
  const hasTenantID = location?.state?.tenant;

  useEffect(() => {
    const loadTraining = async () => {
      let fetchData;
      if (isMandatory !== undefined && isMandatory) {
        fetchData = fetchMandatoryTrainingData;
      } else if (hasTenantID !== undefined) {
        fetchData = fetchTrainingDataWithTenant(hasTenantID);
      } else {
        fetchData = fetchTrainingData;
      }

      try {
        const res = await fetchData(id as string);
        setCourse(res);
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    loadTraining().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [id, isMandatory, hasTenantID]);

  const parseAssetLinks = () => {
    try {
      return JSON.parse(course.asset_link);
    } catch {
      return [];
    }
  };

  const openMaterial = async (index: number) => {
    const links = parseAssetLinks();
    let url = links[index]?.url;
    if (url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `https://${url}`;
      }

      await Bridge.openURL(url);
    }
  };

  const displayTrainingImage = (image: string) => {
    const linkObj = JSON.parse(image);
    const url = linkObj[0]?.url ? linkObj[0]?.url : '';
    return url;
  };

  const goToNewQuiz = () => {
    startNewTrainingSession(course.id)
      .then(res =>
        navigate(`/training/quiz/${res.id}`, {state: {param1: course.id}})
      )
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const goToNewMandatoryQuiz = () => {
    startNewMandatoryTrainingSession(course.id)
      .then(res =>
        navigate(`/training/quiz/${res.id}`, {
          state: {param1: course.id, param2: isMandatory},
        })
      )
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const goToNewAgencyQuiz = () => {
    startNewTrainingSessionWithTenant(hasTenantID)(course.id)
      .then(res =>
        navigate(`/training/quiz/${res.id}`, {
          state: {param1: course.id, param3: hasTenantID},
        })
      )
      .catch(err => {
        console.log(err);
        console.log(err);
      });
  };

  const goToPrevTraining = (trainingId: string, trainingMaterialId: string) => {
    navigate(`/training/quiz/${trainingId}`, {
      state: {param1: trainingMaterialId},
    });
  };

  const goToPrevMandatoryTraining = (
    trainingId: string,
    trainingMaterialId: string
  ) => {
    navigate(`/training/quiz/${trainingId}`, {
      state: {param1: trainingMaterialId, param2: isMandatory},
    });
  };

  const goToPrevAgencyTraining = (
    trainingId: string,
    trainingMaterialId: string
  ) => {
    navigate(`/training/quiz/${trainingId}`, {
      state: {param1: trainingMaterialId, param3: hasTenantID},
    });
  };

  const goToQuiz = () => {
    if (isMandatory) {
      fetchMandatoryTrainingSession(course.id)
        .then(res => {
          if (res.length === 0 || res[0].status === 'fail') {
            goToNewMandatoryQuiz();
          } else if (res[0].status === 'pending') {
            goToPrevMandatoryTraining(res[0].id, res[0].training_material_id);
          } else {
            if (isTrainingSessionExpired(res, res[0]?.nestGetTrainingMaterial))
              goToPrevMandatoryTraining(res[0].id, res[0].training_material_id);
            else handlePopUp('info', 'You have completed the test');
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    } else if (hasTenantID !== undefined) {
      fetchTrainingSessionWithTenant(hasTenantID)(course.id)
        .then(res => {
          if (res.length === 0 || res[0].status === 'fail') {
            goToNewAgencyQuiz();
          } else if (res[0].status === 'pending') {
            goToPrevAgencyTraining(res[0].id, res[0].training_material_id);
          } else {
            handlePopUp('info', 'You have completed the test');
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    } else {
      fetchTrainingSession(course.id)
        .then(res => {
          if (res.length === 0 || res[0].status === 'fail') {
            goToNewQuiz();
          } else if (res[0].status === 'pending') {
            goToPrevTraining(res[0].id, res[0].training_material_id);
          } else {
            handlePopUp('info', 'You have completed the test');
          }
        })
        .catch(err => {
          console.log(err);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Training Course">
          {loading ? (
            <ScrollLoader />
          ) : (
            <Box>
              <Box sx={courseTitle}>{course.name}</Box>
              <Box sx={courseDesc}>{course.description}</Box>
              {course.passing_score_percentage && (
                <Box
                  sx={courseDesc}
                >{`Required Passing Percentage: ${course.passing_score_percentage}%`}</Box>
              )}
              {displayTrainingImage(course.asset_image) && (
                <Box sx={trainingImgBox}>
                  <Box
                    component="img"
                    src={displayTrainingImage(course.asset_image)}
                  />
                </Box>
              )}
              <Box sx={urlBox}>
                {parseAssetLinks().length > 0 && (
                  <>
                    {parseAssetLinks().map((linkObj: any, index: number) => (
                      <Box
                        key={index}
                        sx={materialLink}
                        onPointerUp={() => openMaterial(index)}
                      >
                        {`Click here to view quiz material ${index + 1}: ${
                          linkObj.name
                        } (${linkObj.type}) →`}
                      </Box>
                    ))}
                  </>
                )}
              </Box>
              <Box sx={{mx: 2, mt: 2}}>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={goToQuiz}
                >
                  Go to Quiz
                </Button>
              </Box>
            </Box>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        duration={3000}
        status={status}
        message={message}
      />
    </>
  );
};
