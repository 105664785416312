/* eslint-disable @typescript-eslint/no-unused-vars */
import {Box, Button} from '@mui/material';
import {continueBtn} from '../LandingView/LandingViewStyles';
import {Bridge} from '../../../Bridge';
import {
  GPSBtnBox,
  GPSContainer,
  GPSImage,
  GPSTextBox,
} from './EnableGPSViewStyles';
import AlwaysTurnOnLocation from '../../../../assets/images/AlwaysTurnOnLocation.jpg';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

export const EnableGPSView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkLocationStatus = async () => {
      try {
        const locationStatus = await Bridge.getLocationAuthorizationStatus();

        if (locationStatus === 'AUTHORIZED_ALWAYS') {
          localStorage.setItem('hasRedirected', 'true');
          navigate('/agency/select');
        }
      } catch (err) {
        console.log(err);
      }
    };

    const intervalId = setInterval(checkLocationStatus, 1000);
    return () => clearInterval(intervalId);
  }, [navigate]);

  const handleOpenSetting = async () => {
    try {
      const locationStatus = await Bridge.getLocationAuthorizationStatus();

      if (
        locationStatus === 'DENIED' ||
        locationStatus === 'AUTHORIZED_WHEN_IN_USE'
      ) {
        await Bridge.openSettings();
      } else {
        await Bridge.requestLocationAuthorization('ALWAYS');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box sx={GPSContainer}>
      <Box sx={GPSTextBox}>
        You Must Enable Location Services in Settings 1 hour before your next
        shift:
      </Box>
      <Box
        sx={{
          mx: 4,
          mt: 2,
          py: 2,
          px: 4,
          border: '1px solid',
          borderColor: 'secondary.main',
          fontSize: '0.8rem',
          borderRadius: '8px',
        }}
      >
        <Box>We require your location for the following reasons:</Box>
        <Box>- To hold the employer accountable for pay discrepancies</Box>
        <Box>- To eliminate time fraud</Box>
        <Box>- To provide proof of attendance</Box>
      </Box>
      <Box component="img" src={AlwaysTurnOnLocation} sx={GPSImage} />
      <Box sx={GPSBtnBox}>
        <Button
          variant="outlined"
          sx={continueBtn}
          onPointerUp={handleOpenSetting}
        >
          Turn on
        </Button>
      </Box>
      <Box sx={GPSBtnBox}>
        <Button
          variant="outlined"
          onPointerUp={() => {
            localStorage.setItem('hasRedirected', 'true');
            navigate('/agency/select');
          }}
        >
          Do it later
        </Button>
      </Box>
    </Box>
  );
};
