export const GPSContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

export const GPSTextBox = {
  width: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1rem',
  textAlign: 'center',
  fontWeight: '700',
};

export const GPSBtnBox = {
  pt: 2,
  width: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const GPSImage = {height: '300px', width: 'auto', mt: 2};
