import confetti from 'canvas-confetti';

/**
 * Component for general confetti effect
 */
export const Confetti = (amount: number) => {
  // launch a few confetti from the left edge
  confetti({
    particleCount: amount,
    angle: 60,
    spread: 180,
    origin: {x: 0},
  })?.catch(err => {
    console.log(err);
    console.log(err);
  });
  // and launch a few from the right edge
  confetti({
    particleCount: amount,
    angle: 120,
    spread: 180,
    origin: {x: 1},
  })?.catch(err => {
    console.log(err);
    console.log(err);
  });
};
