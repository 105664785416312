export const filterBar = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: {xs: '3rem', sm: '3.5rem'},
  backgroundColor: 'primary.light',
  position: 'absolute',
  top: {xs: '58px', sm: '62px'},
  borderBottom: '1px solid',
  borderColor: 'secondary.main',
};

export const dropDownScrollLimit = {
  position: 'absolute',
  top: {xs: '110px', sm: '122px'},
  bottom: {xs: '125px', sm: '138px'},
  p: 0,
  overflowY: 'scroll',
};

export const filterContentContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100vw',
  maxWidth: '100%',
  overflow: 'auto',
};

export const titleContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: {xs: '3rem', sm: '3.5rem'},
  backgroundColor: 'secondary.light',
  position: 'relative',
};

export const resetBox = {
  position: 'absolute',
  left: {xs: '15px', sm: '25px'},
  cursor: 'pointer',
  color: 'error.main',
  fontWeight: 'bold',
};

export const clearIcon = {
  position: 'absolute',
  right: {xs: '15px', sm: '25px'},
  cursor: 'pointer',
};

export const formContainer = {px: 3, pt: 2};

export const formLabel = {fontSize: '1.1rem', fontWeight: 'bold'};

export const radioGroup = {pb: 2};

export const bottomButtonContainer = {
  width: '100%',
  pt: {xs: 1},
  height: {xs: '68px', sm: '72px'},
  position: 'fixed',
  bottom: {xs: '66px', sm: '72px'},
};

export const formAltBox = {
  display: 'inline-flex',
  alignItems: 'center',
  ml: '-11px',
  mr: '16px',
};

export const numberInput = {
  width: '100%',
  height: '100%',
  backgroundColor: 'secondary.main',
  border: 'none',
  '& .MuiInput-input': {
    marginLeft: '5px',
  },
  ':before': {border: 'none'},
  ':after': {border: 'none'},
  '&.MuiInput-underline:hover:before': {
    borderBottom: 'none',
  },
};

export const locationBox = {height: 'auto', pl: 1, pt: 1, cursor: 'pointer'};

export const filterList = {
  position: 'absolute',
  backgroundColor: 'secondary.light',
  width: '100%',
  height: '200px',
  zIndex: '99',
  overflow: 'auto',
};
