import {Box, CircularProgress} from '@mui/material';

export const ScrollLoader = () => {
  return (
    <Box sx={scrollLoaderCenterBox}>
      <CircularProgress sx={{color: 'info.main'}} />
    </Box>
  );
};

export const scrollLoaderCenterBox = {
  width: '100vw',
  maxWidth: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 1,
  height: '50px',
};
