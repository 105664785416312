/* eslint-disable @typescript-eslint/no-explicit-any */
import {listBusinessIndustries} from '@digistaff/business';
import {listLanguages} from '@digistaff/core';
import {app} from '../Accounts';
import {listCertificationNameAndID} from './certifications';
import {
  createStaffShiftFilter,
  getStaffShiftFilter,
  updateStaffShiftFilter,
} from '@digistaff/staff';
import {formatMoneyToFloat} from '@digistaff/common';

export const loadIndustryList = async () => {
  try {
    const industryRes = await listBusinessIndustries(app);
    const industryList = industryRes.data.listBusinessIndustries.map(
      (industry: any) => ({
        id: industry.id,
        name: industry.name,
      })
    );

    return {
      name: 'industries',
      label: 'Industry',
      array: industryList,
    };
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const loadCertificationList = async () => {
  try {
    const certListRetrieved = await listCertificationNameAndID();

    return {
      name: 'certifications',
      label: 'Certification',
      array: certListRetrieved,
    };
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const loadLanguageList = async () => {
  try {
    const langListRes = await listLanguages(app);
    const langListRetrieved = langListRes.data.listLanguages.map(
      (lang: any) => ({
        id: lang.id,
        name: lang.name,
      })
    );

    return {
      name: 'languages',
      label: 'Language',
      array: langListRetrieved,
    };
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const createNewFilterInstance = async (staffId: string) => {
  try {
    const payload = {
      id: staffId,
      filter: {
        location: '',
        salary: [1],
        industries: [],
        jobType: [],
        certifications: [],
        languages: [],
        shiftTime: [],
      },
    };
    return await createStaffShiftFilter(app, payload);
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const fetchFilterPreference = async (staffId: string): Promise<any> => {
  try {
    const res: any = await getStaffShiftFilter(app, staffId);

    let preference: any;
    if (res.data.getStaffShiftFilter === null) {
      const resCreate: any = await createNewFilterInstance(staffId);

      preference = JSON.parse(
        JSON.parse(resCreate.data.createStaffShiftFilter.filter)
      );
    } else {
      preference = JSON.parse(res.data.getStaffShiftFilter.filter);
    }

    const formattedSalary = formatMoneyToFloat(preference.salary[0], 'CAD');
    preference = {...preference, salary: [+formattedSalary]};

    return preference;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};

export const updateFilter = async (staffId: string, filterObj: any) => {
  try {
    const res = await updateStaffShiftFilter(app, staffId, {filter: filterObj});

    return res;
  } catch (err) {
    console.log(err);
    console.log(err);
    return Promise.reject(err);
  }
};
