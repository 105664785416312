import {Box, Button} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {Confetti} from '../../../../components/common/Animation/Confetti';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {bottomSubPageLimit} from '../../ProfileView/ProfileViewStyles';
import {
  scoreDesc,
  scoreIcon,
  scoreRadial,
  scoreTitle,
} from './TrainingCenterStyles';
import celebration from '../../../../../assets/images/celebration.svg';
import anxious from '../../../../../assets/images/anxious.svg';

export const ScoreView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const result = location.state.param1;

  const goToCenter = () => navigate('/training/center');

  return (
    <>
      <Navbar />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Quiz Result">
          <Box sx={{px: 2}}>
            <Box sx={scoreTitle}>{'Your Score is ...'}</Box>
            <Box sx={scoreRadial}>
              <Box sx={{fontSize: '48px'}}>{result.score}</Box>
              <Box
                component="img"
                src={result.status === 'pass' ? celebration : anxious}
                sx={scoreIcon}
              />
              <Box sx={scoreDesc}>
                <>{Confetti(result.score)}</>
                {result.status === 'pass'
                  ? 'You are unstoppable!'
                  : 'You can do better!'}
              </Box>
            </Box>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={goToCenter}
            >
              Go Back to Training Center
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};
