/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PasswordInput} from '../../../components/common/PasswordInput/PasswordInput';
import {
  accTextBox,
  continueBtn,
  createAccContainer,
  emailInput,
  signUpBox,
  userScrollLimit,
} from '../LandingView/LandingViewStyles';
import {useState} from 'react';
import {CustomSlider} from '../../../components/common/Animation/CustomSlider';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {
  formContainer,
  inputBox,
  secondaryTitle,
} from '../Registration/RegistrationStyles';
import {
  forgotPassword,
  confirmForgotPassword,
  app,
} from '../../../providers/Accounts';
import {useNavigate} from 'react-router-dom';

export const PasswordRecoveryView = () => {
  const navigate = useNavigate();
  const [showPw, setShowPw] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();

  const handleNext = () => {
    confirmForgotPassword(app, email, newPassword, code)
      .then(() => {
        handlePopUp(
          'success',
          'Password successfully changed. Redirecting you to the login page now...'
        );
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch(({err}: any) => handlePopUp('error', err.message));
  };

  const handleEmailInput = () => {
    forgotPassword(app, email)
      .then(() => handlePopUp('success', 'Code has been sent to the email'))
      .catch(err => handlePopUp('error', err.message));
  };

  return (
    <>
      <Navbar backBtn login />
      <CustomSlider direction="right" fadeIn={300}>
        <Box sx={userScrollLimit}>
          <Box sx={signUpBox}>
            <Box sx={createAccContainer}>
              <Box sx={accTextBox}>Password Recovery</Box>
            </Box>
            <Box sx={{p: 3}}>
              <Box sx={secondaryTitle}>
                Provide your registered email to receive verification code
              </Box>
            </Box>
            <Box sx={formContainer}>
              <Box sx={inputBox}>
                <TextField
                  value={email}
                  onChange={e => setEmail(e.target.value.toLowerCase())}
                  variant="outlined"
                  placeholder="Input email"
                  sx={emailInput}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{fontSize: '14px'}}
                          onPointerUp={handleEmailInput}
                        >
                          Send
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={inputBox}>
                <TextField
                  sx={emailInput}
                  placeholder="Enter your 6 digit verification code here"
                  value={code}
                  onChange={e => setCode(e.target.value)}
                />
              </Box>
              <Box sx={inputBox}>
                <PasswordInput
                  autoFocus
                  placeholder="Enter new password"
                  style={emailInput}
                  type={showPw}
                  name="password"
                  onChange={(e: any) => setNewPassword(e.target.value)}
                  value={newPassword}
                  onClickIcon={() => setShowPw(!showPw)}
                />
              </Box>
              <Box sx={{pt: 1}}>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={handleNext}
                  disabled={!newPassword || !code}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomSlider>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
