import {ChatView} from '../views/Private/ChatView/ChatView';
import {MessageView} from '../views/Private/ChatView/MessageView/MessageView';
import {CheckinView} from '../views/Private/CheckinView/CheckinView';
import {DigiScoreView} from '../views/Private/DigiScoreView/DigiScoreView';
import {DropDownView} from '../views/Private/DropDownView/DropDownView';
import {JobDetailView} from '../views/Private/JobDetailView/JobDetailView';
import {JobView} from '../views/Private/JobView/JobView';
import RouteErrorView from '../views/RouteErrorView/RouteErrorView';
import {ScheduleView} from '../views/Private/ScheduleView/ScheduleView';
import {PayoutHistoryView} from '../views/Private/ProfileView/ProfileSubPage/PayoutHistory/PayoutHistoryView';
import {ProfileView} from '../views/Private/ProfileView/ProfileView';
import {UserIDView} from '../views/Private/ProfileView/ProfileSubPage/UserIDView';
import {PrivateRoute} from './guards/PrivateRoute';
import {ContactPreferenceView} from '../views/Private/ProfileView/ProfileSubPage/ContactPreferences';
import {AvailabilityView} from '../views/Private/ProfileView/ProfileSubPage/AvailabilityView';
import {ChequePickUp} from '../views/Private/ProfileView/ProfileSubPage/Appointment/ChequePickUp';
import {SinglePayoutView} from '../views/Private/ProfileView/ProfileSubPage/PayoutHistory/SinglePayoutView';
import {EmergencyContact} from '../views/Private/ProfileView/ProfileSubPage/EmergencyContact';
import {DocumentsView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentsView';
import {Qualifications} from '../views/Private/ProfileView/ProfileSubPage/Qualification/Qualifications';
import {UserProfile} from '../views/Private/ProfileView/ProfileSubPage/UserProfile';
import {TrainingCenterMainView} from '../views/Private/DigiScoreView/TrainingCenter/TrainingCenterMainView';
import {TrainingVideoView} from '../views/Private/DigiScoreView/TrainingCenter/TrainingVideoView';
import {QuizView} from '../views/Private/DigiScoreView/TrainingCenter/QuizView';
import {ScoreView} from '../views/Private/DigiScoreView/TrainingCenter/ScoreView';
import {WorkListView} from '../views/Private/ProfileView/ProfileSubPage/Qualification/WorkListView';
import {AppointmentHistory} from '../views/Private/ProfileView/ProfileSubPage/Appointment/AppointmentHistory';
import {ProfilePicView} from '../views/Private/ProfileView/ProfileSubPage/ProfilePicView';
import {UploadIdentities} from '../views/Private/ProfileView/ProfileSubPage/Documents/UploadIdentities';
import {UploadCert} from '../views/Private/ProfileView/ProfileSubPage/Documents/UploadCert';
import {VacationCenterView} from '../views/Private/ProfileView/ProfileSubPage/Vacation/VacationCenterView';
import {VacationHistoryView} from '../views/Private/ProfileView/ProfileSubPage/Vacation/VacationSubPages/VacationHistoryView';
import {RequestVacationView} from '../views/Private/ProfileView/ProfileSubPage/Vacation/VacationSubPages/RequestVacationView';
import {SkillsListView} from '../views/Private/ProfileView/ProfileSubPage/Qualification/SkillsListView';
import {BadgeView} from '../views/Private/DigiScoreView/BadgeView';
import {AccountOverview} from '../views/Private/ProfileView/ProfileSubPage/Account/AccountOverview';
import {ChangePwView} from '../views/Private/ProfileView/ProfileSubPage/Account/ChangePwView';
import {SwitchProfileView} from '../views/Private/ProfileView/ProfileSubPage/Account/SwitchProfileView';
import {AgencySelectView} from '../views/Private/AgencySelectView/AgencySelectView';
import {InAppGovDocView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentSubPages/InAppGovDocView';
import {InAppSinDocView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentSubPages/InAppSinDocView';
import {InAppWorkDocView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentSubPages/InAppWorkDocView';
import {EmploymentAgreementView} from '../views/Private/JobView/EmploymentContract/EmploymentAgreementView';
import {InAppCertDocView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentSubPages/InAppCertDocView';
import {InAppCertUpdateView} from '../views/Private/ProfileView/ProfileSubPage/Documents/DocumentSubPages/InAppCertUpdateView';
import {InAppNewGovDoc} from '../views/Private/ProfileView/ProfileSubPage/Documents/InAppAddDocuments/InAppNewGovDoc';
import {InAppNewWorkDoc} from '../views/Private/ProfileView/ProfileSubPage/Documents/InAppAddDocuments/InAppNewWorkDoc';
import {InAppNewSinDoc} from '../views/Private/ProfileView/ProfileSubPage/Documents/InAppAddDocuments/InAppNewSinDoc';
import {EqRentalView} from '../views/Private/ProfileView/EqRentalView';
import {Contracts} from '../views/Private/ProfileView/ProfileSubPage/Documents/Contracts';
import {ViewContract} from '../views/Private/ProfileView/ProfileSubPage/Documents/ViewContract';

export const privateRoutes = [
  {
    path: '/jobs',
    element: (
      <PrivateRoute>
        <JobView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/chat',
    element: (
      <PrivateRoute>
        <ChatView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/chat/:id',
    element: (
      <PrivateRoute>
        <MessageView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/user',
    element: (
      <PrivateRoute>
        <ProfileView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/id',
    element: (
      <PrivateRoute>
        <UserIDView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/availability',
    element: (
      <PrivateRoute>
        <AvailabilityView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payout/history',
    element: (
      <PrivateRoute>
        <PayoutHistoryView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/contact/preferences',
    element: (
      <PrivateRoute>
        <ContactPreferenceView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/appointment',
    element: (
      <PrivateRoute>
        <ChequePickUp />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/appointment/history',
    element: (
      <PrivateRoute>
        <AppointmentHistory />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents',
    element: (
      <PrivateRoute>
        <DocumentsView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/identities',
    element: (
      <PrivateRoute>
        <UploadIdentities />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/government',
    element: (
      <PrivateRoute>
        <InAppGovDocView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/government/new',
    element: (
      <PrivateRoute>
        <InAppNewGovDoc />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/sin',
    element: (
      <PrivateRoute>
        <InAppSinDocView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/sin/new',
    element: (
      <PrivateRoute>
        <InAppNewSinDoc />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/work',
    element: (
      <PrivateRoute>
        <InAppWorkDocView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/document/work/new',
    element: (
      <PrivateRoute>
        <InAppNewWorkDoc />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/certifications',
    element: (
      <PrivateRoute>
        <UploadCert />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/certifications/add',
    element: (
      <PrivateRoute>
        <InAppCertDocView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/certifications/update',
    element: (
      <PrivateRoute>
        <InAppCertUpdateView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/contracts',
    element: (
      <PrivateRoute>
        <Contracts />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/documents/contracts/:id',
    element: (
      <PrivateRoute>
        <ViewContract />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/qualifications',
    element: (
      <PrivateRoute>
        <Qualifications />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/qualifications/experience/works',
    element: (
      <PrivateRoute>
        <WorkListView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/qualifications/experience/skills',
    element: (
      <PrivateRoute>
        <SkillsListView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/profile',
    element: (
      <PrivateRoute>
        <UserProfile />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/profile/profilePic',
    element: (
      <PrivateRoute>
        <ProfilePicView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/contact/emergency',
    element: (
      <PrivateRoute>
        <EmergencyContact />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/digi-score',
    element: (
      <PrivateRoute>
        <DigiScoreView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/digi-score/badges',
    element: (
      <PrivateRoute>
        <BadgeView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/training/center',
    element: (
      <PrivateRoute>
        <TrainingCenterMainView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/training/course/:id',
    element: (
      <PrivateRoute>
        <TrainingVideoView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/training/quiz/:id',
    element: (
      <PrivateRoute>
        <QuizView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/training/quiz/score',
    element: (
      <PrivateRoute>
        <ScoreView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/checkin',
    element: (
      <PrivateRoute>
        <CheckinView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/schedule',
    element: (
      <PrivateRoute>
        <ScheduleView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/filter',
    element: (
      <PrivateRoute>
        <DropDownView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/jobs/:id',
    element: (
      <PrivateRoute>
        <JobDetailView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/payments/:id',
    element: (
      <PrivateRoute>
        <SinglePayoutView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/vacation/center',
    element: (
      <PrivateRoute>
        <VacationCenterView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/vacation/request',
    element: (
      <PrivateRoute>
        <RequestVacationView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/vacation/history',
    element: (
      <PrivateRoute>
        <VacationHistoryView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/overview',
    element: (
      <PrivateRoute>
        <AccountOverview />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/password',
    element: (
      <PrivateRoute>
        <ChangePwView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/switch',
    element: (
      <PrivateRoute>
        <SwitchProfileView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/agency/select',
    element: (
      <PrivateRoute>
        <AgencySelectView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/contracts/:id',
    element: (
      <PrivateRoute>
        <EmploymentAgreementView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
  {
    path: '/account/equipment/rental',
    element: (
      <PrivateRoute>
        <EqRentalView />
      </PrivateRoute>
    ),
    children: [],
    errorElement: <RouteErrorView />,
  },
];
