import {Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {AvailabilityTable} from '../../../../components/common/AvailabilityTable/AvailabilityTable';
import {schedule} from '../../../../components/common/AvailabilityTable/schedule';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {CustomTextLoad} from '../../../../components/common/CustomTextLoad/CustomTextLoad';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  getStaffAvailabilityReg,
  updateStaffAvailabilityReg,
} from '../../../../providers/api/availability';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {weekTableEmpty} from '../../../Public/Registration/AvailabilityView';
import {bottomSubPageLimit} from '../ProfileViewStyles';

import {TimeTableResult} from '../../../../types/types';

export const AvailabilityView = () => {
  const [weekTable, setWeekTable] = useState<TimeTableResult>(schedule);
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [loading, setLoading] = useState(true);
  const staffId = localStorage.getItem('email');

  useEffect(() => {
    const loadTimeTable = async () => {
      try {
        const timeTableTemp: TimeTableResult = await getStaffAvailabilityReg(
          staffId!
        );

        !timeTableTemp ? setWeekTable(schedule) : setWeekTable(timeTableTemp);
      } catch (err) {
        console.log(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    loadTimeTable().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, [staffId]);

  const updateTimeTable = async () => {
    try {
      await updateStaffAvailabilityReg(staffId!, weekTable);
      handlePopUp('success', 'You have updated your availability');
    } catch (error) {
      handlePopUp('error', 'Unable to update table');
      console.log(error);
      console.log(error);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Availability">
          {loading ? (
            <Box sx={{width: '100%'}}>
              <CustomTextLoad height={100} fullWidth mb={30} />
              <CustomTextLoad height={500} fullWidth />
            </Box>
          ) : (
            <>
              <AvailabilityTable
                weekTable={weekTable}
                setWeekTable={setWeekTable}
              />
              <Box sx={{px: 2, pb: 2}}>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  disabled={weekTableEmpty(weekTable)}
                  onPointerUp={updateTimeTable}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
