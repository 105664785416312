import {ArrowForwardIos} from '@mui/icons-material';
import {Box, Divider} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {
  arrowRight,
  bottomSubPageLimit,
  divider,
  listContainer,
  titleBox,
} from '../../ProfileViewStyles';

export const AccountOverview = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <Box sx={{width: '100vw', maxWidth: '100%'}}>
          {legalItems.map((item: {title: string; location: string}) => (
            <Box key={item.title}>
              <Box
                sx={listContainer}
                onPointerUp={() => navigate(item.location)}
              >
                <Box sx={titleBox}>{item.title}</Box>
                <ArrowForwardIos sx={arrowRight} />
              </Box>
              <Divider sx={divider} />
            </Box>
          ))}
        </Box>
      </Box>
      <BottomBar />
    </>
  );
};

const legalItems = [
  {title: 'Change Contact Preference', location: '/contact/preferences'},
  {title: 'Change Password', location: '/account/password'},
];
