/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, ImageList, ImageListItem} from '@mui/material';
import {Moment} from 'moment';
import {useEffect, useState} from 'react';
import {
  dateBox,
  dateContainer,
  dateDisabledBox,
  dayBox,
  imageList,
  monthBox,
  selectedBox,
} from './HorizontalDatePickerStyles';

export interface formattedDate {
  id: string;
  day: string;
  date: string;
  month: string;
}

export const HorizontalDatePicker = ({
  dates,
  selectedDate,
  setSelectedDate,
}: any) => {
  const [showDates, setShowDates] = useState<formattedDate[] | null>([]);

  useEffect(() => dateChanger(dates), [dates]);

  const dateChanger = (dates: {date: Moment; disabled: boolean}[]) => {
    const newDates: formattedDate[] = dates.map(({date, disabled}) => {
      return {
        id: date.format('DD/MM/YYYY'),
        day: date.format('ddd'),
        date: date.format('DD'),
        month: date.format('MMM'),
        disabled,
      };
    });

    setShowDates(newDates);
  };

  const handleDateSelect = (item: any) => {
    if (!item.disabled) {
      setSelectedDate(item.id);
    }
  };

  return (
    <ImageList sx={imageList}>
      <>
        {showDates &&
          showDates.map((item: any) => (
            <ImageListItem
              key={item.id}
              onPointerUp={() => handleDateSelect(item)}
            >
              <Box sx={dateContainer}>
                <Box sx={monthBox}>{item.month}</Box>
                <Box sx={item.id === selectedDate ? selectedBox : null}>
                  <Box sx={item.disabled ? dateDisabledBox : dateBox}>
                    {item.date}
                  </Box>
                </Box>
                <Box sx={dayBox}>{item.day}</Box>
              </Box>
            </ImageListItem>
          ))}
      </>
    </ImageList>
  );
};
