import {ArrowForward} from '@mui/icons-material';
import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../../components/common/PageWrapper/PageWrapper';
import {
  bottomSubPageLimit,
  vacationCenterBox,
  vacationCenterClick,
  vacationCenterForward,
  vacationCenterTitle,
} from '../../ProfileViewStyles';

export const VacationCenterView = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Vacation Center">
          <Box sx={{px: 1}}>
            {vacationCenter.map(v => (
              <Box
                sx={vacationCenterBox}
                key={v.title}
                onPointerUp={() => navigate(v.nav)}
              >
                <Box sx={vacationCenterTitle}>{v.title}</Box>
                <Box sx={{ml: 2}}>{v.desc}</Box>
                <Box sx={vacationCenterClick}>
                  Click to view
                  <ArrowForward sx={vacationCenterForward} />
                </Box>
              </Box>
            ))}
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
    </>
  );
};

const vacationCenter = [
  {
    title: 'Vacation Request',
    desc: 'Request new vacation here',
    nav: '/vacation/request',
  },
  {
    title: 'Vacation History',
    desc: 'Check your earned and spent vacation here',
    nav: '/vacation/history',
  },
];
