/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import {useEffect, useState} from 'react';
import WarningIcon from '@mui/icons-material/Warning';

export const TimezoneChecker = ({onClose}: any) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [location, setLocation] = useState('');

  useEffect(() => {
    const staffAddressTimezonOffset = localStorage.getItem(
      'staffAddressTimezonOffset'
    );

    const staffProfileString = localStorage.getItem('staffProfile');

    const staffProfile = staffProfileString
      ? JSON.parse(staffProfileString)
      : {location: ''};

    if (staffAddressTimezonOffset && staffAddressTimezonOffset !== 'null') {
      setModalOpen(
        parseInt(staffAddressTimezonOffset) !== new Date().getTimezoneOffset()
      );
      setLocation(staffProfile.location ? `(${staffProfile.location})` : '');
    }
  }, []);

  return (
    <Dialog open={modalOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle textAlign="center">
        <WarningIcon /> Device timezone mismatch
      </DialogTitle>
      <DialogContent>
        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          {`Your current address ${location} doesn't match with your phone's timezone.
          This could lead to issues when scheduling and clocking for shifts.`}
        </Box>

        <Box sx={{textAlign: 'center', mb: 2, color: 'info.main'}}>
          {'Please set the correct timezone on your phone settings.'}
        </Box>
      </DialogContent>
      <DialogActions style={{justifyContent: 'center'}}>
        <Button variant="outlined" onClick={() => setModalOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
