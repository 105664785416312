/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, MenuItem, Skeleton, TextField} from '@mui/material';
import {
  datePickerLoading,
  flexCtrCtr,
  selectAppointmentTitle,
  selectTimeTitle,
  textFieldEmer,
} from '../../ProfileViewStyles';
import {continueBtn} from '../../../../Public/LandingView/LandingViewStyles';
import {HorizontalDatePicker} from '../../../../../components/common/HorizontalDatePicker/HorizontalDatePicker';
import {CustomTextLoad} from '../../../../../components/common/CustomTextLoad/CustomTextLoad';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {useEffect, useState} from 'react';
import moment from 'moment';
import {fetchBusinessLocationForAppointment} from '../../../../../providers/api/appointment';

export const BookAppointmentTab = ({
  types,
  selectedTypeId,
  handleTypeSelect,
  dates,
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  timeSlot,
  reason,
  setReason,
  handleNext,
  typeLoading,
  dateLoading,
  selectedWeekday,
  handleSelectedWeekday,
  typePurpose,
  selectedLocation,
  setSelectedLocation,
  optionalNotes,
  setOptionalNotes,
  selectedType,
  timezoneLocationSetting,
}: any) => {
  const [locations, setLocations] = useState([]);
  const [loadedLocation, setLoadedLocations] = useState(false);

  useEffect(() => {
    if (selectedWeekday) {
      const selectedDateMoment = moment(selectedWeekday);
      const selectedWeekSunday = selectedDateMoment
        .clone()
        .startOf('week')
        .toDate();
      const selectedSaturday = selectedDateMoment
        .clone()
        .endOf('week')
        .endOf('day')
        .toDate();

      const staffId = localStorage.getItem('email');
      const fetchLocations = async () => {
        try {
          const business_location_list =
            await fetchBusinessLocationForAppointment(
              staffId!,
              selectedWeekSunday,
              selectedSaturday
            );
          setLocations(business_location_list);
        } catch (error) {
          console.log(error);
        }
        setLoadedLocations(true);
      };

      fetchLocations().catch(err => {
        console.log(err);
      });
    }
  }, [selectedWeekday]);

  const shouldDisableDate = (date: any) => {
    const today = moment().startOf('day');
    const nextSaturday = moment().startOf('week').add(6, 'days');

    return !(
      date.isoWeekday() === 6 &&
      (date.isSameOrBefore(today) || date.isSame(nextSaturday))
    );
  };

  return (
    <>
      <Box sx={selectAppointmentTitle}>Select Appointment Category</Box>
      <Box sx={{p: 1, mb: 1}}>
        {typeLoading ? (
          <Box sx={flexCtrCtr}>
            <CustomTextLoad height={54} fullWidth />
          </Box>
        ) : (
          <TextField
            sx={textFieldEmer}
            select
            value={selectedTypeId}
            onChange={e => {
              const selected = types.find((t: any) => t.id === e.target.value);
              handleTypeSelect(selected);
            }}
          >
            {types.map((t: any) => (
              <MenuItem
                key={t.id}
                value={t.id}
                sx={{textTransform: 'capitalize'}}
              >
                {t.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      {selectedTypeId && (
        <>
          <Box sx={selectAppointmentTitle}>Select Date</Box>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            {dateLoading ? (
              <Box sx={datePickerLoading}>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            ) : (
              <HorizontalDatePicker
                dates={dates}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            )}
          </Box>
          <Box sx={selectTimeTitle}>
            Select Time {`(${timezoneLocationSetting.id || '...'})`}
          </Box>
          <Box sx={{p: 1}}>
            {dateLoading ? (
              <Box sx={flexCtrCtr}>
                <CustomTextLoad height={54} fullWidth />
              </Box>
            ) : (
              <TextField
                sx={textFieldEmer}
                select
                value={selectedTime}
                onChange={e => setSelectedTime(e.target.value)}
              >
                {timeSlot.map((item: any) => (
                  <MenuItem
                    key={item.time}
                    value={item.time}
                    disabled={!item.available || item.disabled}
                  >
                    {`${item.time} ${
                      !item.available || item.disabled ? '(Not Available)' : ''
                    }`}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
          {typePurpose === 'payout' ? (
            <>
              <Box sx={selectTimeTitle}>Week Ending</Box>
              <Box sx={{p: 1}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <MobileDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={selectedWeekday}
                    onChange={handleSelectedWeekday}
                    renderInput={(params: any) => (
                      <TextField {...params} sx={{width: '100%'}} />
                    )}
                    shouldDisableDate={shouldDisableDate}
                  />
                </LocalizationProvider>
              </Box>
              {selectedWeekday && (
                <>
                  {selectedType.is_shift_linked || locations.length !== 0 ? (
                    <>
                      <Box sx={selectTimeTitle}>
                        Business Location{' '}
                        {selectedType.is_shift_linked ? '' : '(Optional)'}
                      </Box>
                      <Box sx={{p: 1}}>
                        <TextField
                          sx={textFieldEmer}
                          disabled={!loadedLocation}
                          select
                          value={
                            selectedLocation
                              ? selectedLocation.businessLocationString
                              : ''
                          }
                          onChange={e => {
                            const selectedLoc = locations.find(
                              (location: any) =>
                                location.businessLocationString ===
                                e.target.value
                            );
                            setSelectedLocation(selectedLoc);
                          }}
                        >
                          {locations &&
                            locations.map((location: any, index: number) => (
                              <MenuItem
                                key={index}
                                value={location.businessLocationString}
                                style={{
                                  minWidth: '300px',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {location.businessLocationString}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>
                    </>
                  ) : null}

                  <Box sx={selectTimeTitle}>Notes (Optional)</Box>
                  <Box sx={{p: 1}}>
                    <TextField
                      sx={textFieldEmer}
                      value={optionalNotes}
                      onChange={e => setOptionalNotes(e.target.value)}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Box sx={selectTimeTitle}>Reason for booking an appointment</Box>
              <Box sx={{p: 1}}>
                <TextField
                  sx={textFieldEmer}
                  value={reason}
                  onChange={e => setReason(e.target.value)}
                />
              </Box>
            </>
          )}
          <Box sx={{mt: 2, mx: 1}}>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={handleNext}
              disabled={dateLoading || typeLoading}
            >
              Schedule
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
