import {Alert, Snackbar} from '@mui/material';
import {PopUpProps} from '../../../types/types';

export const PopUp = ({
  isOpen,
  onClose,
  message,
  status,
  duration,
}: PopUpProps) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={duration ? duration : 5000}
      onClose={onClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      sx={{width: {xs: '100%', sm: '80%', textAlign: 'center'}}}
    >
      <Alert severity={status} sx={{width: '100%'}} onClose={onClose}>
        {message}
      </Alert>
    </Snackbar>
  );
};
