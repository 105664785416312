/* eslint-disable @typescript-eslint/no-explicit-any */

export const checkRequiredStaffCustomAttributes = (
  staffProfileTenant: any,
  shiftRequiredAttributes: Array<any>
) => {
  const attributesOfStaff = JSON.parse(
    staffProfileTenant?.custom_attribute || '{}'
  );
  let isPass = true;
  const required = [];

  for (let step = 0; step < shiftRequiredAttributes.length; step++) {
    if (!attributesOfStaff[shiftRequiredAttributes[step].id]) {
      required.push(shiftRequiredAttributes[step]);
      isPass = false;
    }
  }

  if (staffProfileTenant === null) isPass = true;

  return {
    isPass,
    required,
  };
};
