export const termsBox = {
  height: '350px',
  overflow: 'scroll',
  border: '1px solid',
  borderColor: 'secondary.main',
  p: 1,
};

export const primaryTitle = {
  fontSize: '1.2rem',
  color: 'secondary.dark',
  fontWeight: 'bold',
};

export const secondaryTitle = {fontSize: '1.1rem', color: 'secondary.dark'};

export const formContainer = {px: 3, width: '100%'};

export const inputBox = {pb: 2};

export const titleBox = {px: 3, pt: {xs: 1, sm: 3}};

export const RegPersonalField = {
  width: '100%',
  '& .MuiInputBase-root': {height: '48px'},
  textTransform: 'capitalize',
  mb: 2,
};

export const errorText = {
  color: 'error.main',
  fontSize: '12px',
  textAlign: 'right',
};

export const statusCardBox = {
  height: {xs: '110px', sm: '130px'},
  border: '1px solid',
  borderColor: 'secondary.main',
  boxShadow: 2,
  borderRadius: '8px',
  p: 2,
  position: 'relative',
  cursor: 'pointer',
};

export const notVerified = {color: 'secondary.main'};

export const verified = {color: 'success.main'};

export const statusTitle = {ml: 2, fontSize: '16px'};

export const certDesc = {ml: 2, fontSize: '14px'};

export const requireContainer = {
  position: 'absolute',
  right: '0px',
  display: 'flex',
  alignItems: 'center',
};

export const requireArrow = {
  fontSize: '50px',
  mr: '-21px',
  color: 'info.main',
};

export const requireBox = {
  backgroundColor: 'info.main',
  color: 'primary.light',
  width: '80px',
  display: 'flex',
  justifyContent: 'center',
};

export const statusDesc = {
  ml: 5,
  mt: 1,
  width: {xs: '200px', sm: '250px'},
  fontSize: {xs: '0.6rem', sm: '0.9rem'},
};

export const finalReviewText = {
  width: '100%',
  ml: 2,
  mb: 2,
  textTransform: 'capitalize',
};

export const editBtn = {
  color: 'info.dark',
  cursor: 'pointer',
  mx: 2,
  mb: 2,
  textTransform: 'capitalize',
};

export const finalFormDivider = {color: 'primary.main', mb: 2};

export const errorTextAlt = {
  color: 'error.main',
  fontSize: '12px',
  marginY: '-15px',
};

export const whiteBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.light',
  color: 'info.main',
  border: '1px solid',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'primary.light',
    backgroundColor: 'info.main',
    borderColor: 'primary.light',
  },
  '&:disabled': {
    backgroundColor: 'secondary.main',
  },
};

export const noCertBox = {
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
};

export const certBox = {
  height: '350px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
  gap: 2,
  px: 1,
  textTransform: 'capitalize',
};

export const certCardBox = {
  backgroundColor: 'primary.light',
  height: {xs: '110px', sm: '130px'},
  border: '1px solid',
  borderColor: 'secondary.main',
  boxShadow: 2,
  borderRadius: '8px',
  p: 2,
  position: 'relative',
};

export const certCardDesc = {ml: 2, mt: 1};

export const certRemove = {
  position: 'absolute',
  right: '25px',
  bottom: '15px',
  color: 'error.main',
  cursor: 'pointer',
};

export const prepareTitle = {
  fontSize: '18px',
};

export const errorSin = {color: 'error.main', fontSize: '12px', mb: '5px'};

export const contractItem = {
  backgroundColor: 'primary.light',
  border: '1px solid',
  borderColor: 'secondary.main',
  boxShadow: 2,
  borderRadius: '8px',
  p: 2,
  position: 'relative',
};
