/* eslint-disable @typescript-eslint/no-explicit-any */
import {fileUpload} from '@digistaff/core';
import {useState} from 'react';
import {app} from '../providers/Accounts';
import Resizer from 'react-image-file-resizer';

export const useFileUpload = () => {
  const [selectedFile, setSelectedFile] = useState<any>({});
  const [fileSelected, setFileSelected] = useState(false);

  const changeHandler = async (event: any) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      setFileSelected(true);
      setSelectedFile(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeFile = (file: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'blob'
      );
    });

  const handleFileUpload = (username: string) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const body = event.target.result;
        const type = selectedFile.type;
        const filename = `${username}.${selectedFile.type.split('/')[1]}`;

        fileUpload(app, body, type, filename)
          .then(result => resolve(result))
          .catch(error => reject(error));
      };

      reader.readAsBinaryString(selectedFile);
    });
  };

  return {
    handleFileUpload,
    selectedFile,
    changeHandler,
    fileSelected,
  };
};
