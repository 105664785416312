export const quizBtn = {
  my: 4,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  minHeight: '3.5rem',
  backgroundColor: 'primary.light',
  color: 'primary.main',
  border: '1px solid',
  borderRadius: '5px',
  borderColor: 'secondary.light',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: 'primary.light',
    color: 'primary.main',
  },
};

export const quizBtnAlt = {
  my: 4,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: 'auto',
  minHeight: '3.5rem',
  backgroundColor: 'info.main',
  color: 'primary.light',
  border: '1px solid',
  borderRadius: '5px',
  borderColor: 'info.main',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  textAlign: 'left',
  '&:hover': {
    backgroundColor: 'info.main',
    color: 'primary.light',
    borderColor: 'info.main',
  },
};

export const alphabetBox = {
  border: '1px solid',
  borderRadius: '5px',
  px: 1,
  mr: 3,
};

export const alphabetBoxAlt = {
  border: '1px solid',
  borderRadius: '5px',
  px: 1,
  mr: 3,
  borderColor: 'primary.light',
  backgroundColor: 'primary.light',
  color: 'info.main',
};

export const questionNo = {
  fontSize: '20px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
};

export const questionTextField = {
  width: '100%',
  '& .MuiInputBase-root': {
    backgroundColor: 'primary.light',
    borderRadius: '10px',
    border: '2px solid',
    borderColor: 'secondary.dark',
    boxShadow: 2,
  },
  '& label.Mui-focused': {
    color: 'primary.light',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'primary.light',
    },
    '&:hover fieldset': {
      borderColor: 'primary.light',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.light',
    },
  },
};

export const infoCardBox = {
  backgroundColor: 'secondary.light',
  mx: 2,
  mt: 3,
  borderRadius: '5px',
  position: 'relative',
};

export const infoCardTop = {
  display: 'flex',
  alignItems: 'center',
  pt: 2,
  pb: 1,
  px: 3,
};

export const infoCardTextBox = {
  fontSize: '20px',
  fontWeight: 'bold',
  width: {xs: '100px', sm: '200px', md: '300px'},
};

export const avatarTraining = {
  position: 'absolute',
  top: {xs: '30px', sm: '16px'},
  right: '20px',
  width: '50px',
  height: '50px',
};

export const infoCardBottom = {
  mx: 3,
  pt: 2,
  pb: 2,
  display: 'flex',
  alignItems: 'center',
};

export const gridContainerTraining = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
};

export const instructBox = {
  mx: 2,
  my: 3,
  p: 1,
  textAlign: 'center',
  backgroundColor: 'primary.light',
  opacity: 0.7,
  borderRadius: '5px',
  border: '1px solid',
  borderColor: 'secondary.main',
};

export const trainingModelBox = {
  backgroundColor: 'secondary.light',
  borderRadius: '5px',
  pt: 1,
  pb: 2,
  cursor: 'pointer',
  position: 'relative',
};

export const modelTitle = {
  fontSize: {xs: '16px', sm: '20px'},
  fontWeight: 'bold',
  width: '70%',
  px: 2,
  wordWrap: 'break-word',
};

export const modelDesc = {
  fontSize: {xs: '12px', sm: '14px'},
  px: 2,
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 4,
  WebkitBoxOrient: 'vertical',
};

export const courseTitle = {
  my: 1,
  fontSize: '20px',
  textAlign: 'center',
  fontWeight: 'bold',
};

export const courseDesc = {
  px: 2,
  color: 'warning.light',
  textAlign: 'center',
  my: 1,
};

export const scoreIcon = {width: '80px', height: '80px', mt: 3};

export const scoreDesc = {fontSize: '20px', mt: 4};

export const scoreTitle = {
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
  mt: 1,
};

export const scoreRadial = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '45vh',
  background:
    'radial-gradient(circle, rgba(205, 232, 251, 0.7) 0%, rgba(252, 243, 249, 0.655) 49.48%, rgba(255, 255, 255, 0) 60%, rgb(255, 255, 255))',
};

export const scoreBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const recommendBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  mt: 1,
};

export const urlBox = {
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  flexDirection: 'column',
};

export const trainingImgBox = {
  height: '350px',
  display: 'flex',
  justifyContent: 'center',
  py: 2,
};

export const correctAnsBox = {
  py: 2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'success.main',
  fontWeight: 'bold',
  fontSize: '1.2rem',
};

export const quizViewContainer = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '0px',
  p: 1,
  overflowY: 'scroll',
  width: '100vw',
  maxWidth: '100%',
};

export const materialLink = {
  my: 1,
  mx: 2,
  textAlign: 'center',
  fontSize: '18px',
  border: '1px solid',
  p: 1,
  borderRadius: '8px',
  boxShadow: 1,
  borderColor: 'secondary.dark',
};

export const linkBox = {
  color: 'info.dark',
  display: 'flex',
  justifyContent: 'center',
  my: 1,
  cursor: 'pointer',
};
