/* eslint-disable @typescript-eslint/no-explicit-any */
import {createContext, useState} from 'react';
import {badgeList, BadgeProps} from './Badge';

export type UserContextType = {
  userProfile: BadgeProps;
  setUserProfile: React.Dispatch<React.SetStateAction<BadgeProps>>;
};

export const UserContext = createContext<UserContextType>({
  userProfile: badgeList,
  setUserProfile: () => null,
});

export const BadgeProvider = ({children}: any) => {
  const [userProfile, setUserProfile] = useState(badgeList);

  return (
    <UserContext.Provider value={{userProfile, setUserProfile}}>
      {children}
    </UserContext.Provider>
  );
};
