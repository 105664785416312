/* eslint-disable @typescript-eslint/no-explicit-any */
import {setTenantKey} from '@digistaff/app';
import {app} from '../Accounts';

export const withDefaultTenant = (func: any) => {
  return async (...args: any) => {
    const current_tenant_key = localStorage.getItem('tenantId');
    setTenantKey(app, 'default');
    try {
      const result = await func(...args);
      setTenantKey(app, current_tenant_key!);
      return result;
    } catch (err) {
      setTenantKey(app, current_tenant_key!);
      throw err;
    }
  };
};

export const withTenant = (tenant_id: string, func: any) => {
  return async (...args: any) => {
    const current_tenant_key = localStorage.getItem('tenantId');
    setTenantKey(app, tenant_id);
    try {
      const result = await func(...args);
      setTenantKey(app, current_tenant_key!);
      return result;
    } catch (err) {
      setTenantKey(app, current_tenant_key!);
      throw err;
    }
  };
};
