import {Box, Button, Divider, Modal} from '@mui/material';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {useState} from 'react';
import {
  accumulationBox,
  pendingBox,
  vacationCenterTitle,
  vacationCxl,
} from '../../../ProfileViewStyles';
import {VacationModalBox} from './VacationModalBox';
import {CustomDatePicker} from '../../../../../../components/common/CustomDatePicker/CustomDatePicker';
import moment, {Moment} from 'moment';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';

export const ContractorVacationView = () => {
  const [modal, setModal] = useState(false);
  const {handlePopUp, popUp, setPopUp, status, message} = usePopUp();
  const [cal1, setCal1] = useState<Moment | null>(null);
  const [cal2, setCal2] = useState<Moment | null>(null);
  const [requested, setRequested] = useState({
    start: '',
    end: '',
    status: '',
  });

  // TODO: cancel request from api
  const handleCancel = () => {
    setRequested({
      start: '',
      end: '',
      status: '',
    });
    handlePopUp('success', 'Your pending vacation has been cancelled');
    handleModal();
  };

  const handleModal = () => setModal(!modal);

  const closePopUp = () => setPopUp(!popUp);

  const handleCal1 = (newValue: Moment | null) => setCal1(newValue);

  const handleCal2 = (newValue: Moment | null) => setCal2(newValue);

  const checkDateEmpty = () => {
    (!cal1 || !cal2) &&
      handlePopUp('error', 'Please fill in start date and end date');
    return;
  };

  const checkDateValid = () => {
    const today = moment();
    if (cal1?.isAfter(today) && cal2?.isAfter(today)) {
      return true;
    }
    return false;
  };

  const handleConfirm = () => {
    setRequested({
      start: moment(cal1).format('DD/MM/YYYY'),
      end: moment(cal2).format('DD/MM/YYYY'),
      status: 'Pending',
    });
    handlePopUp(
      'success',
      'Your request has been submitted. Please check approval status later'
    );
  };

  const handleSubmit = () => {
    checkDateEmpty();
    checkDateValid()
      ? handleConfirm()
      : handlePopUp('error', 'Please fill in correct start date and end date');
  };

  return (
    <>
      {Object.values(requested)[0].length !== 0 && (
        <>
          <Box sx={{px: 2}}>
            <Box sx={accumulationBox}>
              <Box sx={vacationCenterTitle}>
                Vacation Request: {requested.start}
                {' - '}
                {requested.end}
              </Box>
              <Box sx={pendingBox}>Status: {requested.status}</Box>
              <Button
                variant="outlined"
                sx={vacationCxl}
                onPointerUp={handleModal}
              >
                cancel
              </Button>
            </Box>
          </Box>
          <Divider sx={{my: 2}} />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          px: 2,
          my: 3,
        }}
      >
        <CustomDatePicker
          value={cal1}
          handleChange={handleCal1}
          label="Start Date"
        />
        <CustomDatePicker
          value={cal2}
          handleChange={handleCal2}
          label="End Date"
        />
        <Button variant="outlined" sx={continueBtn} onPointerUp={handleSubmit}>
          submit
        </Button>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
      <Modal open={modal} onClose={handleModal}>
        <>
          <VacationModalBox
            handleModal={handleModal}
            handleCancel={handleCancel}
          />
        </>
      </Modal>
    </>
  );
};
