/* eslint-disable @typescript-eslint/no-explicit-any */
import {Clear} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {LocalizationProvider, MobileDatePicker} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

export const DateFilter = ({day, handleDayChange}: any) => {
  const handleClearClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleDayChange(null);
  };

  const currentDate = moment(new Date());
  const maxDate = moment(new Date()).add(7, 'days');

  return (
    <Box sx={{px: 3, pt: 2, height: '120px'}}>
      <Box sx={{fontWeight: 'bold', mb: 1, color: '#666666'}}>
        Choose Shift Date
      </Box>
      <Box sx={{display: 'flex'}}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileDatePicker
            inputFormat="DD/MM/YYYY"
            value={day}
            onChange={handleDayChange}
            minDate={currentDate}
            maxDate={maxDate}
            renderInput={params => (
              <TextField
                {...params}
                sx={{width: '100%'}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {day && (
                        <IconButton onClick={handleClearClick}>
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Divider sx={{mt: 2}} />
    </Box>
  );
};
