/* eslint-disable @typescript-eslint/no-explicit-any */
import {PhotoCamera} from '@mui/icons-material';
import {Avatar, Box, Button} from '@mui/material';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {BottomBar} from '../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {useFileUpload} from '../../../../hooks/useFileUpload';
import {usePopUp} from '../../../../hooks/usePopUp';
import {updateStaffProfileWithPayload} from '../../../../providers/api/profile';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {bottomSubPageLimit, flexCtrCtr, photoIcon} from '../ProfileViewStyles';

export const ProfilePicView = () => {
  const [imageUrl, setImageUrl] = useState('');
  const {state} = useLocation();
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const navigate = useNavigate();

  useEffect(() => {
    state.avatar && setImageUrl(state.avatar);
  }, [state.avatar]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();
    file && reader.readAsDataURL(file);
    reader.onloadend = () => setImageUrl(reader.result as string);
    changeHandler(event).catch(err => {
      console.log(err);
      console.log(err);
    });
  };

  const updateAvatar = async () => {
    const staffId = state.id;

    try {
      const uploadedFile: any = await handleFileUpload(staffId);
      const staffAvatar = uploadedFile?.data?.result?.Location;
      const payload = {
        avatar: staffAvatar,
      };
      await updateStaffProfileWithPayload(staffId, payload);
      handlePopUp('success', 'Your profile picture has been updated');
      localStorage.removeItem('lastFetch');
      setTimeout(() => navigate('/user'), 1000);
    } catch (err) {
      console.log(err);
      console.log(err);
      handlePopUp('error', 'Unable to update profile');
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Update Profile Picture">
          <Box sx={{m: 5}}>
            <Box sx={flexCtrCtr}>
              <Avatar sx={{width: '300px', height: '300px'}} src={imageUrl} />
            </Box>
          </Box>
          <Box sx={{m: 2}}>
            <Button variant="outlined" sx={continueBtn} component="label">
              <PhotoCamera sx={photoIcon} />
              Select
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={handleChange}
              />
            </Button>
          </Box>
          <Box sx={{m: 2}}>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={updateAvatar}
              disabled={!fileSelected}
            >
              Upload
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
