/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {getSinDocument} from '../../../../providers/api/documents';
import {Box, Button} from '@mui/material';
import {
  flexCtrCtr,
  roundBtn,
  roundBtnSelected,
} from '../../../Private/ProfileView/ProfileViewStyles';
import {SinTabOne} from '../../../Private/ProfileView/ProfileSubPage/Documents/DocumentsSubSections/SinTabOne';
import {SinTabTwo} from '../../../Private/ProfileView/ProfileSubPage/Documents/DocumentsSubSections/SinTabTwo';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {userScrollLimit} from '../../LandingView/LandingViewStyles';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';

export const SinDocRegView = () => {
  const [sinTab, setSinTab] = useState(-1);
  const [sinPhoto, setSinPhoto] = useState('');
  const [sinNo, setSinNo] = useState('');
  const [sinExpiry, setSinExpiry] = useState('');
  const [retrievedDocType, setRetrievedDocType] = useState('');
  const location = useLocation();
  const sinDocId = location.state.param1;
  const [sinReg, setSinReg] = useState(false);
  const [sinStatus, setSinStatus] = useState('');

  useEffect(() => {
    getData().catch(err => {
      console.log(err);
      console.log(err);
    });
  }, []);

  const getData = async () => {
    if (sinDocId) {
      const docRetrieved: any = await getSinDocument(sinDocId);
      if (docRetrieved) {
        if (docRetrieved.doc_type === 'sin') {
          setSinTab(0);
        } else if (docRetrieved.doc_type === 'cra') {
          setSinTab(1);
        }
        setRetrievedDocType(docRetrieved.doc_type);
        setSinNo(docRetrieved.doc_no);
        setSinPhoto(docRetrieved._photo);
        setSinExpiry(docRetrieved.expiry_date);
      }
    }
  };

  const switchTab = (idx: string) => {
    if (idx === '0') {
      setSinTab(0);
    } else {
      setSinTab(1);
    }
  };

  return (
    <>
      <Navbar cancelBtn backBtn signUp page="/info/id" />
      <Box sx={userScrollLimit}>
        <PageWrapper title="Upload SIN">
          <Box sx={{p: 2}}>
            <Box sx={flexCtrCtr}>
              <Box sx={{display: 'flex', justifyContent: 'center', gap: 3}}>
                <Button
                  id="sin-btn"
                  variant="outlined"
                  sx={sinTab === 0 ? roundBtnSelected : roundBtn}
                  onPointerUp={() => switchTab('0')}
                >
                  I have a valid SIN
                </Button>
                <Button
                  id="cra-btn"
                  variant="outlined"
                  sx={sinTab === 1 ? roundBtnSelected : roundBtn}
                  onPointerUp={() => switchTab('1')}
                >
                  I do NOT have a SIN, but I have registered for one
                </Button>
              </Box>
            </Box>
            {sinTab === 0 && (
              <SinTabOne
                setSinReg={setSinReg}
                sinReg={sinReg}
                registration
                setSinStatus={setSinStatus}
                sinNo={sinNo}
                sinExpiry={sinExpiry}
                sinPhoto={sinPhoto}
                sinDocId={sinDocId}
                retrievedDocType={retrievedDocType}
              />
            )}
            {sinTab === 1 && (
              <SinTabTwo
                setSinReg={setSinReg}
                registration
                setSinStatus={setSinStatus}
                sinPhoto={sinPhoto}
                sinDocId={sinDocId}
                retrievedDocType={retrievedDocType}
              />
            )}
          </Box>
        </PageWrapper>
      </Box>
    </>
  );
};
