/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

export const handleCustomError: (error: any) => void = error => {
  console.log(error);
};

const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return (
      <>
        <h2>
          {error.status} {error.statusText}{' '}
          {error.data?.message && error.data.message}{' '}
        </h2>
        Your error has been received, we will fix the error as soon as possible
      </>
    );
  } else {
    return <div>Oops</div>;
  }
};

const RouteErrorView = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5rem',
        width: '100vw',
      }}
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <ErrorBoundary />
      <Button
        variant="contained"
        sx={{fontWeight: 'fontWeightMedium', mt: '3rem'}}
        onPointerUp={() => navigate(-1)}
      >
        Go back to last page
      </Button>
    </Box>
  );
};

export default RouteErrorView;
