/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, TextField} from '@mui/material';
import {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Navbar} from '../../../../components/common/NavBar/NavBar';
import {PageWrapper} from '../../../../components/common/PageWrapper/PageWrapper';
import {PopUp} from '../../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../../hooks/usePopUp';
import {
  closeMandatoryTrainingSession,
  closeTrainingSession,
  closeTrainingSessionWithTenant,
  fetchMandatoryTrainingQuestions,
  fetchTrainingQuestions,
  fetchTrainingQuestionsWithTenant,
  uploadImageFile,
  uploadImageFileWithTenant,
  uploadMandatoryImageFile,
} from '../../../../providers/api/training';
import {continueBtn} from '../../../Public/LandingView/LandingViewStyles';
import {
  correctAnsBox,
  questionTextField,
  quizViewContainer,
} from './TrainingCenterStyles';
import {SignatureBoard} from '../../../../components/common/SignatureBoard/SignatureBoard';
import ReactSignatureCanvas from 'react-signature-canvas';
import {Bridge} from '../../../../Bridge';
import {useCurrentUser} from '../../../../hooks/useCurrentUser';

import {ChoiceButtons, QuestionDisplay} from './QuizComponents/QuizComponents';

export const QuizView = () => {
  const {state} = useLocation();
  const [course, setCourse] = useState<any>([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState<any[]>([]);
  const [shortQuestionAnswer, setShortQuestionAnswer] = useState('');
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const [tries, setTries] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [questionData, setQuestionData] = useState<any[]>([]);
  const {id} = useParams();
  const navigate = useNavigate();
  const [showSignatureBoard, setShowSignatureBoard] = useState(false);
  const sigPadRef = useRef<ReactSignatureCanvas | null>(null);
  const staffId = localStorage.getItem('email');
  const [loadingSignature, setLoadingSignature] = useState(false);
  const [firstStartTime, setFirstStartTime] = useState('');
  const courseId = state?.param1;
  const isMandatory = state?.param2;
  const hasTenantID = state?.param3;
  const [openEndAnswer, setOpenEndAnswer] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const {userValid, sessionInvalidRedirection, inAppErrMsg} = useCurrentUser();

  useEffect(() => {
    const loadTrainingQuestions = () => {
      if (isMandatory !== undefined && isMandatory) {
        fetchMandatoryTrainingQuestions(courseId as string)
          .then(res => setCourse(res))
          .catch(err => {
            console.log(err);
            console.log(err);
          });
      } else if (hasTenantID !== undefined) {
        fetchTrainingQuestionsWithTenant(hasTenantID)(courseId as string)
          .then(res => setCourse(res))
          .catch(err => {
            console.log(err);
            console.log(err);
          });
      } else {
        fetchTrainingQuestions(courseId as string)
          .then(res => setCourse(res))
          .catch(err => {
            console.log(err);
            console.log(err);
          });
      }
    };
    loadTrainingQuestions();
    setFirstStartTime(new Date().toISOString());
  }, [courseId, isMandatory, hasTenantID]);

  useEffect(() => {
    if (course.length > 0) {
      const initialQuestionData = course.map((question: any) => ({
        question_id: question.id,
        start: '',
        end: '',
        answer: [],
        type: question.type,
      }));
      setQuestionData(initialQuestionData);
    }
  }, [course]);

  const updateStartTime = (currentQuestion: number, startTime: string) => {
    const currentData = questionData[currentQuestion] || {};

    if (!currentData.start) {
      currentData.start = startTime;

      setQuestionData([
        ...questionData.slice(0, currentQuestion),
        currentData,
        ...questionData.slice(currentQuestion + 1),
      ]);
    }
  };

  const getBase64Data = () => {
    if (sigPadRef.current) {
      const imageData =
        sigPadRef.current &&
        sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');
      return imageData.substring(imageData.indexOf(',') + 1);
    } else {
      return;
    }
  };

  const uploadImage = async (base64Data: any, imageId: string) => {
    if (isMandatory) {
      return uploadMandatoryImageFile(base64Data, imageId);
    } else if (hasTenantID !== undefined) {
      return uploadImageFileWithTenant(hasTenantID)(base64Data, imageId);
    } else {
      return uploadImageFile(base64Data, imageId);
    }
  };

  const closeSession = (
    imageId: string,
    questionData: any,
    uploadedImage: string,
    firstStartTime: string
  ) => {
    if (isMandatory) {
      return closeMandatoryTrainingSession(
        imageId,
        questionData,
        uploadedImage,
        firstStartTime
      );
    } else if (hasTenantID !== undefined) {
      return closeTrainingSessionWithTenant(hasTenantID)(
        imageId,
        questionData,
        uploadedImage,
        firstStartTime
      );
    } else {
      return closeTrainingSession(
        imageId,
        questionData,
        uploadedImage,
        firstStartTime
      );
    }
  };

  const confirmSignature = async () => {
    if (sigPadRef.current && !sigPadRef.current.isEmpty()) {
      setLoadingSignature(true);

      const base64Data = getBase64Data();
      const imageId = `${staffId}_${id}`;

      try {
        const userStatus = await userValid();
        if (!userStatus) {
          handlePopUp('error', inAppErrMsg);
          sessionInvalidRedirection();
          return;
        }

        const uploadedImage = await uploadImage(base64Data, imageId);
        const res = await closeSession(
          id as string,
          questionData,
          uploadedImage,
          firstStartTime
        );
        navigate('/training/quiz/score', {state: {param1: res}});
      } catch (err) {
        console.log(err);
        console.log(err);
      }
    } else {
      handlePopUp('error', 'Please sign before submitting the quiz.');
    }
  };

  const openLink = async (url: string) => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = `https://${url}`;
    }

    await Bridge.openURL(url);
  };

  const handleClick = (answer: any) => {
    const startTime = new Date().toISOString();

    if (currentQuestion > 0) {
      updateStartTime(currentQuestion - 1, startTime);
    }
    updateStartTime(currentQuestion, startTime);

    const updatedAnswers = [...answers];
    const currentCorrectAnswers = course[currentQuestion].correctAnswer;

    if (updatedAnswers[currentQuestion]?.includes(answer)) {
      updatedAnswers[currentQuestion] = updatedAnswers[currentQuestion].filter(
        (a: any) => a !== answer
      );
    } else {
      if (
        updatedAnswers[currentQuestion]?.length >= currentCorrectAnswers.length
      ) {
        updatedAnswers[currentQuestion].shift();
      }

      updatedAnswers[currentQuestion] = [
        ...(updatedAnswers[currentQuestion] || []),
        answer,
      ];
    }
    setAnswers(updatedAnswers);
  };

  const handleClear = () => sigPadRef?.current?.clear();

  const checkAnswer = (userAnswer: any): boolean => {
    if (isEmptyAnswer(userAnswer)) {
      handleEmptyAnswer();
      return false;
    }

    const isCorrect = isAnswerCorrect(userAnswer);
    handleAnswerFeedback(isCorrect);

    updateQuestionData(userAnswer);

    if (isCorrect) {
      handleCorrectAnswer();
    }

    return isCorrect;
  };

  const isEmptyAnswer = (answer: any): boolean => {
    return !Array.isArray(answer) && answer.trim() === '';
  };

  const handleEmptyAnswer = () => {
    handlePopUp('error', 'Please answer the question');
    setSubmitting(false);
  };

  const isAnswerCorrect = (userAnswer: any): boolean => {
    if (Array.isArray(userAnswer)) {
      return (
        userAnswer.sort().toString() ===
        [...course[currentQuestion].correctAnswer].sort().toString()
      );
    }

    return (
      course[currentQuestion].correctAnswer[0].trim().toLowerCase() ===
      userAnswer.trim().toLowerCase()
    );
  };

  const handleAnswerFeedback = (isCorrect: boolean) => {
    if (isCorrect) {
      handlePopUp('success', 'Correct!');
    } else {
      setTries(tries + 1);
      handlePopUp('error', 'Incorrect answer. Please try again.');
      setSubmitting(false);
    }
  };

  const updateQuestionData = (userAnswer: any) => {
    const endTime = new Date().toISOString();
    const currentData = questionData[currentQuestion] || {};

    if (!currentData.question_id) {
      currentData.question_id = course[currentQuestion].id;
    }

    currentData.end = endTime;

    const answerType = course[currentQuestion].type;
    if (answerType === 'text' || answerType === 'number') {
      currentData.answer.push([userAnswer.toString()]);
    } else {
      currentData.answer.push([...userAnswer]);
    }

    setQuestionData([
      ...questionData.slice(0, currentQuestion),
      currentData,
      ...questionData.slice(currentQuestion + 1),
    ]);
  };

  const handleCorrectAnswer = () => {
    setTimeout(() => {
      if (currentQuestion + 1 < course.length) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowSignatureBoard(true);
      }
      resetStateAfterCorrectAnswer();
    }, 1500);
  };

  const resetStateAfterCorrectAnswer = () => {
    setTries(0);
    setShortQuestionAnswer('');
    closePopUp();
    setSubmitting(false);
  };

  const updateQuestionOrShowSignature = () => {
    if (currentQuestion + 1 < course.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowSignatureBoard(true);
    }
  };

  const resetStateForNextQuestion = () => {
    setTries(0);
    setShortQuestionAnswer('');
    setOpenEndAnswer('');
    setShowCorrectAnswer(false);
    setSubmitting(false);
  };

  const handleTextAnyType = () => {
    if (openEndAnswer.trim().length >= 1) {
      recordTextAnswer();

      handlePopUp('success', 'Answer recorded');
      setTimeout(() => {
        updateQuestionOrShowSignature();
        resetStateForNextQuestion();
        closePopUp();
      }, 1500);
    } else {
      handlePopUp('error', 'Please answer the question');
      setSubmitting(false);
    }
  };

  const recordTextAnswer = () => {
    const endTime = new Date().toISOString();
    const currentData = questionData[currentQuestion] || {};

    if (!currentData.question_id) {
      currentData.question_id = course[currentQuestion].id;
    }

    currentData.end = endTime;
    currentData.answer.push(openEndAnswer.trim());

    setQuestionData([
      ...questionData.slice(0, currentQuestion),
      currentData,
      ...questionData.slice(currentQuestion + 1),
    ]);
  };

  const handleOtherTypes = () => {
    const userAnswer = getUserAnswer();

    if (!checkAnswer(userAnswer)) {
      if (tries >= 1) {
        handlePopUp('error', 'Incorrect answer.');
        setShowCorrectAnswer(true);
      }
    } else {
      setShowCorrectAnswer(false);
    }
  };

  const getUserAnswer = () => {
    if (course[currentQuestion].type === 'multiple') {
      return answers[currentQuestion];
    }
    return shortQuestionAnswer.trim();
  };

  const handleSubmit = () => {
    setSubmitting(true);
    if (showCorrectAnswer) {
      updateQuestionOrShowSignature();
      resetStateForNextQuestion();
      return;
    }

    if (course[currentQuestion].type === 'text_any') {
      handleTextAnyType();
    } else {
      handleOtherTypes();
    }
  };

  return (
    <>
      <Navbar backBtn login />
      <Box sx={quizViewContainer}>
        <PageWrapper title="Quiz">
          {course.length > 0 && (
            <Box key={course[currentQuestion].id}>
              <QuestionDisplay
                course={course}
                currentQuestion={currentQuestion}
                openLink={openLink}
              />
              {course[currentQuestion].type === 'multiple' && (
                <>
                  {course[currentQuestion]?.choices?.map(
                    (choice: any, index: number) => (
                      <ChoiceButtons
                        key={index}
                        answers={answers}
                        currentQuestion={currentQuestion}
                        choice={choice}
                        index={index}
                        handleClick={handleClick}
                      />
                    )
                  )}
                </>
              )}
              {course[currentQuestion].type === 'number' && (
                <Box sx={{py: 2}}>
                  <TextField
                    placeholder="Please answer here"
                    variant="outlined"
                    type="number"
                    sx={questionTextField}
                    value={shortQuestionAnswer}
                    onChange={e => setShortQuestionAnswer(e.target.value)}
                    inputProps={{
                      pattern: '\\d*',
                    }}
                  />
                </Box>
              )}
              {course[currentQuestion].type === 'text' && (
                <Box sx={{py: 2}}>
                  <TextField
                    multiline
                    placeholder="Please answer here"
                    variant="outlined"
                    rows={10}
                    sx={questionTextField}
                    value={shortQuestionAnswer}
                    onChange={e => setShortQuestionAnswer(e.target.value)}
                  />
                </Box>
              )}
              {course[currentQuestion].type === 'text_any' && (
                <Box sx={{py: 2}}>
                  <TextField
                    multiline
                    placeholder="Please answer here"
                    variant="outlined"
                    rows={10}
                    sx={questionTextField}
                    value={openEndAnswer}
                    onChange={e => setOpenEndAnswer(e.target.value)}
                  />
                </Box>
              )}
              <Button
                variant="outlined"
                disabled={
                  submitting ||
                  (course[currentQuestion].type === 'multiple'
                    ? !(
                        answers[currentQuestion] &&
                        answers[currentQuestion].length > 0
                      )
                    : course[currentQuestion].type === 'text' ||
                      course[currentQuestion].type === 'number'
                    ? shortQuestionAnswer === ''
                    : openEndAnswer === '')
                }
                onClick={handleSubmit}
                sx={continueBtn}
              >
                {showCorrectAnswer ? 'Next' : 'Submit'}
              </Button>
              {showCorrectAnswer && (
                <Box sx={correctAnsBox}>
                  <Box>Correct Answer:</Box>
                  {course[currentQuestion].type === 'multiple' ? (
                    course[currentQuestion].choices.map(
                      (choice: any, index: number) => {
                        if (
                          course[currentQuestion].correctAnswer.includes(
                            choice.id
                          )
                        ) {
                          return (
                            <Box key={index}>{`${String.fromCharCode(
                              65 + index
                            )}: ${choice.value}`}</Box>
                          );
                        }
                        return null;
                      }
                    )
                  ) : (
                    <Box>
                      {course[currentQuestion].correctAnswer.join(', ')}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </PageWrapper>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
      <SignatureBoard
        sigPadRef={sigPadRef}
        onConfirm={confirmSignature}
        open={showSignatureBoard}
        handleClear={handleClear}
        loadingSignature={loadingSignature}
      />
    </>
  );
};
