export const bottomSubPageLimit = {
  position: 'absolute',
  top: {xs: '58px', sm: '64px'},
  bottom: '56px',
  p: 1,
  overflowY: 'scroll',
  width: '100vw',
  maxWidth: '100%',
};

export const listContainer = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  cursor: 'pointer',
  height: '50px',
};

export const divider = {width: '100%', pb: '4px', mb: '8px'};

export const titleBox = {fontSize: '18px', fontWeight: 'bold', pl: '20px'};

export const titleBoxDisabled = {
  fontSize: '18px',
  fontWeight: 'bold',
  pl: '20px',
  color: 'secondary.dark',
};

export const arrowRight = {position: 'absolute', right: '10px'};

export const largeCenterTitle = {
  fontSize: '30px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
};

export const titleContainer = {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  p: {xs: 1, sm: 2},
};

export const selectTimeTitle = {
  ml: 1,
  mt: 1,
  fontSize: '1.2rem',
  fontWeight: 'bold',
};

export const userId = {
  pt: '10px',
  fontSize: '30px',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
};

export const tableHeader = {fontSize: '1.1rem'};

export const contentBgLayout = {
  width: '100%',
  px: 1,
  pt: 2,
  pb: 3,
  backgroundColor: 'secondary.light',
  borderRadius: '5px',
};

export const reportTabBtns = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'secondary.main',
  color: 'primary.main',
  border: 'none',
  boxShadow: 2,
  fontSize: '1rem !important',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'primary.light',
  },
};

export const confReportTabBtns = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.main',
  color: 'primary.light',
  border: 'none',
  boxShadow: 2,
  fontSize: '1rem !important',
  '&:hover': {
    backgroundColor: 'primary.main',
    color: 'primary.light',
  },
};

export const btnContainer = {
  width: '30%',
};

export const btnContainerAlt = {
  width: '30%',
  mx: 2,
};

export const subTitle = {fontSize: '1rem', py: '5px'};

export const issueFilterList = {
  position: 'absolute',
  backgroundColor: 'secondary.light',
  width: '90%',
  height: '200px',
  zIndex: '99',
  overflow: 'auto',
};

export const payoutDetailText = {fontSize: '1.1rem', my: 3};

export const textFieldEmer = {
  width: '100%',
  '& .MuiInputBase-root': {height: '48px'},
  textTransform: 'capitalize',
};

export const headerLabel = {fontSize: '1rem', fontWeight: 'bold', pb: 1};

export const gridTextBox = {p: 1};

export const headerAltLabel = {fontSize: '1rem', fontWeight: 'bold', py: 1};

export const photoIcon = {mr: 1, fontSize: '18px'};

export const docuCardTextBox = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
};

export const docuCardBox = {
  backgroundColor: 'primary.light',
  mx: 2,
  mt: 1,
  borderRadius: '5px',
};

export const docuQualiBox = {
  mb: 2,
  minHeight: '180px',
  border: '1px solid',
  borderRadius: '8px',
  position: 'relative',
};

export const docuQualiTitle = {
  pt: 2,
  pb: 1,
  fontSize: '20px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
};

export const userInfoCardBox = {
  backgroundColor: 'secondary.light',
  m: 1,
  borderRadius: '5px',
  position: 'relative',
  cursor: 'pointer',
};

export const avatarHeader = {
  display: 'flex',
  alignItems: 'center',
  pt: 2,
  pb: 1,
  px: 3,
};

export const qualiJobTitle = {fontSize: '16px', py: 1, fontWeight: 'bold'};

export const qualiTextField = {width: '100%'};

export const drawerBox = {
  '& .MuiPaper-root': {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
};

export const drawerInnerBox = {
  width: 'auto',
  px: 3,
  pt: 1,
  maxHeight: '80vh',
  overflowY: 'scroll',
};

export const drawerTitleContainer = {
  display: 'flex',
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 999,
  pb: 1,
  backgroundColor: 'primary.light',
};

export const drawerClose = {
  position: 'absolute',
  right: '-3px',
  top: '7px',
  cursor: 'pointer',
};

export const qualiBtnContainer = {display: 'flex', gap: 3, mt: 1};

export const editQualiInput = {
  width: '90%',
  '& .MuiInputBase-root': {height: '25px'},
};

export const innerGridContainer = {display: 'flex', my: 1};

export const innerGridTitle = {display: 'flex', justifyContent: 'center'};

export const qualiInstruct = {
  mx: 2,
  color: 'primary.main',
  textAlign: 'center',
};

export const flexCtrCtr = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const flexAlign = {display: 'flex', alignItems: 'center'};

export const addGrid = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'info.main',
  color: 'primary.light',
  borderTopRightRadius: '7px',
  borderBottomRightRadius: '7px',
  cursor: 'pointer',
};

export const gridTextRight = {
  textTransform: 'capitalize',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
};

export const allExp = {
  position: 'absolute',
  bottom: '5px',
  right: '70px',
  color: 'secondary.main',
  display: 'flex',
  cursor: 'pointer',
};

export const selectAppointmentTitle = {
  ml: 1,
  fontSize: '1.2rem',
  fontWeight: 'bold',
};

export const tickText = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  pb: 1,
  fontSize: {xs: '14px', sm: '16px'},
  textTransform: 'capitalize',
};

export const docuTickBlank = {
  position: 'absolute',
  right: '5px',
  fontSize: {xs: '20px', sm: '24px'},
};

export const docuTickGreen = {
  position: 'absolute',
  right: '5px',
  color: 'success.main',
  fontSize: {xs: '20px', sm: '24px'},
};

export const docuTickYellow = {
  position: 'absolute',
  right: '5px',
  color: 'warning.light',
  fontSize: {xs: '20px', sm: '24px'},
};

export const docuTickOrange = {
  position: 'absolute',
  right: '5px',
  color: 'warning.dark',
  fontSize: {xs: '20px', sm: '24px'},
};

export const docuTickRed = {
  position: 'absolute',
  right: '5px',
  color: 'error.main',
  fontSize: {xs: '20px', sm: '24px'},
};

export const docuMandateTitle = {
  fontSize: {xs: '20px', sm: '24px'},
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  pb: 1,
};

export const docuDescBox = {pt: 1};

export const docuArrowRight = {position: 'absolute', right: '5px'};

export const docuSectionBox = {m: 2, cursor: 'pointer'};

export const expiryBox = {mt: 1, textTransform: 'capitalize'};

export const firstDesc = {color: 'secondary.dark'};

export const descReturn = {fontWeight: 'bold', textAlign: 'center'};

export const roundBtn = {
  height: {xs: '130px', sm: '150px'},
  width: {xs: '130px', sm: '150px'},
  borderRadius: '8px',
};

export const roundBtnSelected = {
  height: {xs: '130px', sm: '150px'},
  width: {xs: '130px', sm: '150px'},
  borderRadius: '8px',
  backgroundColor: 'info.main',
  borderColor: 'info.main',
  color: 'primary.light',
  '&:hover': {
    backgroundColor: 'info.main',
    color: 'primary.light',
  },
};

export const tabOneBtnBox = {py: 1};
export const discardBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.light',
  color: 'warning.main',
  border: '1px solid',
  borderColor: 'warning.main',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'warning.main',
    borderColor: 'warning.main',
  },
};

export const saveBtn = {
  width: '100%',
  height: '3rem',
  backgroundColor: 'primary.light',
  color: 'success.main',
  border: '1px solid',
  borderColor: 'success.main',
  boxShadow: 3,
  fontSize: '1rem !important',
  fontWeight: 'bold',
  '&:hover': {
    color: 'success.main',
    borderColor: 'success.main',
  },
};

export const inputTimeProps = {
  px: '2px',
  '&::-webkit-calendar-picker-indicator': {
    display: 'none',
    background: 'none',
    webkitAppearance: 'none',
  },
};

export const cPTableCell = {
  width: {xs: '70px'},
  paddingY: {xs: 0, sm: '16px'},
  paddingRight: {xs: 0, sm: '3px'},
  paddingLeft: {xs: 0, sm: '6px'},
};

export const dayLabel = {
  width: '30px',
  paddingY: {xs: 0, sm: '16px'},
  paddingX: {xs: 0, sm: '1px'},
};

export const vacationCenterBox = {
  backgroundColor: 'secondary.light',
  borderRadius: '8px',
  cursor: 'pointer',
  height: '150px',
  position: 'relative',
};

export const vacationCenterTitle = {
  fontSize: '20px',
  fontWeight: 'bold',
  m: 2,
  pt: 2,
};

export const vacationBoxTitle = {
  fontSize: '20px',
  fontWeight: 'bold',
  mx: 2,
  pt: 2,
};

export const vacationCenterClick = {
  position: 'absolute',
  right: '5px',
  bottom: '10px',
  display: 'flex',
  alignItems: 'center',
};

export const vacationCenterForward = {fontSize: '16px', ml: 1};

export const accumulationBox = {
  boxShadow: 2,
  borderRadius: '8px',
  cursor: 'pointer',
  height: '150px',
  position: 'relative',
};

export const vacationSinceBox = {
  position: 'absolute',
  right: '15px',
  bottom: '15px',
  fontSize: '16px',
};

export const vacaInstruBox = {
  mb: 2,
  backgroundColor: 'secondary.light',
  p: 2,
  borderRadius: 2,
};

export const pendingBox = {ml: 2, fontSize: '16px'};

export const vacationCxl = {
  position: 'absolute',
  right: '15px',
  bottom: '15px',
  backgroundColor: 'primary.light',
  color: 'warning.main',
  border: '1px solid',
  borderColor: 'warning.main',
  '&:hover': {
    color: 'warning.main',
    borderColor: 'warning.main',
  },
};

export const appointmentTitle = {
  fontWeight: 'bold',
  textTransform: 'capitalize',
  fontSize: '20px',
};

export const starIcon = {
  cursor: 'pointer',
  position: 'absolute',
  top: '10px',
  left: '5px',
  fontSize: '28px',
};

export const contactDisabled = {
  width: '350px',
  mr: '20px',
  ml: '10px',
  fontSize: '12px',
  color: 'secondary.main',
};

export const expiryText = {
  color: 'warning.dark',
  fontSize: '12px',
  ml: 1,
  width: {xs: '80px', sm: '150px'},
};

export const expiredText = {
  color: 'error.dark',
  fontSize: '12px',
  ml: 1,
  width: {xs: '80px', sm: '150px'},
};

export const uploadDocumentCardBox = {
  border: '1px solid',
  borderColor: 'secondary.main',
  boxShadow: 2,
  borderRadius: '8px',
  p: 2,
  position: 'relative',
  cursor: 'pointer',
};

export const uploadDocumentCardContainer = {
  px: 1,
  mt: 2,
  mb: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export const docuDetailsBox = {
  mt: 1,
  ml: 5,
  width: {xs: '200px', sm: '250px'},
  fontSize: {xs: '0.6rem', sm: '0.9rem'},
};

export const firstLiner = {
  textTransform: 'capitalize',
};

export const editPenBox = {
  position: 'absolute',
  bottom: '0px',
  right: '5px',
  height: '20px',
  width: '20px',
  backgroundColor: 'secondary.main',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const transparentBox = {
  position: 'absolute',
  right: '0px',
  top: '0px',
  height: '175px',
  width: '35px',
};

export const certLoadingBox = {
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const textFieldAltCapitalized = {
  width: '100%',
  '& .MuiInputBase-root': {
    height: '48px',
    '& .MuiInputBase-input': {
      textTransform: 'capitalize',
    },
  },
};

export const vacationDateBox = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
};

export const vacationDateDesc = {fontSie: '10px', color: 'secondary.dark'};

export const versionNo = {
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
  color: 'secondary.main',
};

export const datePickerLoading = {
  width: '100%',
  p: 1,
  display: 'flex',
  justifyContent: 'space-around',
};

export const circularBox = {
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
};

export const chequeEmptyBox = {
  height: '60vh',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

export const emailBox = {
  width: '100%',
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
