/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {CustomSlider} from '../../../components/common/Animation/CustomSlider';
import {Navbar} from '../../../components/common/NavBar/NavBar';
import {PasswordInput} from '../../../components/common/PasswordInput/PasswordInput';
import {PopUp} from '../../../components/common/PopUp/PopUp';
import {usePopUp} from '../../../hooks/usePopUp';
import {
  accTextBox,
  continueBtn,
  createAccContainer,
  emailInput,
  forgotPwBox,
  forgotPwTxt,
  signUpBox,
  userScrollLimit,
} from '../LandingView/LandingViewStyles';
import {
  formContainer,
  inputBox,
  primaryTitle,
  secondaryTitle,
} from '../Registration/RegistrationStyles';
import {app, signInWithEmailAndPassword} from '../../../providers/Accounts';
import {checkOnboardingStatus} from '../../../providers/api/profile';
import {pageGuards} from '../../../routes/guards/PublicRoute';

export const LoginView = () => {
  const [passwordValues, setPasswordValues] = useState({
    password: '',
    showPassword: false,
  });
  const navigate = useNavigate();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const email = localStorage.getItem('email');
  // onboarding status will set to 10 after completed final review in registration
  const fullyOnboard = 10;
  const pwRequiredLength = 8;

  const handleChange =
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
      setPasswordValues({...passwordValues, [prop]: event.target.value});

  const redirectUser = async () => {
    try {
      const onboardingStatus = await checkOnboardingStatus();
      // Redirect user to last completed registration stage if not yet fully registered
      if (onboardingStatus < fullyOnboard) {
        const redirectToPage = pageGuards.find(
          (p: any) => p.page === onboardingStatus.toString()
        );

        navigate(`${redirectToPage.url}`);
      } else {
        navigate('/agency/select');
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const login = async () => {
    const {password} = passwordValues;

    if (!email) {
      handlePopUp(
        'error',
        'Missing email address. Redirecting you to input email address'
      );
      setTimeout(() => {
        navigate('/');
      }, 1000);
      return;
    }

    if (password.length < pwRequiredLength) {
      handlePopUp('error', 'Password incorrect');
      return;
    }

    try {
      await signInWithEmailAndPassword(app, email, password);
      await redirectUser();
    } catch (error: any) {
      if (
        error?.err?.name === 'UserNotFoundException' ||
        error?.err?.name === 'NotAuthorizedException' ||
        error.code === 'DS_USER_INVALID_CREDS'
      ) {
        handlePopUp('error', 'Username or password incorrect!');
      } else {
        console.log(error);
        console.log(error);
        handlePopUp('error', 'Unable to login, please contact admin');
      }
    }
  };

  const handleKeyDown = async (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await login();
    }
  };

  const forgotPw = () => navigate('/password-recovery');

  return (
    <>
      <Navbar backBtn login />
      <Box sx={userScrollLimit}>
        <Box sx={signUpBox}>
          <Box sx={createAccContainer}>
            <Box sx={accTextBox}>Login</Box>
          </Box>
          <Box sx={{p: 3}}>
            <CustomSlider direction="right" fadeIn={200}>
              <Box sx={primaryTitle}>Hi!</Box>
            </CustomSlider>
            <CustomSlider direction="right" fadeIn={300}>
              <Box sx={secondaryTitle}>
                Login with your Password to continue
              </Box>
            </CustomSlider>
          </Box>
          <Box sx={formContainer}>
            <CustomSlider direction="right" fadeIn={350}>
              <Box sx={inputBox}>
                <PasswordInput
                  id="password-field"
                  autoFocus
                  type={passwordValues.showPassword}
                  style={emailInput}
                  name="password"
                  onChange={handleChange('password')}
                  value={passwordValues.password}
                  onKeyDown={handleKeyDown}
                  onClickIcon={() =>
                    setPasswordValues({
                      ...passwordValues,
                      showPassword: !passwordValues.showPassword,
                    })
                  }
                />
              </Box>
            </CustomSlider>
            <CustomSlider direction="right" fadeIn={400}>
              <Box sx={{pt: 1}}>
                <Button
                  variant="outlined"
                  sx={continueBtn}
                  onPointerUp={login}
                  id="login-btn"
                >
                  login
                </Button>
              </Box>
            </CustomSlider>
            <CustomSlider direction="right" fadeIn={500}>
              <Box sx={forgotPwBox}>
                <Box sx={forgotPwTxt} onPointerUp={forgotPw}>
                  Forgot password?
                </Box>
              </Box>
            </CustomSlider>
          </Box>
        </Box>
      </Box>
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        status={status}
        message={message}
      />
    </>
  );
};
