/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import {handleCustomError} from '../../RouteErrorView/RouteErrorView';
import {Bridge} from '../../../Bridge';
import {fetchBusinessKioskSetting} from '../../../providers/api/shift';

export const groupShiftByShiftDate = (filteredShifts: any) => {
  const groupedShifts: any = {};

  filteredShifts.forEach((shift: any) => {
    if (shift.nestGetShiftInstance?.id) {
      const shiftDate = moment(shift.nestGetShiftInstance?.start_date).format(
        'YYYY-MM-DD'
      );
      if (groupedShifts[shiftDate]) {
        groupedShifts[shiftDate].push(shift);
      } else {
        groupedShifts[shiftDate] = [shift];
      }
    }
  });
  return groupedShifts;
};

// Only show full location address for confirmed shift
export const getShiftLocationString = (
  businessName: string,
  street: string,
  city: string,
  postal: string,
  status: string
) => {
  const businessNameString = businessName || '';
  const streetString = status === 'confirmed' ? street : '';
  const cityString = city || '';
  const postalString = status === 'confirmed' ? postal : '';

  return `${businessNameString ? businessNameString : ''} - ${
    streetString ? streetString : ''
  } ${cityString ? cityString : ''} ${postalString ? ' ' + postalString : ''}`;
};

export const createScheduleEvents = async (groupedShifts: any) => {
  const scheduleEvents = await Promise.all(
    Object.entries(groupedShifts).map(async ([shiftDate, shifts]) => {
      const events = await Promise.all(
        (shifts as any).map(async (shift: any) => {
          const status = await getDisplayStatus(shift);
          return {
            shift_posting_id: shift.nestGetShiftInstance.id,
            start: shift.clock_in,
            end: shift.clock_out,
            title: shift.nestGetShiftInstance.nestGetShiftPosting.name,
            location: getShiftLocationString(
              shift.nestGetShiftInstance.nestGetShiftPosting.nestGetBusiness
                ?.name,
              shift.nestGetShiftInstance.nestGetShiftPosting
                .nestGetBusinessLocation?.street,
              shift.nestGetShiftInstance.nestGetShiftPosting
                .nestGetBusinessLocation?.city,
              shift.nestGetShiftInstance.nestGetShiftPosting
                .nestGetBusinessLocation?.postal,
              shift.status
            ),
            business_location_id:
              shift.nestGetShiftInstance.nestGetShiftPosting
                .business_location_id,
            post_start_time: shift.nestGetShiftInstance.start_date,
            post_end_time: shift.nestGetShiftInstance.end_date,
            approved_start: shift.approved_hours_clock_in,
            approved_end: shift.approved_hours_clock_out,
            tenant_id: shift.tenant_id,
            tenant_name: shift.tenant_name,
            is_clock_missed_approved: shift.is_clock_missed_approved,
            status: status,
          };
        })
      );
      return {
        date: moment(shiftDate).format('DD MMM YYYY'),
        events: events,
      };
    })
  );
  return scheduleEvents;
};

export const sortByDate = (allShifts: any[]) => {
  return allShifts.sort((a, b) => {
    const dateA: any = new Date(b.date);
    const dateB: any = new Date(a.date);
    if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
      return 0;
    }
    return dateA - dateB;
  });
};

export const mergeSchedules = (
  originalSchedule: any[],
  newScheduleShift: any[]
) => {
  const mergedSchedule = [...originalSchedule];

  newScheduleShift.forEach((newShiftGroup: any) => {
    const index = originalSchedule.findIndex(
      (shiftGroup: any) => shiftGroup.date === newShiftGroup.date
    );

    if (index !== -1) {
      newShiftGroup.events.forEach((newEvent: any) => {
        const eventIndex = originalSchedule[index].events.findIndex(
          (event: any) => event.shift_posting_id === newEvent.shift_posting_id
        );
        if (eventIndex === -1) {
          mergedSchedule[index].events.push(newEvent);
        }
      });
    } else {
      mergedSchedule.push(newShiftGroup);
    }
  });

  return mergedSchedule;
};

export const getDisplayStatus = async (single: any) => {
  if (single.status !== 'confirmed') return single.status;

  const currentTime = new Date();
  const postStartTime = new Date(single?.nestGetShiftInstance?.start_date);
  const postEndTime = new Date(single?.nestGetShiftInstance?.end_date);

  const processSettings = async () => {
    try {
      const res = await fetchBusinessKioskSetting(
        single?.tenant_id,
        single?.nestGetShiftInstance?.nestGetShiftPosting?.business_location_id
      );

      return res.length === 0 ? 'confirmed' : 'missed';
    } catch (err) {
      return handleCustomError(err);
    }
  };

  if (
    currentTime > postEndTime &&
    (single?.approved_hours_clock_in === null ||
      single?.approved_hours_clock_out === null)
  ) {
    if (single?.is_clock_missed_approved) {
      return 'missed';
    } else {
      return processSettings();
    }
  } else if (
    currentTime > postStartTime &&
    single?.approved_hours_clock_in === null
  ) {
    if (single?.is_clock_missed_approved) {
      return 'missed';
    } else {
      return processSettings();
    }
  }

  return single.status;
};

export const resetAppDataInBridge = async () => {
  try {
    await Bridge.resetAppData();
  } catch (err) {
    handleCustomError(err);
  }
};

// Remove schedule related value from local storage and trigger reload
export const removeOldSchedule = () => {
  localStorage.removeItem('schedule');
  localStorage.removeItem('scheduleOffset');
  localStorage.removeItem('SLScroll');
  localStorage.removeItem('loadedAllShifts');
};
