/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button, CircularProgress, TextField} from '@mui/material';
import {BottomBar} from '../../../../../../components/common/BottomBar/BottomBar';
import {Navbar} from '../../../../../../components/common/NavBar/NavBar';
import {bottomSubPageLimit, photoIcon} from '../../../ProfileViewStyles';
import {PageWrapper} from '../../../../../../components/common/PageWrapper/PageWrapper';
import {continueBtn} from '../../../../../Public/LandingView/LandingViewStyles';
import {PhotoCamera} from '@mui/icons-material';
import {useFileUpload} from '../../../../../../hooks/useFileUpload';
import {useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {usePopUp} from '../../../../../../hooks/usePopUp';
import {PopUp} from '../../../../../../components/common/PopUp/PopUp';
import {
  updateStaffCertificationInDefaultTenant,
  updateStaffCertificationInTenant,
} from '../../../../../../providers/api/certifications';
import {fetchStaffProfileRaw} from '../../../../../../providers/api/profile';
import Moment from 'react-moment';
import {CustomDatePicker} from '../../../../../../components/common/CustomDatePicker/CustomDatePicker';

export const InAppCertUpdateView = () => {
  const {handleFileUpload, changeHandler, fileSelected} = useFileUpload();
  const {handlePopUp, popUp, closePopUp, status, message} = usePopUp();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const {state} = useLocation();
  const cert = state.param1;
  const staffId = localStorage.getItem('email');
  const tenantId = localStorage.getItem('tenantId');
  const [expiry, setExpiry] = useState<Moment | null>(cert.expiration ?? null);

  const handleReturn = () => {
    handlePopUp(
      'success',
      'Certification uploaded successfully. Now redirect you back...'
    );
    setTimeout(() => {
      navigate('/documents/certifications');
    }, 700);
  };

  const handleChange = (newValue: Moment | null) => setExpiry(newValue);

  const uploadCertification = async () => {
    try {
      const uploaded: any = await handleFileUpload(staffId!);
      return uploaded?.data?.result?.Location;
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const updateCertificationInProfile = async (certPhoto: string) => {
    try {
      const response = await updateStaffCertificationInDefaultTenant(
        cert.id,
        certPhoto,
        expiry
      );

      const staffProfile: any = await fetchStaffProfileRaw(staffId!);

      if (
        Array.isArray(staffProfile?.tenants) &&
        staffProfile.tenants.length !== 0
      ) {
        for (const tenant of staffProfile.tenants) {
          try {
            await updateStaffCertificationInTenant(
              response?.data?.updateStaffDocument?.id,
              tenantId!,
              tenant
            );
          } catch (err) {
            console.log(err);
            console.log(err);
          }
        }
      }
    } catch (err) {
      console.log(err);
      console.log(err);
    }
  };

  const updateCert = async () => {
    setSubmitting(true);

    if (!fileSelected) {
      handlePopUp('error', 'Please upload document');
      setSubmitting(false);
      return;
    }

    try {
      const certPhoto = await uploadCertification();
      await updateCertificationInProfile(certPhoto);
      handleReturn();
    } catch (err) {
      console.log(err);
      console.log(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Navbar backBtn />
      <Box sx={bottomSubPageLimit}>
        <PageWrapper title="Update Certification">
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, m: 2}}>
            <TextField value={cert.idName} disabled />
            <CustomDatePicker
              value={expiry}
              handleChange={handleChange}
              label="Expiry Date (Optional)"
            />
            <Button variant="outlined" sx={continueBtn} component="label">
              <PhotoCamera sx={photoIcon} />
              Upload Certification
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={changeHandler}
              />
            </Button>
            <Button
              variant="outlined"
              sx={continueBtn}
              onPointerUp={updateCert}
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress
                  size="1.3rem"
                  sx={{color: 'primary.light', fontSize: '12px'}}
                />
              ) : (
                'Save'
              )}
            </Button>
          </Box>
        </PageWrapper>
      </Box>
      <BottomBar />
      <PopUp
        isOpen={popUp}
        onClose={closePopUp}
        message={message}
        status={status}
      />
    </>
  );
};
