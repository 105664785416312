/* eslint-disable @typescript-eslint/no-explicit-any */
import {Box, Button} from '@mui/material';
import {
  alphabetBox,
  alphabetBoxAlt,
  linkBox,
  questionNo,
  quizBtn,
  quizBtnAlt,
  trainingImgBox,
} from '../TrainingCenterStyles';
import {flexCtrCtr} from '../../../ProfileView/ProfileViewStyles';

interface QuestionProps {
  course: any[];
  currentQuestion: number;
  openLink: Function;
}

export const QuestionDisplay: React.FC<QuestionProps> = ({
  course,
  currentQuestion,
  openLink,
}) => {
  const current = course[currentQuestion];

  return (
    <Box>
      {/* Render Question Title */}
      <Box sx={questionNo}>
        {`Question ${currentQuestion + 1}: ${current.questionTitle}`}
      </Box>
      {/* Render Description */}
      <Box sx={flexCtrCtr}>{current.desc}</Box>
      {/* Render Image if it exists */}
      {current.img?.[0]?.url && (
        <Box sx={trainingImgBox}>
          <Box component="img" src={current.img[0].url} />
        </Box>
      )}
      {/* Render Link if it exists */}
      {current.link && (
        <Box sx={linkBox} onPointerUp={() => openLink(current.link)}>
          Click here to access the link
        </Box>
      )}
    </Box>
  );
};

export const ChoiceButtons = ({
  answers,
  currentQuestion,
  choice,
  index,
  handleClick,
}: any) => {
  return (
    <Button
      variant="outlined"
      sx={answers[currentQuestion]?.includes(choice.id) ? quizBtnAlt : quizBtn}
      onPointerUp={() => handleClick(choice.id)}
    >
      <Box
        sx={
          answers[currentQuestion]?.includes(choice.id)
            ? alphabetBoxAlt
            : alphabetBox
        }
      >
        {String.fromCharCode(65 + index)}
      </Box>
      <Box>{choice.value}</Box>
    </Button>
  );
};
