export const dateBox = {
  borderTop: '1px solid',
  borderBottom: '1px solid',
  height: '35px',
  display: 'flex',
  alignItems: 'center',
};

export const eventContainer = {
  height: '100px',
  borderBottom: '1px solid',
  borderColor: 'secondary.main',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',
  cursor: 'pointer',
};

export const redColumn = {
  height: '80px',
  borderLeft: '4px solid',
  borderColor: 'red',
  mx: 1,
};

export const blueColumn = {
  height: '80px',
  borderLeft: '4px solid',
  borderColor: 'blue',
  mx: 1,
};

export const orangeColumn = {
  height: '80px',
  borderLeft: '4px solid',
  borderColor: 'orange',
  mx: 1,
};

export const greenColumn = {
  height: '80px',
  borderLeft: '4px solid',
  borderColor: 'green',
  mx: 1,
};

export const greyColumn = {
  height: '80px',
  borderLeft: '4px solid',
  borderColor: 'grey',
  mx: 1,
};

export const detailBox = {ml: 0.5, width: {xs: '180px', sm: '300px'}};

export const eventTitle = {
  fontSize: {xs: '1rem', sm: '1.2rem'},
  fontWeight: 'bold',
  textTransform: 'capitalize',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  width: {xs: '230px', sm: '300px', md: '500px'},
  cursor: 'pointer',
};

export const eventLocation = {
  fontSize: {xs: '0.7rem', sm: '0.8rem'},
  width: '70vw',
  textTransform: 'capitalize',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
};

export const timeBox = {
  position: 'absolute',
  right: '5px',
};

export const timeDetail = {display: 'flex', justifyContent: 'end'};

export const emptyPage = {
  width: '100%',
  height: '90vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};
