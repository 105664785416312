/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  getEmploymentContract,
  listEmploymentContracts,
} from '@digistaff/business';
import {app} from '../Accounts';
import {
  createStaffEmploymentContract,
  listStaffEmploymentContracts,
} from '@digistaff/staff';
import {withTenant} from './withTenant';

export const fetchAgencyEmploymentContractsList = async () => {
  try {
    const options = {filter: {status: 'active'}};
    const res = await listEmploymentContracts(app, options);

    return res.data.listEmploymentContracts;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchAgencyEmploymentContractStatus = async (
  contractId: string
) => {
  try {
    const staffId = localStorage.getItem('email');
    const options = {
      filter: {employment_contract_id: contractId, staff_id: staffId},
    };
    const res: any = await listStaffEmploymentContracts(app, options);

    return res.data.listStaffEmploymentContracts;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchAgencyEmploymentContract = async (contractId: string) => {
  try {
    const res = await getEmploymentContract(app, contractId);
    return res.data.getEmploymentContract;
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchAgencyEmploymentContractInDigiJobs = (tenant_id: string) =>
  withTenant(tenant_id, fetchAgencyEmploymentContract);

export const submitStaffEmploymentContract = async (
  contractId: string,
  staffId: string,
  signature_url: string
) => {
  try {
    const payload = {
      employment_contract_id: contractId,
      staff_id: staffId,
      signature_file_url: signature_url,
    };
    return await createStaffEmploymentContract(app, payload);
  } catch (error) {
    console.log(error);
    console.log(error);
    return Promise.reject(error);
  }
};

export const submitStaffEmploymentContractInDigiJobs = (tenant_id: string) =>
  withTenant(tenant_id, submitStaffEmploymentContract);
