/* eslint-disable @typescript-eslint/no-explicit-any */
import {getBusiness} from '@digistaff/business';
import {
  countChatMessage,
  createChatMessage,
  listChatConversationParticipants,
  listChatMessages,
  updateChatMessage,
} from '@digistaff/notification';
import {app, setTenantKey} from '../Accounts';
import {handleCustomError} from '../../views/RouteErrorView/RouteErrorView';

export const fetchChatConversations = async (staffId: string) => {
  try {
    const filterCriteria = {staff_id: staffId};
    const orderCriteria = [{column: 'created_at', order: 'desc'}];
    const options = {filter: filterCriteria, order: orderCriteria};
    const message_options = {
      order: [{column: 'created_at', order: 'desc'}],
      limit: 1,
    };
    const res = await listChatConversationParticipants(
      app,
      options,
      message_options
    );

    return res?.data?.listChatConversationParticipants;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchConversationMessages = async (
  conversationId: string,
  limit?: number,
  offset?: number
) => {
  try {
    const filterCriteria = {conversation_id: conversationId};
    const orderCriteria = [{column: 'created_at', order: 'desc'}];
    const options = {
      filter: filterCriteria,
      order: orderCriteria,
      limit: limit,
      offset: offset,
    };
    const res: any = await listChatMessages(app, options);
    const conversationMsg = res?.data?.listChatMessages;
    return conversationMsg;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const createMessage = async (
  id: string,
  trimmedMessage: string,
  user: string
) => {
  try {
    const payload = {
      conversation_id: id,
      message: trimmedMessage,
      sender_id: user,
      status: 'sent',
    };
    const res: any = await createChatMessage(app, payload);
    const createdMsg = res?.data?.createChatMessage;
    return createdMsg;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const fetchBusinessAvatar = async (id: string) => {
  try {
    const res = await getBusiness(app, id);
    const business = res?.data?.getBusiness;

    return business;
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const updateMessage = async (messageId: string, response: string) => {
  try {
    const payload = {
      reference_response: response,
    };
    return await updateChatMessage(app, messageId, payload);
  } catch (err) {
    handleCustomError(err);
    return Promise.reject(err);
  }
};

export const updateMsgStatus = async (message: any) => {
  try {
    if (message.status === 'seen') return;
    return await updateChatMessage(app, message.id, {status: 'seen'});
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};

export const fetchMsgCount = async (
  conversation_id: string,
  staffId: string
) => {
  if (conversation_id === 'false' || !conversation_id) return;

  const currentTenant = localStorage.getItem('tenantId');
  setTenantKey(app, currentTenant!);

  try {
    const res: any = await countChatMessage(app, {
      filter: {
        conversation_id: conversation_id,
        status: 'sent',
        sender_id: {
          operator: '<>',
          value: staffId,
        },
      },
    });

    return res?.data?.countChatMessages;
  } catch (error) {
    handleCustomError(error);
    return Promise.reject(error);
  }
};
