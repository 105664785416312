/* eslint-disable @typescript-eslint/no-explicit-any */
import {useCallback} from 'react';
import {app} from '../Accounts';

import {listStaffEmploymentContracts} from '@digistaff/staff';

export const useContracts = () => {
  const listAllContracts = useCallback((id: string) => {
    return new Promise((resolve, reject) => {
      listStaffEmploymentContracts(app, {
        filter: {
          staff_id: id,
        },
      })
        .then((res: any) => resolve(res.data.listStaffEmploymentContracts))
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }, []);

  return {listAllContracts};
};
