/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from '@mui/material';
import {CheckBoxFilterProps} from '../../../../types/types';
import {formContainer, formLabel, radioGroup} from '../DropDownViewStyles';

export const CheckBoxFilter = ({
  cat,
  sortValue,
  selectProperties,
}: CheckBoxFilterProps) => {
  const checkCat = (cat: string, type: string) => {
    const selectedCat = sortValue[cat];
    if (selectedCat.includes(type)) return true;
    return false;
  };

  return (
    <FormControl sx={formContainer}>
      <FormLabel sx={formLabel}>{cat.label}</FormLabel>
      <RadioGroup sx={radioGroup}>
        {cat.array.map((item: {id: string; name: string}) => (
          <FormControlLabel
            key={item.id}
            control={<Checkbox sx={{width: '50px', height: '50px'}} />}
            label={item.name}
            checked={checkCat(cat.name, item.id)}
            onChange={() => selectProperties(sortValue, item.id, cat.name)}
          />
        ))}
      </RadioGroup>
      <Divider />
    </FormControl>
  );
};
